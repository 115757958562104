import * as Sentry from "@sentry/react"
import * as API from "../api"
import { GUEST_LOGGED_IN, logUserAmplitudeEvent, setUserAsGuest } from "../common/amplitude-event-logging"
import Rmbx from "../util"
import { defaultErrorMsg } from "./index"
import { customFormToggleEditing } from "./form-view-actions"
import { eAuthenticationErrorTypes } from "../router/types"
import { setCookie } from "../common/ts-utils"
import { getFlagEnabled } from "../getFlagValue"
import { AUTH_EXPIRY } from "../common/constants"

export function login_began() {
    return {
        type: "LOGIN_BEGAN",
    }
}

export function login_failed(errorMessage) {
    return {
        type: "LOGIN_FAILED",
        data: { errorMessage },
    }
}

export function login_successful(token) {
    return {
        type: "LOGIN_SUCCESSFUL",
        token,
    }
}

export function login_check_began() {
    return {
        type: "LOGIN_CHECK_BEGAN",
    }
}

export function login_check_reset() {
    return {
        type: "LOGIN_CHECK_RESET",
    }
}

export function login_check_failed(errorMessage) {
    return {
        type: "LOGIN_CHECK_FAILED",
        data: { errorMessage },
    }
}

export function login_check_successful(
    email,
    isOidcSsoEnabled,
    // can be removed with RN-1757-cico-worker-experience
    unverifiedUserRole,
    oidcUrl,
    scope,
    clientId,
    connectionName,
    connectionId,
    connectionLogo,
    onAssignedExceptionsList
) {
    return {
        type: "LOGIN_CHECK_SUCCESSFUL",
        data: {
            email,
            isOidcSsoEnabled,
            // can be removed with RN-1757-cico-worker-experience
            unverifiedUserRole,
            oidcUrl,
            scope,
            clientId,
            connectionName,
            connectionId,
            connectionLogo,
            onAssignedExceptionsList,
        },
    }
}

export function guest_login_check_successful(data) {
    return {
        type: "GUEST_LOGIN_CHECK_SUCCESSFUL",
        data: {
            email: data.email,
            formId: data.form_id,
            firstName: data.first_name,
            lastName: data.last_name,
            permissions: data.permissions,
            companyLogoUrl: data.company_logo_url,
            expiration: data.expiration,
        },
    }
}

export function guest_login_check_failed(errorMessage) {
    return {
        type: "GUEST_LOGIN_CHECK_FAILED",
        data: { errorMessage },
    }
}

export function trial_account_deactivated() {
    return {
        type: "TRIAL_ACCOUNT_DEACTIVATED",
    }
}

// This sets a flag that lets us that the user was assigned a project
// after logging in or refreshing the page
export const initializedUser = () => ({
    type: "INITIALIZED",
})

export function login(data) {
    // if WA-7875-enforce-remember-me-setting flag is off or keep me logged in is checked, persist login
    const keepLoggedIn = !!data?.keepLoggedIn || !getFlagEnabled("WA-7875-enforce-remember-me-setting")
    return dispatch => {
        dispatch(login_began())
        return API.login(data)
            .then(result_data => {
                if (getFlagEnabled("WA-7694-cookies-for-auth") && keepLoggedIn) {
                    setCookie("token", result_data[0].token, "/", AUTH_EXPIRY)
                    setCookie("authorization_type", "Token", "/", AUTH_EXPIRY)
                } else {
                    Rmbx.store.set("token", result_data[0].token)
                    Rmbx.store.set("authorization_type", "Token")
                }
                dispatch(login_successful(result_data[0]))
                Sentry.configureScope(function (scope) {
                    scope.setUser({
                        email: result_data[0].user.email,
                        id: result_data[0].user.id,
                    })
                })
                Rmbx.util.history.push("/rhumbix/")
            })
            .catch(error => {
                const error_type = error.response.data?.error_type
                if (error_type === eAuthenticationErrorTypes.TRIAL_DISABLED) {
                    dispatch(trial_account_deactivated())
                    Rmbx.util.history.push("/rhumbix/trial/deactivated/")
                } else {
                    const errorMessage =
                        error.response && error.response.status === 401
                            ? error.response.data.detail
                            : defaultErrorMsg
                    dispatch(login_failed(errorMessage))
                }
            })
    }
}

export function loginCheck(data) {
    return dispatch => {
        dispatch(login_check_began())
        return API.loginCheck(data)
            .then(result => {
                dispatch(
                    login_check_successful(
                        result.email,
                        result.is_oidc_sso_enabled,
                        // can be removed with RN-1757-cico-worker-experience
                        result.user_role,
                        result.oidc_url,
                        result.scope,
                        result.client_id,
                        result.connection_name,
                        result.connection_id,
                        result.connection_logo,
                        result.on_assigned_exceptions_list
                    )
                )
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.errors
                    ? typeof Object.values(error.response.data.errors)[0] === "string"
                        ? Object.values(error.response.data.errors)[0]
                        : Object.values(error.response.data.errors)[0][0]
                    : defaultErrorMsg
                dispatch(login_check_failed(errorMessage))
            })
    }
}

export function guestLogin(data) {
    return dispatch => {
        return API.guestLogin(data)
            .then(result => {
                dispatch(guest_login_check_successful(result))
                Rmbx.store.set("guest_email", result.email)
                Rmbx.store.set("guest_form_id", result.form_id)
                Rmbx.store.set("guest_company_logo", result.company_logo)
                Rmbx.store.set("guest_permissions", result?.permissions)
                Rmbx.store.set("guest_signed_key", data.signed_key)
                Sentry.configureScope(function (scope) {
                    scope.setUser({
                        email: result.email,
                        id: 0,
                    })
                })
                Rmbx.util.history.push(`/rhumbix/guest_access/${data.signed_key}/`)
                const eventInfo = {
                    form_id: result.form_id,
                    permissions: result.permissions,
                }
                setUserAsGuest(true)
                logUserAmplitudeEvent(GUEST_LOGGED_IN, eventInfo)
            })
            .catch(error => {
                const errorMessage =
                    error.response && error.response.data && error.response.data.errors
                        ? Object.values(error.response.data.errors)[0]
                        : defaultErrorMsg
                dispatch(guest_login_check_failed(errorMessage))
                dispatch(customFormToggleEditing(false))
                Rmbx.util.history.push(`/rhumbix/guest_login/${data.signed_key}/`)
            })
    }
}

export function guestAccessExpired(signedKey) {
    return dispatch => {
        dispatch(guest_login_check_failed("Your invitation has expired"))
        Rmbx.util.history.push(`/rhumbix/guest_login/${signedKey}/`)
    }
}
