import {
    booleanColDefCreator,
    checkboxColDefCreator,
    currencyColDefCreator,
    materialColDefCreator,
    stringColDefCreator,
} from "./standard-col-def-creators"
import { isActiveValueFormatter } from "../../../common/ag-grid-value-formatters"
import { singleProjectFilterDef } from "../../../filters/filter-defs"

export const getProjectMaterialSettings = (featureFlags, currentProject, currentUser, __, rowData) => {
    const showPricing = featureFlags.pricing && currentProject && currentProject.options.material_pricing

    const textFieldOptions =
        currentUser && currentUser.text_field_options
            ? currentUser.text_field_options.filter(option => option.model === "Material")
            : []

    const textFieldOptionColDefs = textFieldOptions.map(option =>
        stringColDefCreator({
            headerName: option.label,
            field: `/custom_fields/${option.name}`,
            editable: false,
            referenceableMap: {
                referenceableKey: "materials",
                rowKey: "material",
            },
        })
    )

    return {
        tableName: "Materials",
        navId: "projectMaterials",
        resources: ["projectMaterials"],
        filters: [singleProjectFilterDef],
        relatedNames: {
            projectMaterials: {
                materials: ["/material"],
            },
        },
        colDefs: [
            {
                field: "/project",
                hide: true,
            },
            checkboxColDefCreator(),
            {
                ...materialColDefCreator({
                    editable: true,
                    idsToExclude:
                        rowData && rowData.sourceData && rowData.sourceData.projectMaterials
                            ? rowData.sourceData.projectMaterials.map(r => r.material)
                            : [],
                }),
                invertRelatedFilters: ["projectId"],
            },
            stringColDefCreator({
                headerName: "Description",
                field: "/description",
                editable: false,
                referenceableMap: {
                    referenceableKey: "materials",
                    rowKey: "material",
                },
            }),
            stringColDefCreator({
                headerName: "Units",
                field: "/units",
                editable: false,
                referenceableMap: {
                    referenceableKey: "materials",
                    rowKey: "material",
                },
            }),
            stringColDefCreator({
                headerName: "Part #",
                field: "/part_number",
                editable: false,
                referenceableMap: {
                    referenceableKey: "materials",
                    rowKey: "material",
                },
            }),
            ...(showPricing
                ? [
                      currencyColDefCreator({
                          headerName: "Unit Price*",
                          field: "/unit_price",
                          required: true,
                      }),
                  ]
                : []),
            stringColDefCreator({
                headerName: "Group",
                field: "/group",
                editable: false,
                referenceableMap: {
                    referenceableKey: "materials",
                    rowKey: "material",
                },
            }),
            {
                ...booleanColDefCreator({
                    headerName: "Status",
                    field: "/is_active",
                    referenceableMap: {
                        referenceableKey: "materials",
                        rowKey: "material",
                    },
                    minWidth: 100,
                    editable: false,
                }),
                valueFormatter: isActiveValueFormatter,
            },
            ...textFieldOptionColDefs,
        ],
        gridSettings: {
            rowHeight: 40,
            defaultColDef: {
                editable: true,
            },
            rowSelection: "multiple",
            // allow our click events to happen
            suppressRowClickSelection: true,
        },
        otherSettings: {
            pageTitle: "Project Materials",
            subcontent:
                "Material includes any building materials used on this project. " +
                "Add material to this project by uploading a .CSV file or manually typing " +
                "in the material information.",
            hiddenColumnDefaults: {
                project: "projectId",
            },
            rowLevelErrorDisplay: true,
            enableSearchBar: true,
            displayError: () => {
                if (!currentProject) {
                    return "You must select a project to use this dashboard."
                }

                return null
            },
            buttons: {
                cell: [
                    {
                        label: "Edit",
                        icon: "edit",
                        action: "editFocusedCell",
                    },
                    {
                        label: "Copy",
                        icon: "copy",
                        action: "copyFocusedCell",
                    },
                    {
                        label: "Paste",
                        icon: "paste",
                        action: "pasteIntoFocusedCell",
                    },
                ],
                row: [{ label: "Delete", icon: "delete", action: "deleteSelectedRows" }],
                table: [
                    {
                        label: "Add Row",
                        icon: "add",
                        action: "addNewRow",
                    },
                    {
                        label: "Upload",
                        icon: "upload",
                        action: "navigateTo",
                        args: {
                            url: "/rhumbix/projects/materials/upload/",
                        },
                    },
                    {
                        label: "Export ▾",
                        icon: "export",
                        action: "export",
                    },
                ],
            },
        },
    }
}
