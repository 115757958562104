import { createSelector } from "reselect"
/**
 * Return company form schemas
 */
export const companyFormSchemasSelector = state => state.companyFormSchema.all
export const currentSchemaIdSelector = state =>
    state.customForm?.customFormData?.schema ?? state.companyFormSchema?.single?.id
export const currentSchemaVariantSelector = state => state.companyFormSchema?.single?.variant

export const schemaTemplatesSelector = state => state.schemaVariants.variantTemplates
export const currentVariantSelector = state => state.schemaVariants.selectedVariantName

/**
 * Return
 */
export const listOfCompanyFormSchemas = createSelector(companyFormSchemasSelector, companyFormSchemas => {
    if (companyFormSchemas?.length) {
        return companyFormSchemas.map(schema => {
            return { name: schema.name, id: schema.id }
        })
    }
    return companyFormSchemas || []
})

// schemas for the main list view
export const primaryListViewSchemaNames = state => state.listViewSchemas.schemas?.map(schema => schema.name)
// schemas for related list views (ie Bundle list views)
export const relatedListViewSchemaNames = state => state.relatedSchemas.relatedSchemas?.map(schema => schema.name)

export const currentTemplateSelector = createSelector(
    [schemaTemplatesSelector, currentVariantSelector, currentSchemaIdSelector, currentSchemaVariantSelector],
    (variantTemplates, currentVariant, currentSchemaId, schemaVariant) => {
        if (schemaVariant) return schemaVariant
        return variantTemplates.find(vt => vt.name === currentVariant && vt.schema_id === currentSchemaId)
    }
)

export const schemaVariantNamesForListView = createSelector(
    [schemaTemplatesSelector, primaryListViewSchemaNames],
    (variantTemplates, primaryListViewNames) => {
        return (
            variantTemplates?.filter(vt => primaryListViewNames.includes(vt.schema_name)).map(vt => vt.name) || []
        )
    }
)

export const schemaVariantNamesForRelatedListView = createSelector(
    [schemaTemplatesSelector, relatedListViewSchemaNames],
    (variantTemplates, relatedListViewNames) => {
        return (
            variantTemplates?.filter(vt => relatedListViewNames?.includes(vt.schema_name)).map(vt => vt.name) || []
        )
    }
)
