import { publicApiControlsTooltip } from "../../../common/ag-grid-custom-tooltip-formatters"
import { isActiveValueFormatter } from "../../../common/ag-grid-value-formatters"
import {
    booleanColDefCreator,
    checkboxColDefCreator,
    costCodeTypeColDefCreator,
    numberColDefCreator,
    referenceableColDefCreator,
    stringColDefCreator,
} from "./standard-col-def-creators"
import { companyGroupsFilterDef, singleProjectFilterDef } from "../../../filters/filter-defs"
/** Const */
import { booleanOptions, entryTypeMap, entryTypeOptions, reportingVisibilityMap } from "../../../common/constants"

export function getProjectCostCodesSettings(featureFlags, _currentProject, currentUser) {
    // used to hide columns before returning if needed
    let show_budget, is_editable, isCostCodeControlsEnabled
    if (featureFlags) {
        show_budget = featureFlags.budget_cost_codes
        is_editable = !featureFlags.read_only_cost_codes
        isCostCodeControlsEnabled = featureFlags.cost_code_controls
    }

    const cellClasses = is_editable ? [] : ["readonly"]

    const textFieldOptions =
        currentUser && currentUser.text_field_options
            ? currentUser.text_field_options.filter(option => option.model === "Cost Code")
            : []

    const textFieldOptionColDefs = textFieldOptions.map(option =>
        stringColDefCreator({
            headerName: option.label,
            field: `/custom_fields/${option.name}`,
            cellClass: option.read_only_in_app ? ["readonly"] : [],
            editable: !option.read_only_in_app,
        })
    )

    const pinned = { pinned: "left" }

    return {
        tableName: "Cost Codes",
        navId: "costcodes",
        resources: ["costCodes"],
        filters: [companyGroupsFilterDef, singleProjectFilterDef],
        additionalQueryParams: {
            budget: show_budget,
            include_inactive: true,
            minimal_project_detail: true,
        },
        colDefs: [
            {
                field: "/project",
                hide: true,
            },
            ...(is_editable ? [checkboxColDefCreator({ ...pinned })] : []),
            stringColDefCreator({
                headerName: "Cost Code*",
                field: "/code",
                required: true,
                ...pinned,
            }),
            stringColDefCreator({
                headerName: "Description",
                field: "/description",
            }),
            stringColDefCreator({
                headerName: "Cost Code Group",
                field: "/group",
            }),
            ...(show_budget
                ? [
                      numberColDefCreator({
                          headerName: "Budget Quantity",
                          field: "/budgeted_quantities",
                          width: 150,
                      }),
                  ]
                : []),
            stringColDefCreator({
                headerName: "UoM",
                field: "/units",
                width: 100,
            }),
            ...(show_budget
                ? [
                      numberColDefCreator({
                          headerName: "Budget Hours",
                          field: "/budgeted_hours",
                      }),
                  ]
                : []),
            costCodeTypeColDefCreator({
                hide: !isCostCodeControlsEnabled,
                excludeFromExport: true,
            }),

            {
                ...referenceableColDefCreator({
                    headerName: "Entry Type",
                    field: "/default_entry_type",
                }),
                cellEditorParams: {
                    options: entryTypeOptions,
                    isDesignSystem: true,
                },
                filterQueryParam: "costCodes",
                useSelectV3: true,
                resourceName: "costCodes",
                valueFormatter: p => entryTypeMap[p.value],
                cellRendererParams: {
                    titleFormatter: p => entryTypeMap[p.value],
                },
            },
            {
                ...referenceableColDefCreator({
                    headerName: "Reporting",
                    field: "/visible_in_reporting",
                }),
                cellEditorParams: {
                    options: booleanOptions,
                    isDesignSystem: true,
                },
                cellRendererParams: {
                    titleFormatter: p => reportingVisibilityMap[p.value],
                },
                filterQueryParam: "costCodes",
                useSelectV3: true,
                resourceName: "costCodes",
                valueFormatter: p => reportingVisibilityMap[p.value],
            },
            {
                ...booleanColDefCreator({
                    headerName: "Status",
                    field: "/is_active",
                    minWidth: 100,
                    editable: false,
                    default: true,
                }),
                valueFormatter: isActiveValueFormatter,
                customTooltip: undefined,
            },
            ...textFieldOptionColDefs,
        ],
        gridSettings: {
            rowHeight: 40,
            defaultColDef: {
                cellClass: cellClasses,
                customTooltip: publicApiControlsTooltip,
                editable: is_editable,
            },
            rowSelection: "multiple",
            // allow our click events to happen
            suppressRowClickSelection: true,
        },
        otherSettings: {
            pageTitle: "Cost Codes",
            subcontent: "Foremen assigned to this project may submit time cards with the cost codes listed here.",
            hiddenColumnDefaults: {
                project: "projectId",
            },
            rowLevelErrorDisplay: true,
            enableSearchBar: true,
            buttons: {
                cell: [
                    ...(is_editable
                        ? [
                              {
                                  label: "Edit",
                                  icon: "edit",
                                  action: "editFocusedCell",
                              },
                              {
                                  label: "Copy",
                                  icon: "copy",
                                  action: "copyFocusedCell",
                              },
                              {
                                  label: "Paste",
                                  icon: "paste",
                                  action: "pasteIntoFocusedCell",
                              },
                          ]
                        : []),
                ],
                row: [
                    ...(is_editable
                        ? [
                              {
                                  label: "Activate",
                                  icon: "view",
                                  action: "bulkUpdateField",
                                  args: {
                                      field: "is_active",
                                      value: true,
                                  },
                              },
                              {
                                  label: "Deactivate",
                                  icon: "hide",
                                  action: "bulkUpdateField",
                                  args: {
                                      field: "is_active",
                                      value: false,
                                  },
                              },
                              { separator: true },
                              { label: "Delete", icon: "delete", action: "deleteSelectedRows" },
                          ]
                        : []),
                ],
                table: [
                    ...(is_editable
                        ? [
                              { label: "Add Row", icon: "add", action: "addNewRow" },
                              {
                                  label: "Upload",
                                  icon: "upload",
                                  action: "navigateTo",
                                  args: {
                                      url: `/rhumbix/projects/${show_budget ? "budgets" : "cost-codes"}/upload/`,
                                  },
                              },
                          ]
                        : []),
                    {
                        label: "Export ▾",
                        icon: "export",
                        action: "export",
                    },
                ],
            },
        },
    }
}
