import React from "react"
import { connect } from "react-redux"
import { matchPath, withRouter } from "react-router-dom"
import isEqual from "lodash/isEqual"

import Rmbx from "../util"
import LoadingIndicator from "../components/loading-indicator"
import { initializedUser } from "../actions/auth"
import { get_current_user } from "../actions/index"
import { loadAllEntities, loadEntitiesFromStorage, loadSingleProject } from "../cached-data/actions"
import { fetchEmployeeStartOfWeek } from "../filters/actions"
import {
    companyOptionsSelector,
    currentProjectIdSelector,
    currentProjectSelector,
    firstProjectLoadedSelector,
    isFeatureEnabledSelector,
    projectsErrorSelector,
    projectsListSelector,
    userInitializedSelector,
    userSelector,
} from "../selectors"
import SidebarWrapper from "../components/navigation/sidebar-wrapper"
import { ConnectedRouteList } from "./routeList"
import { routesWithoutSidebar } from "./routes"
import featureFlagsClient from "../featureFlagsClient"
import { deleteCookie, featureFlagClientIdentifyUser } from "../common/ts-utils"
import TrialAccountInfoBar from "../components/trials/trial-account-info-bar"
import { getAuthToken } from "../util"
import { getFlagEnabled } from "../getFlagValue"

const _openRoutes = [
    "/rhumbix/guest_login/*",
    "/rhumbix/login/",
    "/rhumbix/login/lockout/",
    "/rhumbix/external-login/",
    "/rhumbix/password/forgot/",
    "/rhumbix/password/set/",
    "/rhumbix/password/reset/*",
    "/rhumbix/password/set-new/*",
    "/rhumbix/trial/deactivated/",
]

const _isOpenUrl = path => {
    for (const url of [
        "login/lockout",
        "guest_login",
        "guest_access",
        "password/reset",
        "password/set-new",
        "shared",
        "sign",
        "trial/deactivated",
    ]) {
        if (path.startsWith(`/rhumbix/${url}/`)) {
            return true
        }
    }
    return false
}

/**
 * A React component that determines the user's auth status, redirects the user
 * (if necessary), and delays rendering the app for authenticated users until
 * at least one page of projects has been loaded.
 *
 * While the data is loading, nothing is rendered.
 */
class AuthProvider extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentMenuGroup: "",
            isAuthorized: false,
        }
    }

    redirectToLogin = (redirectRoutes, currentPath) => {
        if (!redirectRoutes.includes(currentPath)) {
            Rmbx.store.set("attempted_url", currentPath)
        }

        if (currentPath !== "/rhumbix/login/") {
            this.clearSessionData()
            Rmbx.util.history.push(`/rhumbix/login/${this.props.location.search}`)
        }
    }

    clearSessionData = () => {
        const attemptedUrl = Rmbx.store.get("attempted_url")
        Rmbx.util.destroySession()
        if (getFlagEnabled("WA-7694-cookies-for-auth")) {
            deleteCookie("token")
            deleteCookie("authorization_type")
        }
        if (attemptedUrl) {
            Rmbx.store.set("attempted_url", attemptedUrl)
        }
    }

    setDefaultFiltersAndRedirect = (attemptedUrl, redirectRoutes, currentPath) => {
        this.props.initializedUser()

        if (this.props.projects.length === 0) {
            // Project list was empty -- redirect to project setup
            Rmbx.util.history.push("/rhumbix/projects/")
        } else {
            this.redirectToLandingPage(attemptedUrl, redirectRoutes, currentPath)
        }
    }

    redirectToLandingPage = (attemptedUrl, redirectRoutes, currentPath) => {
        if (redirectRoutes.includes(currentPath)) {
            if (attemptedUrl) {
                Rmbx.util.history.push(attemptedUrl)
                Rmbx.store.set("attempted_url", null)
            } else {
                let redirectUrl = "/rhumbix/projects" // Fallback URL

                if (this.props.isTimekeepingFeatureEnabled) {
                    redirectUrl = "/rhumbix/time-cards/weekly/"
                } else if (
                    typeof this.props.isTimekeepingFeatureEnabled !== "undefined" &&
                    this.props.currentUser?.list_views?.length
                ) {
                    /**
                     * The reason we have to check if the flag is undefined here is to avoid assigning
                     * '/rhumbix/field-forms/t-and-m-tracking/ to history before feature flags are loaded in
                     * store->state If we did that, since the t-and-m-tracking route is not in
                     * the redirectRoutes array we would never pass the first if check even when the state was
                     * updated successfully, and if we try adding it to
                     * the redirectRoutes array we break A LOT of things
                     */
                    const currentUserListViews = this.props.currentUser.list_views

                    // If show_nav_link is false, navigation will fail.
                    // Arbitrarily find the first list view where
                    // this property is true
                    const validListView = currentUserListViews.find(lv => lv.show_nav_link)

                    if (validListView) redirectUrl = `/rhumbix/field-forms/list-view/${validListView.slug}`
                }

                Rmbx.util.history.push(redirectUrl)
            }
        }
    }

    redirect = () => {
        const currentPath = this.props.location.pathname
        const token = getAuthToken()

        const isOpenRoute = _openRoutes.includes(currentPath)
        const isOpenUrl = _isOpenUrl(currentPath)
        const redirectRoutes = ["/rhumbix/", "/rhumbix/login/"]
        const attemptedUrl = Rmbx.store.get("attempted_url")

        if (this.props.ssoLoginError) {
            this.redirectToLogin(redirectRoutes, currentPath)
        } else if (!token && !isOpenRoute && !isOpenUrl) {
            this.redirectToLogin(redirectRoutes, currentPath)
        } else if (token && !this.props.userInitialized && this.props.firstProjectLoaded) {
            this.setDefaultFiltersAndRedirect(attemptedUrl, redirectRoutes, currentPath)
        } else if (token) {
            this.redirectToLandingPage(attemptedUrl, redirectRoutes, currentPath)
        }
    }

    loadData() {
        const token = getAuthToken()

        if (token && !this.props.ssoLoginError && !this.props.userInitialized) {
            this.props
                .getCurrentUser()
                .then(() => {
                    const { currentUser } = this.props
                    if (currentUser?.employee_id) {
                        featureFlagClientIdentifyUser(currentUser, featureFlagsClient)
                        this.props.fetchEmployeeStartOfWeek(currentUser.employee_id)
                    }
                    this.props.loadEntitiesFromStorage()
                    this.props.loadSingleProject()
                    this.props.loadAllEntities("timekeepingStatuses")
                })
                .finally(() => {
                    this.redirect()
                })
        } else {
            this.redirect()
        }
    }

    componentDidMount() {
        this.loadData()
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(this.props, prevProps)) {
            this.loadData()
        }
    }

    updateCurrentMenuGroup = menuGroup => {
        if (menuGroup) {
            this.setState({ currentMenuGroup: menuGroup })
        }
    }

    /** Update "isAuthorized" state, use this to show/hide sidebar. */
    updateIsAuthorized = value => this.setState({ isAuthorized: value })

    render() {
        const token = getAuthToken()
        const isDataLoading =
            token && !this.props.ssoLoginError && (!this.props.userInitialized || !this.props.firstProjectLoaded)

        if (isDataLoading) {
            return <LoadingIndicator active={true} />
        }

        if (this.props.projectsError) {
            return <p className="error">{this.props.projectsError} Please refresh the page and try again.</p>
        }

        const renderSidebar =
            !routesWithoutSidebar.some(routePattern => matchPath(this.props.location.pathname, routePattern)) &&
            !(
                // Couldn't use routesWithSidebar because matchPath
                // is unhappy with the special characters in the pathname
                // for guest form shares, most likely the colons.
                (
                    getFlagEnabled("WA-7861-prevent-guest-mode-login-loop") &&
                    (this.props.location.pathname.includes("/guest_login/") ||
                        this.props.location.pathname.includes("/guest_access/"))
                )
            )

        const isTrialAccount = () => {
            // TODO: Consider making an enum and selector for company type
            return (
                !!this.props.deactivationDate &&
                this.props.companyType &&
                (this.props.companyType === "FREE_TRIAL" || this.props.companyType === "PAID_TRIAL")
            )
        }

        return (
            <>
                {isTrialAccount() ? <TrialAccountInfoBar deactivationDate={this.props.deactivationDate} /> : null}
                <div className="sidebar-content-viewport-container">
                    {renderSidebar && this.state.isAuthorized ? (
                        <SidebarWrapper currentMenuGroup={this.state.currentMenuGroup} />
                    ) : null}
                    <ConnectedRouteList
                        updateMenuGroup={this.updateCurrentMenuGroup}
                        updateIsAuthorized={this.updateIsAuthorized}
                    />
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    currentProjectId: currentProjectIdSelector(state),
    currentProject: currentProjectSelector(state),
    currentUser: userSelector(state),
    projects: projectsListSelector(state),
    firstProjectLoaded: firstProjectLoadedSelector(state),
    isTimekeepingFeatureEnabled: isFeatureEnabledSelector(state, "timekeeping"),
    projectsError: projectsErrorSelector(state),
    userInitialized: userInitializedSelector(state),
    ssoLoginError: state.current_user_failed && state.current_user_failed.errorResponseData,
    deactivationDate: companyOptionsSelector(state)?.deactivation_date ?? undefined,
    companyType: companyOptionsSelector(state)?.company_type ?? undefined,
})

const mapDispatchToProps = {
    getCurrentUser: get_current_user,
    initializedUser: initializedUser,
    loadSingleProject,
    loadEntitiesFromStorage,
    loadAllEntities,
    fetchEmployeeStartOfWeek,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthProvider))
