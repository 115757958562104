import {
    enumFilterCreator,
    referenceableSelectorFilterCreator,
    dateRangeFilterDefCreator,
    booleanFilterDefCreator,
} from "./filter-def-creators"
import { eDatePickerType, tFilterToStringParams } from "./types"
import { referenceablesToValueFormatters } from "../common/referenceable-value-formatters"
import { iTimekeepingStatus, tCachedResourceName } from "../cached-data/types"

/**
 * Hard coded filter definitions
 * For use by legacy views and views with hard-coded settings files
 */
export const projectStatusFilterDef = enumFilterCreator({
    key: "projectStatus",
    label: "Project Status",
    options: [
        { label: "Active", value: "ACTIVE" },
        { label: "Inactive", value: "INACTIVE" },
    ],
})

export const employeeStatusFilterDef = enumFilterCreator({
    key: "employeeStatus",
    label: "Employee Status",
    options: [
        { label: "Active", value: "ACTIVE" },
        { label: "Inactive", value: "INACTIVE" },
    ],
})

export const getEnumOption = (label: string): any => {
    // Takes an enum label (Such as "Office Staff") and converts it into the enum's value (Such as "OFFICE_STAFF"),
    // making the assumption that the label is convertable in this way to the value, and then returns
    // a react-select option ({ label: string, value: string, data: any }) representing the value
    const value = label.toUpperCase().replace(" ", "_")
    return { label, value, data: { name: label, queryStr: value } }
}

export const tkEntryStatusFilterDef = enumFilterCreator({
    key: "tkEntryStatus",
    label: "Entry Status",
    valueKey: "queryStr",
    multiselect: true,
    options: [
        { label: "Not Approved", value: "PENDING", data: { name: "Not Approved", queryStr: "PENDING" } },
        ...["Supervisor Approved"].map(getEnumOption),
        { label: "Payroll Verified", value: "APPROVED", data: { name: "Payroll Verified", queryStr: "APPROVED" } },
        ...["Exported", "Synced"].map(getEnumOption),
    ],
})

export const customTkEntryStatusFilterDef = {
    ...referenceableSelectorFilterCreator({
        resourceName: "timekeepingStatuses",
        label: "Timekeeping Status",
        parameterName: "statuses",
        multiselect: true,
        isDesignSystem: true,
        isSelectorV3: true,
    }),
    // This is ripped off from the referenceable type column filter, but customized since we store/search for
    // statuses by their name, rather than an ID
    filterStateStringGetter: (params: tFilterToStringParams): string => {
        const formatter = referenceablesToValueFormatters[params.resourceName as tCachedResourceName]
        const valueFormatter = formatter ? formatter.valueFormatter : undefined
        let refIds: Array<string> | string = params.primaryValue as Array<string> | string
        if (!Array.isArray(refIds)) {
            refIds = [refIds]
        }

        if (params.context) {
            const refData = params.context.referenceableData[params.resourceName as tCachedResourceName] as {
                [key: number]: iTimekeepingStatus
            }

            return refIds
                .map(id => {
                    const obj = Object.values(refData).find((item: iTimekeepingStatus) => item.name === id)
                    return valueFormatter
                        ? valueFormatter({
                              value: obj,
                              context: params.context,
                          })
                        : id
                })
                .join(", ")
        } else {
            throw new Error("Parameter context is missing in referenceableIdsToString")
        }
    },
}

export const singleProjectFilterDef = {
    ...referenceableSelectorFilterCreator({
        resourceName: "projects",
        label: "Projects",
    }),
    clearable: false,
    required: true,
}

export const singleGroupFilterDef = {
    ...referenceableSelectorFilterCreator({
        resourceName: "companyGroups",
        label: "Group",
    }),
    clearable: false,
    required: true,
    secondaryKey: "groupId", // set the parent_id to the current groupId
    secondaryParameterName: "parent_id",
}

// Used for legacy views that can have one or all projects selected
export const clearableSingleProjectFilterDef = {
    ...singleProjectFilterDef,
    clearable: true,
    required: false,
}

export const multiProjectFilterDef = referenceableSelectorFilterCreator({
    resourceName: "projects",
    label: "Projects",
    multiselect: true,
    isDesignSystem: true,
    isSelectorV3: true,
})

export const multiCohortFilterDef = referenceableSelectorFilterCreator({
    resourceName: "cohorts",
    label: "Cohorts",
    multiselect: true,
    isDesignSystem: true,
    isSelectorV3: true,
})

export const multiGroupFilterDef = referenceableSelectorFilterCreator({
    resourceName: "companyGroups",
    label: "Group",
    multiselect: true,
    isSelectorV3: true,
    isDesignSystem: true,
})

export const companyGroupsFilterDef = {
    ...referenceableSelectorFilterCreator({
        resourceName: "companyGroups",
        label: "Group",
    }),
}

/**
 * Week filter with both shift_start_time_0 (start) and shift_start_time_1 (end) in params
 */
export const weekFilterDef = dateRangeFilterDefCreator({
    label: "Week",
    datePickerType: eDatePickerType.WEEKLY,
})

/* same as weekFilterDef except the label is changed,
rename to "weekFilterDef" once the original above is removed */
export const weekUpdatedNameFilterDef = dateRangeFilterDefCreator({
    label: "Date Range (7 Days)",
    datePickerType: eDatePickerType.WEEKLY,
})

/** Week filter with only start_date (start) in param. */
export const weekStartDateOnlyFilterDef = dateRangeFilterDefCreator({
    label: "Week",
    datePickerType: eDatePickerType.WEEKLY,
    parameterName: "start_date",
    isStartDateOnly: true,
})

export const dayFilterDef = dateRangeFilterDefCreator({
    label: "Day",
    datePickerType: eDatePickerType.DAILY,
})

export const dateRangeFilterDef = dateRangeFilterDefCreator({
    label: "Date Range",
})

export const laborTypeFilterDef = referenceableSelectorFilterCreator({
    label: "Employee Labor Type",
    resourceName: "companyCrewTypes",
    multiselect: true,
})

export const tradeFilterDef = referenceableSelectorFilterCreator({
    label: "Employee Trade",
    resourceName: "employeeTrades",
    multiselect: true,
    queryParam: "trades",
})

export const classificationFilterDef = referenceableSelectorFilterCreator({
    label: "Employee Classification",
    resourceName: "employeeClassifications",
    multiselect: true,
    queryParam: "classifications",
})

export const employeeFilterDef = referenceableSelectorFilterCreator({
    label: "Employee",
    resourceName: "employees",
    multiselect: true,
    // ignoreRelatedFilters allows the filter to show all employees available to the user rather than
    // being limited by project and employee trade
    ignoreRelatedFilters: ["projectId", "employeeTrade"],
    isDesignSystem: true,
    isSelectorV3: true,
    clearedBy: ["cohortId"],
})

export const timeCardOwnerFilterDef = referenceableSelectorFilterCreator({
    label: "Time Card Owner",
    // overriding the key is necessary to differentiate it from
    // the employee filter
    key: "timeCardOwnerId",
    parameterName: "timecard_owner_id",
    resourceName: "employees",
    multiselect: true,
    // when the search options are pulled from the backend, add the search param
    // to the request to exclude all employees with the worker role
    extraSearchFilters: {
        exclude_user_role: "WORKER",
    },
    ignoreRelatedFilters: ["projectId", "employeeTrade"],
    isDesignSystem: true,
    isSelectorV3: true,
})

/*
Production Tracking Cost Code Filter.
Available options:
- If no option is selected, displaying all cost codes.
- with hours or production: only displaying cost codes with hours or production > 0.
*/
export const productionCostCodesFilterDef = enumFilterCreator({
    key: "costCodeFilter",
    label: "Cost Codes",
    options: [{ label: "with hours or production", value: "with_hours_or_production" }],
    defaultGetter: () => ({ costCodeFilter: "with_hours_or_production" }),
    isDesignSystem: true,
    isSelectorV3: true,
})

/* Cost Code Filter - Single Selection */
export const singleCostCodeFilterDef = referenceableSelectorFilterCreator({
    resourceName: "costCodes",
    label: "Cost Code",
})

/* Cost Code Filter - show or hide visible_in_reports */
export const includeHiddenCostCodeFilterDef = booleanFilterDefCreator({
    key: "includeHidden",
    label: "Show Hidden Cost Codes",
})
