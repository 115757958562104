import { checkboxColDefCreator, stringColDefCreator, dateColDefCreator } from "./standard-col-def-creators"
import { getDisplayValueForPermissions } from "../../../components/guest-login/guest-login-utils"
import { compareGroupColumns } from "../../../common/ag-grid-comparators"
import { guestFormSharesLinkClickValueGetter } from "../../../common/ag-grid-value-getters"

export function getGuestFormShareSettings(
    featureFlags,
    currentProject,
    currentUser,
    currentProjectIds,
    sourceData,
    config
) {
    // Grab either the specified form IDs (for example, if the user selected
    // rows before opening the right rail),
    // or if none were passed in, grab the schema for this table
    // to ensure we get all of the form permissions we might need
    const additionalQueryParams = config.formIds ? { forms: config.formIds } : { schema_names: config.schemaNames }

    // Set this parameter in order to retrieve the available permissions for each guest form share. This is
    // a much slower operation but is necessary in order for users to change permissions on forms
    additionalQueryParams["check_available_permissions"] = true

    // if we have sourceData - check if the forms have variants - if they don't hide the column
    // if we don't have source data, show the column by default - we don't have access to the guest share data
    const showVariantsCol = sourceData?.length ? sourceData.some(form => form.variants?.length) : true

    return {
        navId: "collaborate",
        resources: ["guestFormShares"],
        filters: [],
        relatedNames: {
            guestFormShares: {
                companyFormStores: ["/form"],
            },
        },
        additionalQueryParams,
        colDefs: [
            checkboxColDefCreator({}),
            stringColDefCreator({
                headerName: "Email",
                field: "/guest/email",
            }),
            dateColDefCreator({
                headerName: "Share Date",
                field: "/created_on",
            }),
            stringColDefCreator({
                headerName: "Project",
                field: "/project_name",
                referenceableMap: {
                    referenceableKey: "companyFormStores",
                    rowKey: "form",
                },
            }),
            stringColDefCreator({
                headerName: "Form #",
                field: "/form_num",
                referenceableMap: {
                    referenceableKey: "companyFormStores",
                    rowKey: "form",
                },
            }),
            stringColDefCreator({
                headerName: "Link Clicks",
                valueGetter: guestFormSharesLinkClickValueGetter,
            }),
            stringColDefCreator({
                headerName: "Permissions",
                field: "/permissions",
                valueFormatter: ({ value }) => getDisplayValueForPermissions(value),
            }),
            ...(showVariantsCol
                ? [
                      stringColDefCreator({
                          headerName: "Variant",
                          field: "/variant",
                      }),
                  ]
                : []),
        ],
        gridSettings: {
            rowHeight: 40,
            rowSelection: "multiple",
            suppressRowClickSelection: true,
            suppressCellSelection: true,
            defaultColDef: {
                editable: false,
                suppressCount: true,
            },
            autoGroupColumnDef: {
                headerName: "Email",
                suppressMenu: true,
                sort: "asc",
                unSortIcon: true,
                cellRendererParams: {
                    suppressCount: true,
                },
                comparator: compareGroupColumns,
                minWidth: 200,
                maxWidth: 400,
            },
        },
        otherSettings: {
            hideCellActions: true,
            groupBy: [
                {
                    cols: [],
                    label: "None",
                },
                {
                    cols: ["/form_num"],
                    label: "Form",
                },
                {
                    cols: ["/guest/email"],
                    label: "Email",
                },
                {
                    cols: ["/project_name"],
                    label: "Project",
                },
            ],
            buttons: {
                cell: [],
                row: [
                    {
                        label: "Reshare Link",
                        icon: "share",
                        action: "reshareGuestShareForm",
                    },
                    {
                        label: "Update Permissions ▾",
                        icon: "settings",
                        action: "updateGuestPermissions",
                    },
                    {
                        label: "Revoke Access",
                        icon: "remove",
                        action: "deleteGuestShareForm",
                    },
                ],
                table: [
                    {
                        label: "Group By",
                        action: "groupBy",
                        icon: "group",
                    },
                ],
            },
        },
    }
}
