import {
    copyFocusedCell,
    copyFocusedCellToClipboardOnly,
    copyFocusedPivotCell,
    copySelectedCells,
    createNewTimecardCell,
    deleteFocusedPivotCell,
    duplicateWeeklyViewCell,
    editFocusedCell,
    editFocusedPivotCell,
    getCellTimekeepingStatusChangeHandlers,
    getSignatureOptions,
    getSignatureRequestOptions,
    openHistoryModalForFocusedPivotCell,
    openSideRailTableForFocusedCell,
    pasteIntoCellRange,
    pasteIntoFocusedCell,
    pasteIntoFocusedPivotCell,
} from "./cell"
import { clearGridSelection } from "./common"
import {
    addNewRowToGroup,
    archiveSelectedRows,
    deleteSelectedRows,
    deleteStatus,
    duplicateRow,
    bulkUpdateField,
    bulkManageAccess,
    getFormVariants,
    getWorkflowActionsHandlers,
    bundleForms,
    copyToForm,
    shareLinkToForm,
    openHistory,
    openSideRailCollaboratorsForSelectedRows,
    reshareGuestShareForm,
    updateGuestShareFormPermissions,
    deleteGuestShareForm,
    exportTK,
    getOptInOutButtons,
    getFormReviewActionButtons,
    bulkDownloadPdfs,
    importFromFormOrTimeCard,
    unhideStatus,
    getAvailableDownloadVariants,
    openDeletePicklistItemModal,
    getAvailableBundleVariants,
    openCicoDetailsForSelectedRows,
    sendResetPasswordMail,
    sendWelcomeMail,
    toggleEmployeeStatus,
    toggleLogin,
    toggleTextAlerts,
} from "./row"
import {
    addCohortEmployeeRows,
    addEmployee,
    addNewRow,
    addProject,
    addEmployeeRows,
    addCostCodeRows,
    addApiIntegrationToken,
    editTimeCardDetails,
    getExportHandlers,
    getGroupingChangeHandler,
    navigateTo,
    navigateToExternal,
    getRowToggleHandler,
    getTableTimekeepingStatusChangeHandlers,
    openAddTimekeepingEntryModal,
    openCicoDetails,
    openCreateTimeCardModal,
    openCsvExportForm,
    openFieldFormCreate,
    openSideRailCollaborators,
    toggleFancySearchBar,
    togglePlaceholders,
    toggleStatefulButton,
} from "./table"
import { tExtraButtonParams, iCellEventHandlers, iRowEventHandlers, iTableEventHandlers } from "../types"

// Exports --------------------------------------------------------------------

export const cellEventHandlers = (params: tExtraButtonParams): iCellEventHandlers => ({
    clearGridSelection,
    copyFocusedCell,
    copyFocusedCellToClipboardOnly,
    copyFocusedPivotCell,
    copySelectedCells,
    createNewTimecardCell: e => createNewTimecardCell(e, params),
    deleteFocusedPivotCell,
    duplicateWeeklyViewCell,
    editFocusedCell,
    editFocusedPivotCell,
    openHistoryModalForFocusedPivotCell,
    openSideRailTableForFocusedCell,
    pasteIntoCellRange,
    pasteIntoFocusedCell,
    pasteIntoFocusedPivotCell,
    getSignatureOptions: getSignatureOptions(params),
    getSignatureRequestOptions: getSignatureRequestOptions(params),
    updateTimekeepingStatusForFocusedPivotCell: getCellTimekeepingStatusChangeHandlers(params),
})

export const rowEventHandlers = (params: tExtraButtonParams): iRowEventHandlers => ({
    addNewRowToGroup, // TODO: Should this be in a new set of event handlers?
    archiveSelectedRows,
    clearGridSelection,
    copyToForm,
    deleteStatus,
    deleteSelectedRows,
    duplicateRow,
    bundleForms,
    bulkUpdateField,
    bulkManageAccess,
    editTimeCardDetails,
    exportTK,
    shareLinkToForm,
    openHistory,
    openSideRailCollaboratorsForSelectedRows,
    reshareGuestShareForm,
    updateGuestPermissions: updateGuestShareFormPermissions(params),
    deleteGuestShareForm,
    getFormVariants: getFormVariants(params),
    getOptInOutTMSubmit: getOptInOutButtons(params, "field_form_email"),
    getOptInOutTMStatus: getOptInOutButtons(params, "field_form_status_change_email"),
    getOptInOutOtherSubmit: getOptInOutButtons(params, "other_field_form_email"),
    getOptInOutOtherStatus: getOptInOutButtons(params, "other_field_form_status_change_email"),
    performWorkflowActionFromSchema: getWorkflowActionsHandlers(params),
    getAvailableFormReviewActions: getFormReviewActionButtons(params),
    getVariantBundleNames: getAvailableBundleVariants(params),
    getVariantDownloadNames: getAvailableDownloadVariants(params),
    bulkDownloadPdfs,
    importFromFormOrTimeCard,
    unhideStatus,
    openDeletePicklistItemModal,
    openCicoDetailsForSelectedRows,
    sendResetPasswordMail,
    sendWelcomeMail,
    toggleEmployeeStatus,
    toggleLogin,
    toggleTextAlerts,
})

export const tableEventHandlers = (params: tExtraButtonParams): iTableEventHandlers => ({
    addCohortEmployeeRows,
    addEmployee,
    addNewRow,
    addProject,
    addEmployeeRows,
    addCostCodeRows,
    addApiIntegrationToken,
    editTimeCardDetails,
    export: getExportHandlers(params),
    groupBy: getGroupingChangeHandler(params),
    navigateTo,
    navigateToExternal,
    toggleRowExpansion: getRowToggleHandler(params),
    openCicoDetails,
    openSideRailCollaborators,
    openFieldFormCreate,
    updateTimekeepingStatusForTable: getTableTimekeepingStatusChangeHandlers(params),
    openAddTimekeepingEntryModal,
    openCsvExportForm,
    openCreateTimeCardModal,
    toggleFancySearchBar,
    togglePlaceholders,
    toggleStatefulButton,
})

// These actions are used for some keydown events on CustomTable
export {
    clearGridSelection,
    deleteFocusedPivotCell,
    editFocusedCell,
    editFocusedPivotCell,
    openSideRailTableForFocusedCell,
}
