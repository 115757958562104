export const employeeRoleMap = new Map([
    ["Worker", "WORKER"],
    ["Foreman", "FOREMAN"],
    ["Admin", "ADMIN"],
    ["PM", "PM"],
    ["Payroll Admin", "PAYROLL_ADMIN"],
    ["Office Staff", "OFFICE_STAFF"],
])

export const EMPLOYEE_ROLE_OPTIONS = Array.from(employeeRoleMap.keys())
export const EMPLOYEE_ROLE_DEFAULT_OPTION = "Worker"
export const EMPLOYEE_ROLES = new Set(employeeRoleMap.values())

export const DECLINABLE_BOOLEAN_LABELS: Map<string | null, string> = new Map([
    ["YES", "Yes"],
    ["NO", "No"],
    ["DECLINE", "Decline to Identify"],
    [null, ""],
])

export const DECLINABLE_BOOLEAN_VALUES: Map<string, string | null> = new Map(
    Array.from(DECLINABLE_BOOLEAN_LABELS, keypair => keypair.reverse()) as [string, string | null][]
)

/** User Roles */
export const adminAndPmRoles = new Set(["ADMIN", "PAYROLL_ADMIN", "PM"])

export const absenceOptions = [
    "Training",
    "Injured",
    "Sick",
    "Vacation",
    "I Don't Know",
    "Changed Foreman",
    "Done with Work",
    "Laid Off",
    "Holiday",
    "Leave Early",
    "Late Arrival",
    "Other",
]

export const KeyCodes = {
    KEY_ENTER: 13,
    KEY_ESC: 27,
    KEY_PAGE_UP: 33,
    KEY_PAGE_DOWN: 34,
    KEY_PAGE_END: 35,
    KEY_PAGE_HOME: 36,
    KEY_LEFT: 37,
    KEY_UP: 38,
    KEY_RIGHT: 39,
    KEY_DOWN: 40,
    KEY_DELETE: 46,
}

export const tooltipTypes = {
    TEXT: 0,
    IMAGE: 1,
    LINK: 2,
}

export const defaultWorkHours = {
    ZERO_WORK_HOURS: 0,
    DAY_WORK_HOURS: 8,
    WEEK_WORK_HOURS: 40,
}

export const foremanFocusedTooltipTypes = {
    BUDGET: "the budget",
    THIS_WEEK: "this week",
    LAST_WEEK: "last week",
}

export const EmployeePricingType = {
    DISABLED: "DISABLED",
    STANDARD_TYPE: "STANDARD_TYPE",
    PREMIUM_TYPE: "PREMIUM_TYPE",
}

export const navCategories = {
    analytics: "analytics",
    beta: "beta-dashboards",
    companySettings: "company-settings",
    dailySummaryEmailSettings: "daily-summary-email-settings",
    demo: "demo-dashboards",
    emailAlerts: "email-alerts",
    externalLinks: "external-links",
    fieldForms: "field-forms",
    projectSettings: "project-settings",
    reports: "reports",
    timeCards: "time-cards",
}

export const notificationPreferences = {
    OPT_IN: "Opt In",
    OPT_OUT: "Opt Out",
}

export const priceFields = [
    "idle_time",
    "over_time",
    "running_time",
    "double_time",
    "unit_price",
    "standard_time",
    "premium_double_time",
    "premium_over_time",
]

export const urlForResource = {
    absences: "/api/v4/absences/",
    accountSettings: "/api/v4/my_notification_preferences/",
    analyticsDashboards: "/api/v4/analytics_dashboards/",
    apiIntegration: "/api/v3/company_integration/",
    autodeskToken: "/integrations/autodesk/token/",
    changeOrders: "/api/v4/change_orders/",
    cohorts: "/api/v4/cohorts/",
    cohortEmployees: "/api/v4/cohort_employees/",
    companies: "/api/v4/companies/",
    companyAbsenceTypes: "/api/v4/company_absence_types/",
    companyAnalyticsDashboards: "/api/v4/company_analytics_dashboards/",
    companyFormSchemas: "/api/v4/company_form_schemas/",
    companyFormSchemasForDashboard: "/api/v4/company_form_schemas/for_dashboard/",
    companyFormStores: "/api/v4/company_form_stores/",
    companyGroups: "/api/v4/company_groups/",
    companyStartStopTypes: "/api/v4/company_start_stop_types/",
    costCodes: "/api/v4/cost_codes/",
    costCodeControls: "/api/v4/company_cost_code_controls/",
    companyCrewTypes: "/api/v4/company_crew_types/",
    costItems: "/api/v4/cost_items/",
    dailySummaryPreferences: "/api/v4/daily_summary_preferences/",
    employeeClassifications: "/api/v4/employee_classifications/",
    employeeEntries: "/api/v4/employee_entries/",
    employeeTrades: "/api/v4/employee_trades/",
    guestFormShares: "/api/v4/guest_form_shares/",
    employees: "/api/v4/employees/",
    employeeSchemas: "/api/v4/employee_schemas/",
    employeeSchemasForDashboard: "/api/v4/employee_schemas/for_dashboard/",
    equipment: "/api/v4/equipment/",
    ewsStartStopTimes: "/api/v4/ews_start_stop_times/",
    groupMembers: "/api/v4/group_members/",
    materials: "/api/v4/materials/",
    modifyProduction: "/api/v4/pt_weekly_view/",
    picklistItems: "/api/v4/picklist_items/",
    projectEmployees: "/api/v4/project_employees/",
    projectEquipment: "/api/v4/project_equipment/",
    projectMaterials: "/api/v4/project_materials/",
    projects: "/api/v4/projects/",
    projectShares: "/api/v4/project_shares/",
    quantities: "/api/v4/quantities/",
    schemaActions: "/api/v4/schema_actions/",
    schemaStatusNames: "/api/v4/schema_status_names/",
    timekeepingEntries: "/api/v4/timekeeping_entries/",
    timekeepingModifiersForDashboard: "/api/v4/timekeeping_modifiers_for_dasbhoard/",
    timekeepingStatuses: "/api/v4/timekeeping_statuses/",
    timelineEntryVersions: "/api/v4/timeline_entries/",
    tkExportFormats: "/api/v4/tk_export_formats/",
    workShifts: "/api/v4/work_shifts/",
    companyTrades: "/api/v4/company_trades/",
    companyClassifications: "/api/v4/company_classifications/",
    companyTextFieldOptions: "/api/v4/company_text_field_options/",
    employeeLicenses: "/api/v4/picklist_items/",
    employeeUnions: "/api/v4/picklist_items/",
    employeeCertifications: "/api/v4/picklist_items/",
}

export const statusesByUserRole = [
    {
        name: "PENDING",
        label: "Not Approved",
        canChangeToStatus: ["FOREMAN", "OFFICE_STAFF", "PM", "PAYROLL_ADMIN", "ADMIN"],
        canEditCell: ["FOREMAN", "OFFICE_STAFF", "PM", "PAYROLL_ADMIN", "ADMIN"],
        selectedColor: "orange-empty",
        trafficColor: "orange-filled-light",
    },
    {
        name: "SUPERVISOR_APPROVED",
        label: "Supervisor Approved",
        canChangeToStatus: ["PM", "PAYROLL_ADMIN", "ADMIN"],
        canEditCell: ["PM", "PAYROLL_ADMIN", "ADMIN"],
        selectedColor: "green-empty",
        trafficColor: "green-empty-light",
    },
    {
        name: "APPROVED",
        label: "Payroll Verified",
        canChangeToStatus: ["PAYROLL_ADMIN", "ADMIN"],
        canEditCell: ["PAYROLL_ADMIN", "ADMIN"],
        selectedColor: "green-filled",
        trafficColor: "green-filled-light",
    },
    {
        name: "EXPORTED",
        label: "Exported",
        canChangeToStatus: ["PAYROLL_ADMIN", "ADMIN"],
        canEditCell: ["PAYROLL_ADMIN", "ADMIN"],
        selectedColor: "gray-filled",
        trafficColor: "gray-filled-light",
    },
    {
        name: "SYNCED",
        label: "Synced",
        canChangeToStatus: [],
        canEditCell: [],
        selectedColor: "gray-filled",
        trafficColor: "",
    },
]

// The old approval workflow
export const NoApExStatusesByUserRole = [
    {
        name: "PENDING",
        label: "Not Approved",
        canChangeToStatus: ["PM", "PAYROLL_ADMIN", "ADMIN"],
        canEditCell: ["FOREMAN", "OFFICE_STAFF", "PM", "PAYROLL_ADMIN", "ADMIN"],
        selectedColor: "orange-empty",
        trafficColor: "orange-filled-light",
    },
    {
        name: "APPROVED",
        label: "Approved",
        canChangeToStatus: ["PAYROLL_ADMIN", "ADMIN"],
        canEditCell: ["PAYROLL_ADMIN", "ADMIN"],
        selectedColor: "green-filled",
        trafficColor: "green-filled-light",
    },
    {
        name: "EXPORTED",
        label: "Exported",
        canChangeToStatus: ["PAYROLL_ADMIN", "ADMIN"],
        canEditCell: ["PAYROLL_ADMIN", "ADMIN"],
        selectedColor: "gray-filled",
        trafficColor: "gray-filled-light",
    },
]

// all others default to "id"
export const referenceableValueKeyOverrides = {
    schemaStatusNames: "name",
    employeeTrades: "name",
    employeeClassifications: "name",
    timekeepingStatuses: "name",
    companyTextFieldOptions: "name",
}

// universal time formats - 12H and 24H
export const TIME_FORMAT = "h:mm a"
export const TIME_FORMAT_24H = "HH:mm"

// formats for use with JS Date constructor
export const DATE_ONLY_FORMAT = "MMMM dd, yyyy"
export const DATETIME_FORMAT = "MMMM d, yyyy - h:mm a"
export const DATETIME_FORMAT_24H = "MMMM d, yyyy - HH:mm"
export const DATE_MM_DD_YYYY = "MM/dd/yyyy"
export const DATE_M_D_YYYY = "M/d/yyyy"
export const DATE_MM_DD = "MM/dd"
export const DATE_MMM_D = "MMM d" // ex Jan 9
export const DATE_MMM_D_YYYY = "MMM d, yyyy" // ex. Jan 9, 2023
export const DATETIME_MM_DD_YYYY_12H = `${DATE_MM_DD_YYYY} - ${TIME_FORMAT}`
export const WEEKDAY_M_D = "EEEE, M/d"
export const WEEKDAY_M_D_YYYY = "EEEE, MMMM d, yyyy" // ex. Monday, January 9, 2023

// Stored formats are ISO8601 - datetimes us JS Date's toISOString()
export const STORED_DATE_ONLY_FORMAT = "yyyy-MM-dd" // 2019-09-27
export const MINUTE_INTERVAL = 15

export const WEEKDAY_INDEX = {
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
    SUNDAY: 0,
}

/** Quantity Entry Types */
const discreteQuantity = "DISCRETE_QUANTITY"
const percentComplete = "PERCENT_COMPLETE"
/** Quantity entry type options. */
export const entryTypeOptions = [
    { data: discreteQuantity, value: discreteQuantity },
    { data: percentComplete, value: percentComplete },
]
/** Quantity entry type map from database name to custom table name. */
export const entryTypeMap = {
    [discreteQuantity]: "Discrete Quantity",
    [percentComplete]: "% Complete",
}

/**
 * Boolean Options.
 * - Need to be in the string format if using with React Select.
 * - Otherwise, React Select sets the false value to undefined.
 */
export const booleanOptions = [
    { data: "true", value: "true" },
    { data: "false", value: "false" },
]

/** Project cost code reporting visibility map from database name to custom table name. */
export const reportingVisibilityMap = {
    true: "Visible",
    false: "Hidden",
}

/** Status values on the CSV file must conform to these options */
export const statusOptionsMap = {
    true: "Active",
    false: "Inactive",
}

// The permissions that can be assigned to the guest who receives a shared form. These string values
// must match the strings coming from the API
export const VIEW_ONLY = "VIEW_ONLY"
export const REQUEST_SIGNATURE = "REQUEST_SIGNATURE"

export const EMPLOYEE_MAIN_GROUP_ASSIGNMENT = "Main (all projects)"
export const EMPLOYEE_PROJECT_SPECIFIC_ASSIGNMENTS = "Project Specific"
export const EMPLOYEE_MAIN_GROUP_AVAILABLE_SELECTOR_OPTIONS = [
    EMPLOYEE_MAIN_GROUP_ASSIGNMENT,
    EMPLOYEE_PROJECT_SPECIFIC_ASSIGNMENTS,
]

export const BUNDLE_TABLE_NAME = "Saved Bundles"
export const INDIVIDUAL_FORM_TABLE_NAME = "Individual Forms"
export const AUTH_EXPIRY = 14

export const PDF_PATH = "/rhumbix/shared/:id/:splat?"
export const GUEST_PATH = "/rhumbix/guest_access/:signed_key"
export const EXTERNAL_PATHS = [PDF_PATH, GUEST_PATH]
export const WEEKLY_TK_PDF_PATH = "/rhumbix/print/weekly-tk"

export const RESOURCE_TO_HUMAN_READABLE = {
    timekeepingEntries: { single: "Timekeeping Entry", plural: "Timekeeping Entries" },
    employeeEntries: { single: "Shift Extra", plural: "Shift Extras" },
    absences: { single: "Absence", plural: "Absences" },
    ewsStartStopTimes: { single: "Shift/Break", plural: "Shifts/Breaks" },
}

export const weeklyTkDummyProject = { name: "No Project", job_number: "--", id: -1 }

const emptyEntity = {
    objects: {},
    invalidate: false,
}

const emptyRefState: Record<number, any> = {}

export const emptyReferenceableDict = {
    cohorts: emptyRefState,
    cohortEmployees: emptyRefState,
    companyAbsenceTypes: emptyRefState,
    companyFormSchemas: emptyRefState,
    companyFormStores: emptyRefState,
    companyGroups: emptyRefState,
    companyStartStopTypes: emptyRefState,
    costCodes: emptyRefState,
    costItems: emptyRefState,
    changeOrders: emptyRefState,
    costCodeControls: emptyRefState,
    companyCrewTypes: emptyRefState,
    employees: emptyRefState,
    employeeTrades: emptyRefState,
    employeeClassifications: emptyRefState,
    guestFormShares: emptyRefState,
    projectEmployees: emptyRefState,
    employeeSchemas: emptyRefState,
    equipment: emptyRefState,
    materials: emptyRefState,
    projectMaterials: emptyRefState,
    projectEquipment: emptyRefState,
    projects: emptyRefState,
    companies: emptyRefState,
    schemaStatusNames: emptyRefState,
    analyticsDashboards: emptyRefState,
    companyTrades: emptyRefState,
    companyClassifications: emptyRefState,
    picklistItems: emptyRefState,
    workShifts: emptyRefState,
    timekeepingStatuses: emptyRefState,
    timelineEntryVersions: emptyRefState,
}

export const emptyEntityState = {
    cohorts: emptyEntity,
    cohortEmployees: emptyEntity,
    companyAbsenceTypes: emptyEntity,
    companyFormSchemas: emptyEntity,
    companyFormStores: emptyEntity,
    companyGroups: emptyEntity,
    companyStartStopTypes: emptyEntity,
    costCodes: emptyEntity,
    costItems: emptyEntity,
    changeOrders: emptyEntity,
    costCodeControls: emptyEntity,
    companyCrewTypes: emptyEntity,
    employees: emptyEntity,
    employeeTrades: emptyEntity,
    employeeClassifications: emptyEntity,
    guestFormShares: emptyEntity,
    projectEmployees: emptyEntity,
    employeeSchemas: emptyEntity,
    equipment: emptyEntity,
    materials: emptyEntity,
    projectMaterials: emptyEntity,
    projectEquipment: emptyEntity,
    projects: emptyEntity,
    companies: emptyEntity,
    schemaStatusNames: emptyEntity,
    analyticsDashboards: emptyEntity,
    companyTrades: emptyEntity,
    companyClassifications: emptyEntity,
    picklistItems: emptyEntity,
    timekeepingStatuses: emptyEntity,
    timelineEntryVersions: emptyEntity,
    workShifts: emptyEntity,
}

export const CICO_TYPE_SHIFTS_AND_BREAKS_MAP = {
    Shift: "CLOCK_IN",
    Break: "START_BREAK",
    Meal: "START_MEAL",
}

export const TIMELINE_ENTRY_TYPE = {
    CLOCK_IN: "CLOCK_IN",
    START_BREAK: "START_BREAK",
    START_MEAL: "START_MEAL",
    END_MEAL: "END_MEAL",
    END_BREAK: "END_BREAK",
    CLOCK_OUT: "CLOCK_OUT",
    CHANGE_TASK: "CHANGE_TASK",
}

export const CICO_LOOKUP = {
    CLOCK_IN: "Start Shift",
    START_BREAK: "Start Break",
    START_MEAL: "Start Meal",
    END_MEAL: "End Meal",
    END_BREAK: "End Break",
    CLOCK_OUT: "End Shift",
    // CHANGE_TASK: "Change Task",
}

export const CICO_REVERSE_LOOKUP = {
    "Start Shift": "CLOCK_IN",
    "Start Break": "START_BREAK",
    "Start Meal": "START_MEAL",
    "End Meal": "END_MEAL",
    "End Break": "END_BREAK",
    "End Shift": "CLOCK_OUT",
    "Change Task": "CHANGE_TASK",
}

export const TIMELINE_STATE = {
    CLOCKED_IN: "CLOCKED_IN",
    ON_BREAK: "ON_BREAK",
    ON_MEAL: "ON_MEAL",
    CLOCKED_OUT: "CLOCKED_OUT",
}

export const TIMELINE_STATE_MACHINE = {
    // If you're clocked out, you can only clock in
    [TIMELINE_STATE.CLOCKED_OUT]: {
        [TIMELINE_ENTRY_TYPE.CLOCK_IN]: TIMELINE_STATE.CLOCKED_IN,
    },

    // If you're clocked in, you can start meal/break, clock out, or change cost code
    [TIMELINE_STATE.CLOCKED_IN]: {
        [TIMELINE_ENTRY_TYPE.START_BREAK]: TIMELINE_STATE.ON_BREAK,
        [TIMELINE_ENTRY_TYPE.START_MEAL]: TIMELINE_STATE.ON_MEAL,
        [TIMELINE_ENTRY_TYPE.CHANGE_TASK]: TIMELINE_STATE.CLOCKED_IN,
        [TIMELINE_ENTRY_TYPE.CLOCK_OUT]: TIMELINE_STATE.CLOCKED_OUT,
    },

    // If you're on a meal or break, you can only end that meal/break
    [TIMELINE_STATE.ON_BREAK]: { [TIMELINE_ENTRY_TYPE.END_BREAK]: TIMELINE_STATE.CLOCKED_IN },
    [TIMELINE_STATE.ON_MEAL]: { [TIMELINE_ENTRY_TYPE.END_MEAL]: TIMELINE_STATE.CLOCKED_IN },
}
