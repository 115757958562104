import { AnyAction, Dispatch as iDispatch } from "redux"

import * as API from "../api"
import { flattenData } from "../common/ag-grid-utils"
import { getSourceDataForGroupKeyInfo } from "../common/ag-grid-grouping-utils"
import { iCopyCellAction, iCopyPivotCellAction, tBulkDeleteSourceDataAction, tBulkActionParams } from "./types"
import { tResourceName, iJSONObject, AsyncThunk, ReduxState } from "../common/types"
import { sourceDataAdded, sourceDataDeleted, actionError, setSaveStatus } from "../dashboard-data/actions"
import { MutableSourceData, tBaseSourceDataAction } from "../dashboard-data/types"
import { tContext, tGroupKeyInfo } from "../components/custom-dashboards/types"
import { tSourceData, tSourceDataResponse, tResourceObject, tSourceDataErrorIds } from "../dashboard-data/types"
import { RowNode } from "ag-grid-community"
import { sourceDataUpdated, SSRMRowDeleted } from "../dashboard-data/actions"
import { getMessageFromAPIErrorResponse } from "../common/ts-utils"
import { submitSelectedFormShareInfo } from "../api/post"
import { getFlagEnabled } from "../getFlagValue"
import { performDataRefresh } from "../actions/server-side-row-model"
import { ThunkDispatch } from "redux-thunk"
import { loadEntities } from "../cached-data/actions"
import { getDescriptionOfSourceData } from "../common/ag-grid-ts-utils"

// Utils ----------------------------------------------------------------------

// TODO: response is actually an error response from the promise call but we have different shapes for that
// in api_v3 vs api_v4 and we should better indicate it.
// HACK: iJSONObject is a workaround for now. Kill it if you see this.
const _errorResponseHandler = (
    dispatch: iDispatch<any>,
    sourceData: tSourceData,
    reason: { response: iJSONObject; resource: tResourceName },
    message: string,
    showErrorModal: boolean
) => {
    const defaultMessage = "You do not have permission to perform this action."
    const { response, resource } = reason
    if (response) {
        if (showErrorModal) {
            const errorMessage = getMessageFromAPIErrorResponse(response, defaultMessage)
            return dispatch(actionError(true, errorMessage))
        }
        if (response["detail"] !== undefined && response["detail"]) {
            return dispatch(actionError(true, response["detail"] as string))
        }
        // TODO: revisit this as it's a weird side effect approach
        const errorField = Object.keys(response)[0] as tResourceName
        _setSourceDataErrors(dispatch, sourceData, resource, response, errorField, message)
    } else {
        dispatch(actionError(true, "An unknown error occurred"))
    }
}

const _getBulkActionParams = (data: tSourceData, initialActionParams?: tBulkActionParams): tBulkActionParams => {
    const actionParams = Object.assign({}, initialActionParams)
    actionParams.resourceToId = {}
    // convert dictionary of resources to rows to dictionary of resources to ids
    for (const untypedResource in data) {
        const resource = untypedResource as tResourceName
        const resourceData = data[resource] || []
        const gridIds = []
        const objectIds = []
        for (const obj of resourceData) {
            if (obj.gridId !== undefined && obj.gridId) {
                gridIds.push(obj.gridId)
            }
            if (obj.id !== undefined && obj.id) {
                objectIds.push(obj.id)
            }
        }
        actionParams.resourceToId[resource] = {
            gridIds: gridIds,
            // rows that have not been synced to the server (ex: an empty row) do not have ids yet,
            // filter them out, we don't want to send undefined ids to the server
            object_ids: objectIds,
        }
    }
    return actionParams
}

// Delete ---------------------------------------------------------------------

export const deleteSourceData = (
    sourceData: tSourceData,
    actionParams?: tBulkActionParams,
    isSSRM?: boolean
): AsyncThunk<void | tBaseSourceDataAction> => dispatch => {
    const promises = []
    const apiActionParams = _getBulkActionParams(sourceData, actionParams)
    dispatch(setSaveStatus("in-progress"))

    for (const untypedResource in apiActionParams.resourceToId) {
        const resource = untypedResource as tResourceName
        const currentResource = apiActionParams.resourceToId[resource]
        if (currentResource == undefined) {
            continue
        }
        if (currentResource.object_ids.length === 0) {
            continue
        }
        const args = [resource, currentResource.object_ids, apiActionParams["current_project_id"]]
        promises.push(
            new Promise((resolve, reject) => {
                API.deleteItemsById(...args)
                    .then(resolve)
                    .catch(result =>
                        reject({
                            resource: resource,
                            response: result.response ? result.response.data : "",
                        })
                    )
            })
        )
    }

    return Promise.all(promises).then(
        () => {
            dispatch(setSaveStatus("saved"))
            if (isSSRM) {
                dispatch(SSRMRowDeleted(sourceData))
                dispatch(performDataRefresh(true))
            } else return dispatch(sourceDataDeleted(sourceData))
        },
        reason => {
            dispatch(setSaveStatus("failed"))
            _errorResponseHandler(dispatch, sourceData, reason, "These objects cannot be deleted", true)
        }
    )
}

/**
 * Mark rows as ready for deletion (by setting the "deleted_on" attribute). Do not actually remove them yet,
 * this will happen when the "OK" button is hit. We do remove any rows that have not been saved to the database
 * yet
 * @param sourceData Source data being acted upon
 * @param selectedRows AG Grid nodes that are being acted upon
 * @param isSSRM Whether this is a Server-Side Row Model table
 */
export const softDeleteSourceData = (sourceData: tSourceData, selectedRows: RowNode[], isSSRM?: boolean) => (
    dispatch: ThunkDispatch<ReduxState, null, AnyAction>
) => {
    const updatedData: tSourceData = {}
    const deletedData: tSourceData = {}
    const time = new Date().toISOString()
    for (const [key, value] of Object.entries(sourceData)) {
        // Any rows with an ID need to be given a "deleted_on" timestamp
        updatedData[key as tResourceName] = value
            ?.filter(v => v.id)
            .map(v => {
                v.deleted_on = time
                // We also mark as modified or else the Save & Close button won't be triggered
                v.modified = true
                return v
            })
        // Find any rows that do not have an ID. They can simply be removed from the source data
        // and do not need to be greyed out for later.
        deletedData[key as tResourceName] = value?.filter(v => !v.id)
    }
    selectedRows.forEach(node => {
        node.data.deleted_on = time
        node.setData(node.data)
    })
    // TODO: Test this part. At the time of writing, no SSRM table exists which would use softDelete
    if (isSSRM) {
        // We delete any new rows
        dispatch(SSRMRowDeleted(deletedData))
        return dispatch(performDataRefresh(true))
    }
    dispatch(sourceDataDeleted(deletedData))
    return dispatch(sourceDataUpdated(updatedData))
}

const _deleteOldCellData = (
    context: tContext,
    groupKeyInfo: tGroupKeyInfo[],
    filteredSourceData: { [key: string]: any } = {}
): AsyncThunk<void | tBaseSourceDataAction> => (dispatch, getState) => {
    // use getState() here because we want to delete whatever is in the cell at thetime this runs
    // ex: paste twice on the same cell quickly so that the second paste occurs before the first one completes
    const sourceData = getState().sourceData.sourceData

    let sourceDataToDelete: { [key: string]: any } = {}
    if (getFlagEnabled("WA-7151-fancy-filter-before-action-buttons") && Object.keys(filteredSourceData).length) {
        sourceDataToDelete = filteredSourceData
    } else {
        sourceDataToDelete = getSourceDataForGroupKeyInfo(sourceData, groupKeyInfo, context)
    }

    // Don't delete dummy cells, as they're needed to maintain the table's column headers
    for (const resource in sourceDataToDelete) {
        sourceDataToDelete[resource] = sourceDataToDelete[resource].filter((col: any) => !col.dummy)
    }

    return dispatch(deleteSourceData(sourceDataToDelete))
}

// TODO: instead of chaining all actions together, disable all actions on data with matching GroupKeyInfo until
// finished
let lastCustomDashboardPromise: Promise<tBulkDeleteSourceDataAction> | null = null

export const deletePivotCell = (
    context: tContext,
    groupKeyInfo: tGroupKeyInfo[],
    sourceDataToDelete: { [key: string]: any } = {}
): AsyncThunk<tBulkDeleteSourceDataAction> => async dispatch => {
    if (lastCustomDashboardPromise) {
        await lastCustomDashboardPromise
    }
    lastCustomDashboardPromise = dispatch(_deleteOldCellData(context, groupKeyInfo, sourceDataToDelete))
    return lastCustomDashboardPromise
}

// Copy -----------------------------------------------------------------------

export const copyCell = (copiedData: string): iCopyCellAction => ({
    type: "CELL_COPY",
    payload: {
        error: null,
        data: copiedData,
    },
})

export const copyPivotCell = (copiedData: tSourceData): iCopyPivotCellAction => ({
    type: "PIVOT_CELL_COPY",
    payload: {
        error: null,
        data: copiedData,
    },
})

// Paste ----------------------------------------------------------------------

const _prepareSourceData = (dataToAdd: tSourceData, dataToReplace: tSourceData): any => {
    // Combine the data to replace with the data to add
    const preparedSourceData: Record<string, any> = {}

    // Mark the rows to delete
    for (const propertyName of Object.getOwnPropertyNames(dataToReplace)) {
        const resourceName = propertyName as tResourceName
        const resourceData = dataToReplace[resourceName] || []
        const dataMarkedToDelete = resourceData.map((row: tResourceObject) => {
            const flattenedRow = flattenData(row)
            flattenedRow["delete"] = true
            return flattenedRow
        })

        preparedSourceData[resourceName] = dataMarkedToDelete
    }

    // Add the new rows
    for (const propertyName of Object.getOwnPropertyNames(dataToAdd)) {
        const resourceName = propertyName as tResourceName
        const resourceData = dataToAdd[propertyName as tResourceName] || []
        const flattenedData = resourceData.map((row: tResourceObject) => {
            const flattenedRow: Record<string, any> = flattenData(row)
            flattenedRow["delete"] = false
            delete flattenedRow.id // This will be a new row, so remove ID
            return flattenedRow
        })
        preparedSourceData[resourceName] = [...preparedSourceData[resourceName], ...flattenedData]
    }

    return preparedSourceData
}

export const pastePivotCell = (
    context: tContext,
    groupKeyInfo: tGroupKeyInfo[],
    sourceDataToCreate: tSourceData
): AsyncThunk<any> => async (dispatch, getState) => {
    // Grab the current contents of the cell, so that they can be deleted after the paste
    // operation successfully completes
    const previousCellContents: tSourceData = getSourceDataForGroupKeyInfo(
        getState().sourceData.sourceData,
        groupKeyInfo,
        context,
        ["timelineEntryVersions"]
    )

    if (getFlagEnabled("WA-8225-fancy-find-copy-paste-bug")) {
        const sourceDataDescription = getDescriptionOfSourceData(previousCellContents)
        const description = sourceDataDescription.description
            ? `${sourceDataDescription.description}<br>This action cannot be undone`
            : "This action cannot be undone"
        if (sourceDataDescription.numRecords) {
            context.createModalAction({
                title: "Pasting into this cell will delete the following contents:",
                description: description,
                action: () =>
                    doPivotPaste(sourceDataToCreate, previousCellContents, dispatch, context, groupKeyInfo),
                buttonClass: "continueButton",
                backgroundCloseEnabled: true,
                close: () => context.createModalAction(null),
            })
            return
        }
    }

    return doPivotPaste(sourceDataToCreate, previousCellContents, dispatch, context, groupKeyInfo)
}

const doPivotPaste = (
    sourceDataToCreate: tSourceData,
    previousCellContents: tSourceData,
    dispatch: ThunkDispatch<ReduxState, null, AnyAction>,
    context: tContext,
    groupKeyInfo: tGroupKeyInfo[]
) => {
    // For the delete case, we want to send the filtered data along to be deleted
    lastCustomDashboardPromise = dispatch(() => {
        const data = _prepareSourceData(sourceDataToCreate, previousCellContents)
        return API.pasteCell(data)
    })
        .then((result: MutableSourceData) => {
            dispatch(_deleteOldCellData(context, groupKeyInfo, previousCellContents))
            dispatch(sourceDataAdded(result))
            const newWorkShifts = Object.values(result).reduce((agg, objectList) => {
                objectList.forEach(entity => {
                    if (
                        entity.work_shift_id &&
                        typeof entity.work_shift_id === "object" &&
                        !(entity.work_shift_id.id in agg)
                    ) {
                        agg[entity.work_shift_id.id] = entity.work_shift_id
                    }
                })
                return agg
            }, {} as Record<number, tResourceObject>)
            dispatch(loadEntities({ workShifts: newWorkShifts }))
        })
        .catch((errors: any) => {
            let message = "An error has occurred."

            if (errors.response?.data)
                message = Object.values(errors.response.data)
                    .map((value: any) => Object.values(value)?.join(" "))
                    .join(" ")
            else if (errors.message) message = errors.message

            dispatch(setSaveStatus("failed"))
            dispatch(actionError(true, message))
        })
    return lastCustomDashboardPromise
}

// Bulk Actions --------------------------------------------------------------

const _getSourceDataWithUpdatedValues = (
    sourceData: tSourceData,
    field: string,
    value: string | number | boolean | { row: string },
    addModified = false
) => {
    const updatedSourceData = {} as tSourceData
    for (const untypedResource in sourceData) {
        const resourceName = untypedResource as tResourceName
        const currentResource = sourceData[resourceName]
        if (currentResource == undefined) {
            continue
        }
        updatedSourceData[resourceName] = currentResource.map(row => {
            if (addModified) row["modified"] = true
            return { ...row, [field]: value }
        })
    }
    return updatedSourceData
}

const _setSourceDataErrors = (
    dispatch: iDispatch<any>,
    sourceData: tSourceData,
    resourceName: tResourceName,
    errorRows: tSourceDataErrorIds,
    key: tResourceName,
    message: string
) => {
    const sourceDataThatShouldHaveErrors = {
        [resourceName]: [],
    }
    const resourceRows = sourceData[resourceName]
    const responseRows = errorRows[key]
    if (resourceRows !== undefined && responseRows !== undefined) {
        resourceRows.filter(row => responseRows.findIndex(id => id === row.id) !== -1)
    }
    const sourceDataWithErrors = _getSourceDataWithUpdatedValues(sourceDataThatShouldHaveErrors, "errors", {
        row: message,
    })
    dispatch(sourceDataUpdated(sourceDataWithErrors))
}

/**
 * This method is much like its cousin below, only it merely serves to update AG Grid row data
 * for when we do not have the table automatically saving after any change. The rows will be marked
 * as "modified" and the save will be triggered elsewhere.
 * @param data Source data to update
 * @param actionParams parameters passed to the button in the AG Grid settings file
 * @param selectedNodes AG Grid row nodes to update (needed for Server-Side Row Model)
 * @param isSSRM Is this table using Server-Side Row Model
 */
export const bulkUpdateSourceDataFieldNoAutosave = (
    data: tSourceData,
    actionParams: tBulkActionParams,
    selectedNodes: RowNode[] = [],
    isSSRM: boolean
) => (dispatch: ThunkDispatch<any, any, any>) => {
    const apiActionParams = _getBulkActionParams(data, actionParams)
    const updatedSourceData = _getSourceDataWithUpdatedValues(data, actionParams.field, actionParams.value, true)
    if (isSSRM) {
        selectedNodes.forEach(node => {
            node.data[apiActionParams.field] = apiActionParams.value
            node.setData(node.data)
        })
    } else {
        return dispatch(sourceDataUpdated(updatedSourceData))
    }
}

export const bulkUpdateSourceDataField = (
    data: tSourceData,
    actionParams: tBulkActionParams,
    updateUsingResponseData = false,
    selectedNodes: RowNode[] = [],
    isSSRM = false
): AsyncThunk<any> => dispatch => {
    const promises = []
    const apiActionParams = _getBulkActionParams(data, actionParams)
    dispatch(setSaveStatus("in-progress"))
    for (const untypedResourceName in apiActionParams.resourceToId) {
        const resourceName = untypedResourceName as tResourceName
        const currentResource = apiActionParams.resourceToId[resourceName]
        if (currentResource == undefined) {
            continue
        }
        promises.push(
            new Promise((resolve, reject) => {
                API.updateItemsValueById(
                    resourceName,
                    currentResource.object_ids,
                    apiActionParams.field,
                    apiActionParams.value,
                    updateUsingResponseData
                )
                    .then(result =>
                        resolve({
                            resourceName: resourceName,
                            data: result,
                        })
                    )
                    .catch(result =>
                        reject({
                            resource: resourceName,
                            response: result.response.data,
                        })
                    )
            })
        )
    }

    return Promise.all(promises).then(
        response => {
            let updatedSourceData: tSourceData = {}
            // if updateUsingResponseData is true, this will parse out
            // updated row data from the backend response to update the grid's row data
            if (updateUsingResponseData) {
                // map the id from the response data to its id within the row in ag grid
                // in order to replace that row with updated data
                response.forEach(resourceResponse => {
                    const resResponse = resourceResponse as tSourceDataResponse
                    const resData = resResponse.data
                    if (resData) {
                        updatedSourceData[resResponse.resourceName] = resResponse.data.map(
                            (responseRow: tResourceObject) => {
                                const originalSourceData = data[resResponse.resourceName]
                                if (originalSourceData) {
                                    const gridRow = originalSourceData.find(
                                        gridRow => gridRow.id === responseRow.id
                                    )
                                    if (gridRow) {
                                        return { ...responseRow, gridId: gridRow.gridId }
                                    }
                                }
                            }
                        )
                    }
                })
            }
            // if updateUsingResponseData is false, this will update the
            // grid's row data based on the "field" and "value" attributes
            // defined in the button's "args" prop that is defined in
            // via the dashboard settings file
            else {
                updatedSourceData = _getSourceDataWithUpdatedValues(data, actionParams.field, actionParams.value)
            }
            dispatch(setSaveStatus("saved"))
            if (isSSRM) {
                selectedNodes.forEach(node => {
                    node.data[apiActionParams.field] = apiActionParams.value
                    node.setData(node.data)
                })
            } else {
                return dispatch(sourceDataUpdated(updatedSourceData))
            }
        },
        reason => {
            dispatch(setSaveStatus("failed"))
            _errorResponseHandler(
                dispatch,
                data,
                reason,
                "These rows cannot be updated.",
                // always show error modal
                true
            )
        }
    )
}

// Re-send e-mail invitations with links to the specified guest form shares
export const reshareGuestFormShares = (data: any): AsyncThunk<void | tBaseSourceDataAction> => dispatch => {
    dispatch(setSaveStatus("in-progress"))

    const promise = new Promise((resolve, reject) => {
        submitSelectedFormShareInfo(data)
            .then(resolve)
            .catch(result =>
                reject({
                    resource: "guestFormShares",
                    response: result.response.data,
                })
            )
    })

    return promise.then(
        () => {
            dispatch(setSaveStatus("saved"))
        },
        reason => {
            dispatch(setSaveStatus("failed"))
            _errorResponseHandler(dispatch, data, reason, "These links could not be resent", true)
        }
    )
}

export const setGroupByState = (tableName: string, label: string) => {
    return {
        type: "SET_GROUP_BY_STATE",
        tableName,
        label,
    }
}
