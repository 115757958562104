import { loadAllEntities } from "../../../cached-data/actions"
import { emailValueSetter, employeeRoleValueSetter, phoneValueSetter } from "../../../common/ag-grid-value-setters"

import {
    phoneNumberFormatter,
    titleCaseFormatter,
    filterFormatter,
    isActiveValueFormatter,
    dateTimeValueFormatter,
    declinableBooleanValueFormatter,
    emailFormatter,
} from "../../../common/ag-grid-value-formatters"
import { getFlagEnabled } from "../../../getFlagValue"
import { EMPLOYEE_ROLE_DEFAULT_OPTION, EMPLOYEE_ROLE_OPTIONS } from "../../../common/constants"
import { isValidEmail, isLowercaseEmail } from "../../../common/validators"
import {
    checkboxColDefCreator,
    stringColDefCreator,
    booleanColDefCreator,
    employeeGroupsColDefCreator,
    equipmentColDefCreator,
    datetimeColDefCreator,
    companyCrewTypeColDefCreator,
    picklistColDefCreator,
    employeeCohortsColDefCreator,
    modifyButtonColDefCreator,
} from "./standard-col-def-creators"
import { enumFilterCreator, referenceableSelectorFilterCreator } from "../../../filters/filter-def-creators"
import { employeeStatusFilterDef, getEnumOption } from "../../../filters/filter-defs"
import { textFieldOptionCreator, picklistItemCreator } from "../../../filters/filter-def-creators"
import { getDisallowedRoles } from "../../../common/ts-utils"

export function getEmployeesSettings(featureFlags, _currentProject, currentUser) {
    const is_editable = featureFlags && !featureFlags.read_only_employees
    const cellClasses = is_editable ? [] : ["readonly"]
    const showLaborType = featureFlags.cost_code_controls
    const pinned = { pinned: "left" }

    const activateButton = {
        label: "Activate",
        icon: "view",
        action: "toggleEmployeeStatus",
        args: { is_active: true },
        disabled: context => {
            const disallowedRoles = getDisallowedRoles(context.currentUser)
            return context.selectedRows.some(r => disallowedRoles.includes(r.user_role))
        },
        tooltip: context => {
            const disallowedRoles = getDisallowedRoles(context.currentUser)
            if (context.selectedRows.some(r => disallowedRoles.includes(r.user_role)))
                return "You may only perform this action on team members at or below " + "your role"
            return featureFlags.cico
                ? "Activate the employee(s) for your company. Also allows employee(s)" + " to log in to Rhumbix."
                : "Activate the employee(s) for your company. Also allows non-Workers" + " to log in to Rhumbix."
        },
    }
    const deactivateButton = {
        label: "Deactivate",
        icon: "hide",
        action: "toggleEmployeeStatus",
        args: { is_active: false },
        disabled: context => {
            const disallowedRoles = getDisallowedRoles(context.currentUser)
            return context.selectedRows.some(r => disallowedRoles.includes(r.user_role))
        },
        tooltip: context => {
            const disallowedRoles = getDisallowedRoles(context.currentUser)
            if (context.selectedRows.some(r => disallowedRoles.includes(r.user_role)))
                return "You may only perform this action on team members at or" + " below your role"
            return (
                "Deactivate the employee(s) for your company. Also prevents them" +
                " from being able to log in to Rhumbix."
            )
        },
    }
    const inviteToRhumbixButton = {
        label: "Invite to Rhumbix App",
        action: "sendWelcomeMail",
        icon: "worker",
        disabled: context => context.selectedRows.some(row => !row.user_id || !row.email),
        tooltip: context => {
            if (context.selectedRows.some(row => !row.email))
                return "Some of the selected employees do not have email addresses"
            if (context.selectedRows.some(row => !row.user_id))
                return "Some of the selected employees are not configured to log in"
            return "Send a welcome email with a link to log in to Rhumbix"
        },
    }
    const resetPasswordButton = {
        label: "Reset Password",
        action: "sendResetPasswordMail",
        icon: "refresh",
        disabled: context => context.selectedRows.some(r => !r.user_id || !r.email),
        tooltip: context => {
            if (context.selectedRows.some(row => !row.user_id))
                return "Some of the selected employees are not configured to log in"
            if (context.selectedRows.some(row => !row.email))
                return "Some of the selected employees do not have email addresses"
            return "Send an email with a reset password link"
        },
    }
    const enableLoginButton = {
        label: "Enable Login",
        action: "toggleLogin",
        icon: "addCollaborator",
        args: {
            is_active: true,
        },
        disabled: context => {
            const disallowedRoles = getDisallowedRoles(context.currentUser)
            return (
                (!context.currentUser.features.cico && context.selectedRows.some(r => r.user_role === "WORKER")) ||
                context.selectedRows.some(r => !r.email || disallowedRoles.includes(r.user_role))
            )
        },
        tooltip: context => {
            const disallowedRoles = getDisallowedRoles(context.currentUser)
            if (context.selectedRows.some(r => !r.email))
                return "Some of the selected employees do not have email addresses"
            if (!context.currentUser.features.cico && context.selectedRows.some(r => r.user_role === "WORKER"))
                return "Some of the selected employees have the role Worker"
            if (context.selectedRows.some(r => disallowedRoles.includes(r.user_role)))
                return "You may only perform this action on team members at or " + "below your role"
            return "Allows selected employees to log in to Rhumbix"
        },
    }
    const disableLoginButton = {
        label: "Disable Login",
        action: "toggleLogin",
        icon: "remove",
        args: {
            is_active: false,
        },
        disabled: context => {
            const disallowedRoles = getDisallowedRoles(context.currentUser)
            return (
                (!context.currentUser.features.cico && context.selectedRows.some(r => r.user_role === "WORKER")) ||
                context.selectedRows.some(r => !r.email || disallowedRoles.includes(r.user_role))
            )
        },
        tooltip: context => {
            const disallowedRoles = getDisallowedRoles(context.currentUser)
            if (context.selectedRows.some(r => !r.email))
                return "Some of the selected employees do not have email addresses"
            if (!context.currentUser.features.cico && context.selectedRows.some(r => r.user_role === "WORKER"))
                return "Some of the selected employees have the role Worker"
            if (context.selectedRows.some(r => disallowedRoles.includes(r.user_role)))
                return "You may only perform this action on team members at or " + "below your role"
            return "Prevents selected employees from logging in to Rhumbix"
        },
    }

    const enableTextAlertsButton = {
        label: "Enable Text Alerts",
        action: "toggleTextAlerts",
        icon: "add",
        args: {
            is_active: true,
        },
        disabled: context => context.selectedRows.some(row => !row.phone),
        tooltip: context => {
            if (context.selectedRows.some(row => !row.phone))
                return "Some of the selected employees do not have phone numbers"
            return "Opt employees in for text messages"
        },
    }
    const disableTextAlertsButton = {
        label: "Disable Text Alerts",
        action: "toggleTextAlerts",
        icon: "x",
        args: {
            is_active: false,
        },
        disabled: context => context.selectedRows.some(row => !row.phone),
        tooltip: context => {
            if (context.selectedRows.some(row => !row.phone))
                return "Some of the selected employees do not have phone numbers"
            return "Opt employees out of text messages"
        },
    }

    const rowButtons = getFlagEnabled("WA-8582-read-only-employee-changes")
        ? [
              ...(is_editable
                  ? [
                        activateButton,
                        deactivateButton,
                        { separator: true },
                        { label: "Delete", icon: "delete", action: "deleteSelectedRows" },
                    ]
                  : []),
              inviteToRhumbixButton,
              resetPasswordButton,
              enableLoginButton,
              disableLoginButton,
              enableTextAlertsButton,
              disableTextAlertsButton,
          ]
        : [
              ...(is_editable
                  ? [
                        activateButton,
                        deactivateButton,
                        { separator: true },
                        { label: "Delete", icon: "delete", action: "deleteSelectedRows" },
                    ]
                  : []),
              inviteToRhumbixButton,
              resetPasswordButton,
              ...(is_editable
                  ? [enableLoginButton, disableLoginButton, enableTextAlertsButton, disableTextAlertsButton]
                  : []),
          ]

    const textFieldOptions =
        currentUser && currentUser.text_field_options
            ? currentUser.text_field_options.filter(option => option.model === "Employee")
            : []

    const cohortTextFieldOptions =
        currentUser && currentUser.text_field_options
            ? currentUser.text_field_options.filter(option => option.model === "Cohort")
            : []

    const textFieldOptionColDefs = textFieldOptions.map(option =>
        stringColDefCreator({
            headerName: option.label,
            field: `/custom_fields/${option.name}`,
            cellClass: option.read_only_in_app ? ["readonly"] : [],
            editable: !option.read_only_in_app,
        })
    )

    const textFieldOptionfilterDefs = textFieldOptions.map(option =>
        textFieldOptionCreator(option.label, option.model, option.name)
    )

    const defaultTooltip = {}

    const employeeRoleMultiFilterDef = enumFilterCreator({
        key: "employeeRole",
        label: "Employee Role",
        valueKey: "queryStr",
        multiselect: true,
        options: ["Admin", "Payroll Admin", "PM", "Office Staff", "Foreman", "Worker"].map(getEnumOption),
    })

    const tradeFilterDef = referenceableSelectorFilterCreator({
        label: "Trades",
        resourceName: "employeeTrades",
        multiselect: true,
        isSelectorV3: true,
        queryParam: "trades",
    })

    const classificationFilterDef = referenceableSelectorFilterCreator({
        label: "Classifications",
        resourceName: "employeeClassifications",
        multiselect: true,
        isSelectorV3: true,
        queryParam: "classifications",
    })

    const certificationsFilterDef = picklistItemCreator("Certifications", "Certification", "certification_ids")
    const licensesFilterDef = picklistItemCreator("Licenses", "License", "license_ids")
    const unionsFilterDef = picklistItemCreator("Unions", "Union", "union_ids")

    const companyGroupsMultiFilterDef = {
        ...referenceableSelectorFilterCreator({
            resourceName: "companyGroups",
            label: "Groups",
        }),
        multiselect: true,
    }

    const permittedCohortsFilterDef = {
        ...referenceableSelectorFilterCreator({
            resourceName: "cohorts",
            label: "Cohorts",
        }),
        multiselect: true,
        parameterName: "permitted_cohort_ids",
        label: "Permitted Cohort",
        key: "permitted_cohort",
        // Including hidden because this is configuration use of the selector, rather than TK/PT use
        extraSearchFilters: { include_hidden: true },
    }

    const memberCohortsFilterDef = {
        ...referenceableSelectorFilterCreator({
            resourceName: "cohorts",
            label: "Cohorts",
        }),
        multiselect: true,
        parameterName: "member_of_cohort_ids",
        label: "Member of Cohort",
        key: "cohort_member",
        // Including hidden because this is configuration use of the selector, rather than TK/PT use
        extraSearchFilters: { include_hidden: true },
    }

    const permittedCohortTextFieldOptionFilterDefs = cohortTextFieldOptions
        .filter(option => option.model === "Cohort")
        .map(option =>
            textFieldOptionCreator(`Permitted Cohort - ${option.label}`, option.model, option.name, "permitted_")
        )

    const memberCohortTextFieldOptionFilterDefs = cohortTextFieldOptions
        .filter(option => option.model === "Cohort")
        .map(option =>
            textFieldOptionCreator(`Member of Cohort - ${option.label}`, option.model, option.name, "member_")
        )

    const fiterDefs = [
        companyGroupsMultiFilterDef,
        employeeRoleMultiFilterDef,
        employeeStatusFilterDef,
        tradeFilterDef,
        classificationFilterDef,
        ...textFieldOptionfilterDefs,
        licensesFilterDef,
        certificationsFilterDef,
        unionsFilterDef,
        permittedCohortsFilterDef,
        ...permittedCohortTextFieldOptionFilterDefs,
        memberCohortsFilterDef,
        ...memberCohortTextFieldOptionFilterDefs,
    ]

    return {
        tableName: "Employees",
        navId: "employees",
        resources: ["employees"],
        relatedNames: {
            employees: {
                equipment: ["/default_equipment"],
            },
        },
        initAction: () => dispatch => {
            // Load the groups so that we can selectively access and assign users
            // to the Main group
            dispatch(loadAllEntities("companyGroups"))
        },
        filters: fiterDefs,
        colDefs: [
            {
                field: "/project",
                hide: true,
            },
            ...(getFlagEnabled("WA-8582-read-only-employee-changes") || is_editable
                ? [
                      checkboxColDefCreator({
                          headerCheckboxSelection: false,
                          ...pinned,
                      }),
                  ]
                : []),
            stringColDefCreator({
                headerName: "Employee ID*",
                field: "/company_supplied_id",
                headerTooltip: "Your internal company id",
                required: true,
                ...pinned,
            }),
            stringColDefCreator({
                headerName: "First Name*",
                field: "/first_name",
                required: true,
                ...pinned,
            }),
            stringColDefCreator({
                headerName: "Last Name*",
                field: "/last_name",
                required: true,
                ...pinned,
            }),
            {
                ...stringColDefCreator({
                    headerName: "Role*",
                    field: "/user_role",
                    width: 120,
                    enum: EMPLOYEE_ROLE_OPTIONS,
                    default: EMPLOYEE_ROLE_DEFAULT_OPTION,
                    // we always want this to be editable, even if nothing else is
                    editable: true,
                    required: true,
                }),
                valueFormatter: titleCaseFormatter,
                valueSetter: employeeRoleValueSetter,
                customTooltip: undefined,
                cellClass: [],
            },
            {
                ...stringColDefCreator({
                    headerName: "Mobile Phone",
                    field: "/phone",
                    headerTooltip: "Required for Foremen",
                }),
                valueFormatter: phoneNumberFormatter,
                valueSetter: phoneValueSetter,
            },
            {
                ...stringColDefCreator({
                    headerName: "Email",
                    field: "/email",
                    width: 230,
                    headerTooltip: "Required for roles other than Worker",
                }),
                cellEditorParams: {
                    validators: [isValidEmail, isLowercaseEmail],
                },
                ...(getFlagEnabled("WA-8383-lowercase-emails")
                    ? {
                          valueFormatter: emailFormatter,
                          valueSetter: emailValueSetter,
                      }
                    : {}),
            },
            {
                headerName: "Login Enabled",
                valueFormatter: params => {
                    return params.data.email && params.data.user_is_active ? "Yes" : "No"
                },
                cellRendererParams: {
                    computedField: true,
                },
                field: "/login_enabled",
                editable: false,
                minWidth: 140,
            },
            {
                ...booleanColDefCreator({
                    headerName: "Text Alerts OK",
                    field: "/text_alerts_ok",
                    minWidth: 100,
                    editable: false,
                    default: false,
                }),
                valueFormatter: params => (params.data.text_alerts_ok ? "Yes" : "No"),
            },
            stringColDefCreator({
                headerName: "Classification",
                field: "/classification",
            }),
            stringColDefCreator({
                headerName: "Trade",
                field: "/trade",
            }),
            ...(featureFlags.cost_code_level_modifiers
                ? [
                      equipmentColDefCreator({
                          field: "/default_equipment",
                          required: false,
                          editable: false,
                          excludeFromExport: true,
                      }),
                  ]
                : []),
            companyCrewTypeColDefCreator({
                alphabetize: true,
                hide: !showLaborType,
            }),
            {
                ...picklistColDefCreator("Certifications", "/certifications", "Certification"),
                editable: is_editable,
            },
            { ...picklistColDefCreator("Licenses", "/licenses", "License"), editable: is_editable },
            { ...picklistColDefCreator("Unions", "/unions", "Union"), editable: is_editable },
            employeeGroupsColDefCreator({
                groupsCompanyFeatureToggleActive: featureFlags?.groups,
                excludeFromExport: !featureFlags?.groups,
                alphabetize: true,
            }),
            {
                ...employeeCohortsColDefCreator({ includeHidden: true }),
                editable: is_editable || getFlagEnabled("WA-8582-read-only-employee-changes"),
            },
            {
                ...booleanColDefCreator({
                    headerName: "Status",
                    field: "/is_active",
                    minWidth: 100,
                    editable: false,
                    default: true,
                    uneditableMessage: "Select checkbox beside Employee ID to update this field",
                }),
                valueFormatter: isActiveValueFormatter,
            },

            {
                ...stringColDefCreator({
                    headerName: "Disabled Status",
                    field: "/disabled_status",
                    width: 140,
                    enum: ["YES", "NO", "DECLINE"],
                    default: "",
                    valueFormatter: declinableBooleanValueFormatter,
                    required: false,
                }),
            },
            {
                ...stringColDefCreator({
                    headerName: "Veteran Status",
                    field: "/veteran_status",
                    width: 140,
                    enum: ["YES", "NO", "DECLINE"],
                    default: "",
                    valueFormatter: declinableBooleanValueFormatter,
                    required: false,
                }),
            },
            ...(featureFlags.cico
                ? [
                      stringColDefCreator({
                          headerName: "PIN",
                          field: "/cico_pin",
                          width: 230,
                          default: "",
                          required: false,
                          editable: is_editable || getFlagEnabled("WA-8582-read-only-employee-changes"),
                      }),
                      stringColDefCreator({
                          headerName: "QR Code",
                          field: "/cico_qr_code",
                          width: 230,
                          default: "",
                          required: false,
                          editable: is_editable || getFlagEnabled("WA-8582-read-only-employee-changes"),
                      }),
                  ]
                : []),
            datetimeColDefCreator({
                headerName: "Created On",
                editable: false,
                field: "/created_on",
                valueFormatter: dateTimeValueFormatter,
                // Override the default controlled by public api tooltip
                customTooltip: () => {
                    return {
                        tooltipShouldRender: false,
                        data: {
                            messages: [],
                        },
                    }
                },
            }),
            ...textFieldOptionColDefs,
            datetimeColDefCreator({
                headerName: "Last Active",
                editable: false,
                field: "/last_active",
                valueFormatter: dateTimeValueFormatter,
                // Override the default controlled by public api tooltip
                customTooltip: () => {
                    return {
                        tooltipShouldRender: false,
                        data: {
                            messages: [],
                        },
                    }
                },
            }),
            {
                ...modifyButtonColDefCreator({
                    modifyFlow: "EMPLOYEE_MODIFY",
                    headerName: "Settings",
                    field: "/settings",
                    editable: is_editable,
                }),
                excludeFromExport: true,
            },
        ],
        gridSettings: {
            rowHeight: 40,
            defaultColDef: {
                cellClass: cellClasses,
                ...defaultTooltip,
                editable: is_editable,
                getQuickFilterText: filterFormatter,
            },
            // allow our click events to happen
            suppressRowClickSelection: true,
            rowSelection: "multiple",
            // SSRM settings
            rowModelType: "serverSide",
            serverSideStoreType: "partial",
            cacheBlockSize: 100,
        },
        otherSettings: {
            rowLevelErrorDisplay: true,
            enableSearchBar: true,
            hiddenColumnDefaults: {
                project: "projectId",
            },
            buttons: {
                cell: [
                    ...(is_editable
                        ? [
                              {
                                  label: "Edit",
                                  icon: "edit",
                                  action: "editFocusedCell",
                              },
                              {
                                  label: "Copy",
                                  icon: "copy",
                                  action: "copyFocusedCell",
                              },
                              {
                                  label: "Paste",
                                  icon: "paste",
                                  action: "pasteIntoFocusedCell",
                              },
                          ]
                        : []),
                ],
                row: rowButtons,
                table: [
                    ...(is_editable
                        ? [
                              {
                                  label: "Add Employee",
                                  icon: "add",
                                  action: "addEmployee",
                              },
                              {
                                  label: "Upload",
                                  icon: "upload",
                                  action: "navigateTo",
                                  args: { url: "/rhumbix/company-admin/users/upload/" },
                              },
                          ]
                        : []),
                    {
                        label: "Export ▾",
                        icon: "export",
                        action: "export",
                    },
                ],
            },
            rowLevelValidators: [
                data => {
                    if (
                        (data.email == null || data.email.trim().length === 0) &&
                        ["ADMIN", "OFFICE_STAFF", "PAYROLL_ADMIN", "PM", "FOREMAN"].includes(data.user_role)
                    ) {
                        data.errors["/email"] = ["This role requires an email address"]
                    }
                    if (
                        (data.phone == null || data.phone.trim().length === 0) &&
                        ["FOREMAN"].indexOf(data.user_role) > -1
                    ) {
                        data.errors["/phone"] = ["This role requires a mobile phone number"]
                    }
                    return data
                },
            ],
        },
    }
}
