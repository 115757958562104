import { ReactNode, MouseEvent as tMouseEvent } from "react"
import { tNetworkStatusState, tSourceData } from "../dashboard-data/types"
import { tContext } from "../components/custom-dashboards/types"
import { SearchBarState, tResourceName } from "../common/types"
import { SideRailContextInstance } from "../components/SideRail/SideRailContext"
import { ColumnApi, GridApi, RowNode } from "ag-grid-community"
import { Action } from "redux"
import { iTKExportFormat } from "../cached-data/types"

// TODO: Fill in these proxy types with actual values, figure out whether they
// belong here or somewhere else
export type tBulkDeleteSourceDataAction = any

/**
 * The current toolbar "mode", which is based on both the table's settings and
 * the user's current selection
 */
export enum eToolbarMode {
    "TABLE",
    "ROW",
    "CELL",
    "GROUP_ROW",
}

/**
 * Extra arguments that may be passed to a button's event handler via. its
 * button def in the dashboard settings.
 */
export type tButtonArgs = {
    field?: string
    value?: any
    url?: string
    no_autosave?: boolean
    local_state_update?: boolean
    extraArgs?: Record<string, any>
    is_active?: boolean
}

/**
 * The toolbar's props, which are also the extra parameters (along with its
 * state) that are passed to onClick event handlers for toolbar buttons
 */
export type tToolbarProps = {
    clipboard: Record<string, any>
    columnApi: ColumnApi
    context: tContext
    gridApi: GridApi
    groupBy: Record<string, string>
    networkStatus: tNetworkStatusState
    pivotClipboard: tPivotClipboard
    sourceData: tSourceData
    isAudit: boolean
    isLoading: boolean
}

/**
 * The toolbar's props, which is also the extra parameters (along with its
 * props) that are passed to onClick event handlers for toolbar buttons
 */
export type tToolbarState = {
    currentGrouping: string
    rowsExpanded: boolean
}

/**
 * The extra parameters that are passed to onClick event handlers for toolbar
 * buttons
 */
export type tExtraButtonParams = tToolbarProps &
    tToolbarState & {
        args?: tButtonArgs
        node?: RowNode
        dropdown?: boolean
        isCheckbox?: boolean
        isChecklist?: boolean
        isExport?: boolean
        isHistory?: boolean
        setGroupByLabel: (label: string) => void
        toggleRowExpansion: React.Dispatch<React.SetStateAction<boolean>>
        sideRailContext: SideRailContextInstance
        showWarningModal?: boolean
        sourceDataFilterButton?: boolean
        modalTitle?: string
        modalDescription?: string
        updateUsingResponseData?: boolean
        variantName?: string | null
    }

/**
 * A function that is invoked when a button is clicked, but may also be invoked
 * programmatically (e.g. as a side-effect of another user action)
 */
export type tButtonClickHandler = (
    e: tMouseEvent<HTMLButtonElement> | null,
    extraParams: tExtraButtonParams
) => void

/**
 * A tuple of label, action, and icon name
 */
export type tButtonClickHandlerTuple = [
    string, // label
    tButtonClickHandler | tButtonClickHandlerTuple[], // action
    keyof iStandardButtonIcons, // icon
    boolean?, // disabled
    string? // tooltip
]

/**
 * A function that returns a list of button click handler tuples
 */
export type tButtonClickHandlerFactory = (params: tExtraButtonParams) => tButtonClickHandlerTuple[]

/**
 * A mapping of button action names to click handlers for individual cells
 */
export interface iCellEventHandlers {
    clearGridSelection: tButtonClickHandler
    copyFocusedCell: tButtonClickHandler
    copyFocusedCellToClipboardOnly: tButtonClickHandler
    copyFocusedPivotCell: tButtonClickHandler
    copySelectedCells: tButtonClickHandler
    createNewTimecardCell: tButtonClickHandler
    deleteFocusedPivotCell: tButtonClickHandler
    duplicateWeeklyViewCell: tButtonClickHandler
    editFocusedCell: tButtonClickHandler
    editFocusedPivotCell: tButtonClickHandler
    openSideRailTableForFocusedCell: tButtonClickHandler
    openHistoryModalForFocusedPivotCell: tButtonClickHandler
    pasteIntoCellRange: tButtonClickHandler
    pasteIntoFocusedCell: tButtonClickHandler
    pasteIntoFocusedPivotCell: tButtonClickHandler
    getSignatureOptions: tButtonClickHandlerTuple[]
    getSignatureRequestOptions: tButtonClickHandlerTuple[]
    updateTimekeepingStatusForFocusedPivotCell: tButtonClickHandlerTuple[]
}

/**
 * A mapping of button action names to click handlers for rows
 */
export interface iRowEventHandlers {
    addNewRowToGroup: tButtonClickHandler // TODO: Should we make a new set of event handlers?
    archiveSelectedRows: tButtonClickHandler
    bulkUpdateField: tButtonClickHandler
    bulkManageAccess: tButtonClickHandler
    clearGridSelection: tButtonClickHandler
    deleteSelectedRows: tButtonClickHandler
    deleteStatus: tButtonClickHandler
    duplicateRow: tButtonClickHandler
    editTimeCardDetails: tButtonClickHandler
    exportTK: tButtonClickHandler
    getFormVariants: tButtonClickHandlerTuple[]
    bundleForms: tButtonClickHandler
    copyToForm: tButtonClickHandler
    shareLinkToForm: tButtonClickHandler
    openHistory: tButtonClickHandler
    openSideRailCollaboratorsForSelectedRows: tButtonClickHandler
    performWorkflowActionFromSchema: tButtonClickHandlerTuple[]
    getAvailableFormReviewActions: tButtonClickHandlerTuple[]
    reshareGuestShareForm: tButtonClickHandler
    deleteGuestShareForm: tButtonClickHandler
    getOptInOutTMSubmit: tButtonClickHandlerTuple[]
    getOptInOutTMStatus: tButtonClickHandlerTuple[]
    getOptInOutOtherSubmit: tButtonClickHandlerTuple[]
    getOptInOutOtherStatus: tButtonClickHandlerTuple[]
    updateGuestPermissions: tButtonClickHandlerTuple[]
    bulkDownloadPdfs: tButtonClickHandler
    importFromFormOrTimeCard: tButtonClickHandler
    unhideStatus: tButtonClickHandler
    getVariantBundleNames: tButtonClickHandlerTuple[]
    getVariantDownloadNames: tButtonClickHandlerTuple[]
    openDeletePicklistItemModal: tButtonClickHandler
    openCicoDetailsForSelectedRows: tButtonClickHandler
    sendWelcomeMail: tButtonClickHandler
    sendResetPasswordMail: tButtonClickHandler
    toggleEmployeeStatus: tButtonClickHandler
    toggleLogin: tButtonClickHandler
    toggleTextAlerts: tButtonClickHandler
}

/**
 * A mapping of button action names to click handlers for the entire table
 */
export interface iTableEventHandlers {
    addCohortEmployeeRows: tButtonClickHandler
    addEmployee: tButtonClickHandler
    addNewRow: tButtonClickHandler
    addProject: tButtonClickHandler
    addEmployeeRows: tButtonClickHandler
    addCostCodeRows: tButtonClickHandler
    addApiIntegrationToken: tButtonClickHandler
    editTimeCardDetails: tButtonClickHandler
    export: tButtonClickHandlerTuple[]
    groupBy: tButtonClickHandlerTuple[]
    navigateTo: tButtonClickHandler
    navigateToExternal: tButtonClickHandler
    openSideRailCollaborators: tButtonClickHandler
    toggleRowExpansion: tButtonClickHandlerTuple[]
    togglePlaceholders: tButtonClickHandler
    openFieldFormCreate: tButtonClickHandler
    openAddTimekeepingEntryModal: tButtonClickHandler
    openCicoDetails: tButtonClickHandler
    openCreateTimeCardModal: tButtonClickHandler
    openCsvExportForm: tButtonClickHandler
    updateTimekeepingStatusForTable: tButtonClickHandlerTuple[]
    toggleFancySearchBar: tButtonClickHandler
    toggleStatefulButton: tButtonClickHandler
}

/**
 * The set of all possible event handlers
 */
export type tToolbarEventHandlers = iCellEventHandlers | iRowEventHandlers | iTableEventHandlers

/**
 * A mapping of button icon names to components
 */
export interface iStandardButtonIcons {
    add: ReactNode
    addCollaborator: ReactNode
    archive: ReactNode
    bundle: ReactNode
    bulkview: ReactNode
    collaborator: ReactNode
    collapse: ReactNode
    copy: ReactNode
    copyAction: ReactNode
    costCode: ReactNode
    deactivate: ReactNode
    download: ReactNode
    download_all: ReactNode
    delete: ReactNode
    edit: ReactNode
    exception: ReactNode
    expand: ReactNode
    export: ReactNode
    find: ReactNode
    group: ReactNode
    hide: ReactNode
    history: ReactNode
    import: ReactNode
    launch: ReactNode
    paste: ReactNode
    refresh: ReactNode
    signature: ReactNode
    status: ReactNode
    upload: ReactNode
    share: ReactNode
    settings: ReactNode
    remove: ReactNode
    variant: ReactNode
    view: ReactNode
    worker: ReactNode
    x: ReactNode
}

/**
 * Defines a button in the table settings.
 */
export type tButtonParams = {
    action: keyof tToolbarEventHandlers
    args?: tButtonArgs
    ariaLabel: string
    className?: string
    icon?: keyof iStandardButtonIcons
    label: string
    ungroupOptions?: boolean
    showWarningModal?: boolean
    modalTitle?: string
    modalDescription?: string
    updateUsingResponseData?: boolean
    tooltip?: ((context: tContext, sourceData: tSourceData) => string) | string
    disabled?: ((context: tContext, sourceData: tSourceData) => boolean) | boolean
    activeClass?: string
    customButtonType?: string
    hideInHistory?: boolean
    checklist?: boolean
    checkbox?: boolean
    export?: boolean
    sourceDataFilterButton?: boolean
}

/**
 * Signals a simple UI separator in between table action buttons.
 */
export type ToolbarButtonSeparator = {
    separator: true
}

/**
 * Defines a button in the dashboard toolbar. These are passed directly as
 * props to the ToolbarButton component
 */
export type tButtonDef = {
    action: tButtonClickHandler | tButtonClickHandlerTuple[]
    ariaLabel: string
    disabled?: boolean
    icon?: ReactNode
    label: string
    menuIcon?: ReactNode
    params: tExtraButtonParams
    ungroupOptions?: boolean
    tooltip?: string
    activeClass?: string
}

/**
 * All of the props that go to a button
 */
export type tButtonProps = tButtonDef & {
    className?: string
    dropdown?: boolean
    id?: string
    customButtonType?: string
    isLoading?: boolean
    hideLabel?: boolean
    isChecklist?: boolean
    stateAttribute?: keyof SearchBarState
}

export type tChecklistOption = {
    id?: number
    label: string
    description?: string
    action?: (ctx?: tContext) => Action
    activeStateParams?: (keyof SearchBarState)[]
    disabled?: (ctx: tContext, sourceData: tSourceData) => boolean
    hasGear?: boolean
    gearModalType?: string
    isChild?: boolean
}

export type tExportChecklistOption = iTKExportFormat & {
    label: string
}

export type tChecklistSection = {
    isFilterable?: boolean
    isScrollable?: boolean
    label: string
    options: tExportChecklistOption[]
}

/**
 * Defines all the buttons in the dashboard toolbar for a particular table.
 */
export type tDashboardButtonSettings = {
    cell: tButtonDef[]
    row: tButtonDef[]
    table: tButtonDef[]
}

/**
 * The status of a cell
 */
export type tStatus = {
    name: string
    label: string
    canChangeToStatus: string[]
    canEditCell: string[]
    selectedColor: string
    trafficColor: string
}

/**
 * Redux action for copying cells to a redux "clipboard"
 */
export interface iCopyCellAction {
    type: "CELL_COPY"
    payload: {
        error: null
        data: string
    }
}

/**
 * Redux action for copying pivot cells to a redux "clipboard"
 */
export interface iCopyPivotCellAction {
    type: "PIVOT_CELL_COPY"
    payload: {
        error: null
        data: tSourceData
    }
}

/**
 * Valid redux actions for clipboard
 */
export type tClipboardAction = iCopyCellAction

/**
 * Valid redux actions for pivot clipboard
 */
export type tPivotAction = iCopyPivotCellAction

/**
 * Pivot clipboard state in redux
 */
export type tPivotClipboard = tSourceData

export type tBulkFilters = { [key in tResourceName]?: string | number }

export type tBulkActionParams = {
    field: string
    value: string | number
    no_autosave?: boolean
    current_project_id?: number | Array<number>
    // TODO: get rid of this as it is always true
    showErrorModal?: boolean
    resourceToId?: {
        [key in tResourceName]?: {
            gridIds: Array<string | number>
            object_ids: Array<number>
        }
    }
}

export type GroupByAction = {
    type: "SET_GROUP_BY_STATE"
    tableName: string
    label: string
}
