import {
    tFilterState,
    tFilterKey,
    eDatePickerType,
    tFilterDef,
    tErrorFilterDef,
    iMutableFilterState,
    tStartOfWeekIndex,
    tFilterContext,
} from "./types"
import { startOfWeek, endOfWeek, isValid, format } from "date-fns"
import { getAsDate } from "../common/ts-utils"
import { STORED_DATE_ONLY_FORMAT } from "../common/constants"

export const filterHasSingleValueSelected = (filterValue: any): boolean =>
    filterValue != null && (!Array.isArray(filterValue) || filterValue.length === 1)

export const isApplicableFilterValue = (filterValue: any): boolean =>
    filterValue !== null && filterValue !== undefined && (!Array.isArray(filterValue) || filterValue.length > 0)

/**
 * Given the current filter state, and a filter component.
 * If the component is required to be set AND it does not have a value set in the current state
 *     return false
 * Else
 *     return true
 * @param filterState The current filter state
 * @param filterComponent A filter component with all of it's props
 */
export const requiredFilterValidator = (filterState: tFilterState, filterDef: tFilterDef): boolean => {
    return !filterDef.required ? true : !!isApplicableFilterValue(filterState[filterDef.key as tFilterKey])
}

export const calculateStartDate = (
    startDate: Date,
    datePickerType: eDatePickerType,
    startOfWeekIndex: tStartOfWeekIndex
): Date => {
    if (startDate) {
        if (datePickerType === eDatePickerType.WEEKLY) {
            return startOfWeek(startDate, { weekStartsOn: startOfWeekIndex })
        }
        return startDate
    }
    return new Date()
}

export const calculateEndDate = (
    startDate: Date,
    endDate: Date | null,
    datePickerType: eDatePickerType,
    startOfWeekIndex: tStartOfWeekIndex
): Date => {
    if (datePickerType === eDatePickerType.WEEKLY) {
        return endOfWeek(startDate, { weekStartsOn: startOfWeekIndex })
    }

    if (datePickerType === eDatePickerType.DAILY) {
        return startDate
    }

    // Don't allow null/undefined endDates
    return endDate ? endDate : startDate
}

export const removeFilterDefByKey = (
    filters: Array<tFilterDef | tErrorFilterDef>,
    key: tFilterKey | string | Array<string>
): Array<tFilterDef | tErrorFilterDef> => {
    if (Array.isArray(key)) {
        const keySet = new Set(key)
        return filters.filter(item => !keySet.has(item.key))
    }
    return filters.filter(item => item.key !== key)
}

export const dateFilterStateDefaultGetter = (
    filterState: tFilterState,
    filterDef: tFilterDef,
    context: tFilterContext
): tFilterState => {
    let { startDate, endDate } = filterState
    startDate = startDate && isValid(getAsDate(startDate)) ? getAsDate(startDate) : new Date()
    endDate = endDate && isValid(getAsDate(endDate)) ? getAsDate(endDate) : new Date()
    const datePickerType: eDatePickerType = filterDef.datePickerType
        ? filterDef.datePickerType
        : eDatePickerType.CUSTOM
    const dayOfWeekIndex = context.startOfTheWeekIndex
    const newStartDate = calculateStartDate(startDate, datePickerType, dayOfWeekIndex)
    return {
        startDate: newStartDate,
        endDate: calculateEndDate(newStartDate, endDate, datePickerType, dayOfWeekIndex),
    }
}

export const datePickerHasArrows = (datePickerType: eDatePickerType | undefined): boolean => {
    return datePickerType ? [eDatePickerType.DAILY, eDatePickerType.WEEKLY].includes(datePickerType) : false
}

export const getApplicableFilters = (filters: Array<tFilterDef>, filterState: tFilterState): tFilterState =>
    filters.reduce((accum: iMutableFilterState, ele: tFilterDef) => {
        accum = { ...accum, [ele.key]: filterState[ele.key] }
        if (ele.secondaryKey) {
            accum = { ...accum, [ele.secondaryKey]: filterState[ele.secondaryKey] }
        }
        return accum
    }, {} as iMutableFilterState)

export const enumFilterDefaultGetter = (filterState: tFilterState, filterDef: tFilterDef): tFilterState => {
    /*
    We originally had a use case for a default value in an enum, but no longer do.
    I wanted to keep the default getter in place so we could use it down the road if needed

    a case would look like this:
    case "includeDeleted":
        let { includeDeleted } = filterState
        includeDeleted = includeDeleted ? includeDeleted : "0"
        enumState = {includeDeleted: includeDeleted}
        break
    */
    let enumState
    switch (filterDef.key) {
        default:
            enumState = {}
    }
    return enumState
}

export const getFilterValue = (type: string | undefined, key: tFilterKey, rawFilterValues: tFilterState) => {
    let rawValue = rawFilterValues[key]
    if ("boolean" === type && rawValue === undefined) rawValue = false
    return rawValue && type === "date"
        ? format(getAsDate(rawValue as string | Date), STORED_DATE_ONLY_FORMAT)
        : rawValue
}

export const getFilterQueryParams = (filters: tFilterState, settings: Record<string, any>) => {
    return settings && settings.filters
        ? settings.filters.reduce((accum: Record<string, any>, ele: tFilterDef) => {
              const { key, parameterName, secondaryKey, secondaryParameterName, type } = ele
              accum[parameterName] = getFilterValue(type, key, filters)
              if (secondaryKey && secondaryParameterName) {
                  accum[secondaryParameterName] = getFilterValue(type, secondaryKey, filters)
              }
              return accum
          }, {} as Record<string, string>)
        : {}
}
