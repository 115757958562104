import {
    buttonColDefCreator,
    checkboxColDefCreator,
    datetimeColDefCreator,
    employeeColDefCreator,
    modifyButtonColDefCreator,
    stringColDefCreator,
} from "./standard-col-def-creators"
import ManageSchemaAccessButton from "../schemas/ManageSchemaAccessButton"
import { tContext } from "../types"
import { tResourceObject, tSourceData } from "../../../dashboard-data/types"
import { CellClassParams, EditableCallbackParams } from "ag-grid-community"

export const getTkExportFormatSettings = () => {
    return {
        tableName: "Export Formats",
        navId: "tkExportFormats",
        resources: ["tkExportFormats"],
        filters: [],
        additionalQueryParams: { include_all: true },
        relatedNames: {
            tkExportFormats: {
                employees: ["/created_by"],
            },
        },
        colDefs: [
            checkboxColDefCreator({}),
            stringColDefCreator({
                headerName: "Name",
                field: "/name",
            }),
            stringColDefCreator({
                headerName: "Description",
                field: "/description",
            }),
            employeeColDefCreator({
                headerName: "Created By",
                field: "/created_by",
                width: 150,
            }),
            datetimeColDefCreator({
                headerName: "Created On",
                field: "/created_on",
            }),
            datetimeColDefCreator({
                headerName: "Last Exported",
                field: "/last_run",
            }),
            datetimeColDefCreator({
                headerName: "Last Edited",
                field: "/last_updated",
            }),
            stringColDefCreator({
                headerName: "Status",
                field: "/is_active",
                width: 100,
                valueFormatter: params => (params.value ? "Enabled" : "Disabled"),
            }),
            {
                ...modifyButtonColDefCreator({
                    modifyFlow: "MODIFY_CSV_EXPORT",
                    field: "/settings",
                }),
                width: 100,
                editable: (params: EditableCallbackParams) => !params.data.is_legacy && !params.data.is_pdf,
                headerName: "",
                cellClassRules: {
                    readonly: (params: CellClassParams) => params.data.is_legacy || params.data.is_pdf,
                },
                cellRendererParams: {
                    flow: "MODIFY_CSV_EXPORT",
                    omitFromExport: true,
                    disabled: (params: EditableCallbackParams) => params.data.is_legacy || params.data.is_pdf,
                },
            },
            {
                ...buttonColDefCreator({ width: 150 }),
                cellRenderer: ManageSchemaAccessButton("field-forms"),
                headerName: "",
                cellRendererParams: {
                    disabled: (params: EditableCallbackParams) => !params.data.is_legacy && !params.data.is_pdf,
                },
                cellClassRules: {
                    readonly: (params: CellClassParams) => params.data.is_legacy || params.data.is_pdf,
                },
            },
        ],
        gridSettings: {
            rowHeight: 40,
            defaultColDef: {
                editable: false,
            },
            rowSelection: "multiple",
            // allow our click events to happen
            suppressRowClickSelection: true,
        },
        otherSettings: {
            pageTitle: "Manage Exports",
            rowLevelErrorDisplay: true,
            buttons: {
                row: [
                    {
                        label: "Export",
                        icon: "export",
                        action: "exportTK",
                    },
                    {
                        label: "Preview",
                        icon: "view",
                        action: "export",
                    },
                    {
                        label: "Duplicate",
                        icon: "copy",
                        action: "duplicateRow",
                        disabled: (context: tContext) => context.selectedRows.length > 1,
                        tooltip: (context: tContext) =>
                            context.selectedRows.length > 1 && "You may only duplicate one row at a time",
                    },
                    {
                        label: "Delete",
                        icon: "delete",
                        action: "deleteSelectedRows",
                    },
                ],
                table: [
                    {
                        label: "New Export",
                        icon: "add",
                        action: "openCsvExportForm",
                    },
                    {
                        separator: true,
                        label: "separator",
                    },
                    {
                        label: "Creator",
                        icon: "addCollaborator",
                        args: {
                            extraArgs: {
                                /**
                                 * A callback to populate the dropdown options, since they're coming from
                                 * the data in the table itself.
                                 */
                                optionsPopulateCb: (ctx: tContext, sourceData: tSourceData) => {
                                    // Make a set of the employee ids that created the export formats
                                    return Array.from(
                                        new Set(
                                            (sourceData["tkExportFormats"] || [])
                                                .filter(r => r.created_by)
                                                .map((row: tResourceObject) => row.created_by)
                                        )
                                    ).map((eId: number) => {
                                        const emp = ctx.referenceableData.employees[eId]
                                        if (emp) {
                                            return {
                                                id: emp.id,
                                                label: `${emp.last_name}, ${emp.first_name}`,
                                            }
                                        }
                                    })
                                },
                                field: "created_by",
                            },
                        },
                        sourceDataFilterButton: true,
                    },
                    {
                        label: "View History",
                        icon: "history",
                        action: "history",
                    },
                ],
            },
        },
    }
}
