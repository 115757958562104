import React from "react"
import { navCategories } from "../../common/constants"
import { CustomDashboard, ReportsPageContainer } from "../../router/lazy-components"
import { CollapsibleFolderMenu } from "@rhumbix/rmbx_design_system_web"
import { getFlagEnabled } from "../../getFlagValue"
import { slugify } from "../../common/ts-utils"
import { JsonPointer as jsonpointer } from "json-ptr"
import structuredClone from "@ungap/structured-clone"

const MenuOptions = props => {
    return (
        props.options?.map(option => {
            const { navCondition, navCategory, settings, listView } = option
            if (typeof navCondition === "function" && !option.navCondition(props)) {
                return null
            }
            const styling = props.isSidebarCollapsed ? "mini-sidebar-custom-list-item" : ""
            if (settings === "equipmentTracking" && navCategory === "beta-dashboards") {
                // Prevent equipment-tracking from being added to the Beta dashboard
                return
            }
            const matchingNestedPaths = listView?.use_nav_folder_paths
                ? props?.nestedListViewPaths?.filter(path => path.startsWith(listView?.title))
                : []
            if (
                listView?.use_nav_folder_paths &&
                matchingNestedPaths?.length &&
                getFlagEnabled("WA-7882-nested-left-nav")
            ) {
                const existingListViews = {}
                props.listViews?.forEach(listView => {
                    // we are only concerned with list views we don't show in the left nav already
                    if (!listView?.show_nav_link && listView.title) {
                        existingListViews[listView.title] = listView
                    }
                })
                const baseRoute = {
                    path: `/rhumbix/field-forms/list-view/${slugify(listView.title)}/`,
                    authRequired: true,
                    wrapper: ReportsPageContainer,
                    component: CustomDashboard,
                    listView: structuredClone(listView),
                    navTitle: listView.title,
                    navCategory: navCategories.fieldForms,
                }

                const nestedListViews = {}

                matchingNestedPaths.forEach(folderPath => {
                    const folders = folderPath.split("/").slice(1)
                    const leafTitle = folders[folders.length - 1]
                    const pathSegments = folderPath.split("/")
                    // create options without schema names for the non-leaf folders so we can filter at folder level
                    folders.forEach((__, index) => {
                        if (index === 0) return
                        const newPath = pathSegments.slice(0, index + 1).join("/")
                        if (!newPath) return
                        const nestedListView = existingListViews?.[newPath] ?? structuredClone(listView)
                        if (listView.related_list_view && !nestedListView.related_list_view) {
                            // if the parent list view has a bundle list view, bring that down to the child
                            // only if the child doesn't have one of its own
                            nestedListView.related_list_view = listView.related_list_view
                        }
                        if (!jsonpointer.get(nestedListViews, `/${newPath}`)) {
                            jsonpointer.set(
                                nestedListViews,
                                `/${newPath}`,
                                {
                                    path: `${baseRoute?.path}${slugify(
                                        pathSegments.slice(1, index + 1).join("/")
                                    )}`,
                                    navTitle: pathSegments[index],
                                    listView: { ...nestedListView, title: newPath, use_nav_folder_paths: true },
                                },
                                true
                            )
                        }
                    })
                    // now set the leaf node list view
                    const leafListView = existingListViews?.[folderPath] ?? structuredClone(listView)
                    if (!jsonpointer.get(nestedListViews, `/${folderPath}`)) {
                        jsonpointer.set(
                            nestedListViews,
                            `/${folderPath}`,
                            {
                                ...baseRoute,
                                path: `${baseRoute?.path}${slugify(folders.join("/"))}`,
                                listView: {
                                    ...leafListView,
                                    schema_name: null,
                                    use_nav_folder_paths: false, // tells CollapsibleFolderMenu it's a leaf
                                    title: folderPath,
                                },
                                navTitle: leafTitle,
                            },
                            true
                        )
                    }
                })
                // nestedListViews should have all the data needed to build out the menu
                return (
                    <CollapsibleFolderMenu
                        key={option.listView.title}
                        {...props}
                        route={baseRoute}
                        isExpanded={props?.menuGroupsExpandedState[option.listView.title]}
                        isMini={props.isSidebarCollapsed}
                        isRootMenu={true}
                        menuTitle={option.listView.title}
                        nestedListViews={nestedListViews}
                        renderMenuItemLink={props.renderMenuItemLink}
                    />
                )
            }
            return (
                <li className={styling} key={JSON.stringify(option.path)}>
                    {props.renderMenuItemLink(option)}
                </li>
            )
        }) ?? <></>
    )
}

export default MenuOptions
