import { ElementType } from "react"
import {
    tEntitySelection,
    tCachedResourceName,
    iCompanyCrewType,
    iEmployee,
    iCompanyGroup,
    tProject,
} from "../cached-data/types"
import { iCurrentUser } from "../common/types"

export interface iMutableFilterState {
    analyticsDashboardId?: number | Array<number>
    cohortId?: number | Array<number>
    companyAbsenceTypesId?: number | Array<number>
    companyGroupId?: number
    companyCrewTypesId?: number | Array<number>
    companyStartStopTypeId?: number | Array<number>
    companyId?: number | Array<number>
    costCodeId?: number | Array<number>
    costCodeFilter?: string
    costItemId?: number | Array<number>
    changeOrderId?: number | Array<number>
    employeeClassification?: string | Array<string>
    employeeId?: number | Array<number>
    employeeRole?: string
    employeeStatus?: string
    employeeTrade?: string | Array<string>
    employeeLicense?: string | Array<string>
    employeeCertification?: string | Array<string>
    employeeUnion?: string | Array<string>
    endDate?: Date
    equipmentId?: number | Array<number>
    foremanId?: number | Array<number>
    groupId?: number | Array<number>
    includeDeleted?: boolean | null
    includeHidden?: boolean | null
    materialId?: number | Array<number>
    parentId?: number | Array<number>
    projectId?: number | Array<number>
    projectShareId?: number | Array<number>
    projectStatus?: string
    schemaName?: string
    schemaStatusName?: string | Array<string>
    startDate?: Date
    tkEntryStatus?: string | Array<string>
    is_active?: boolean
    companyTrade?: string | Array<string>
    companyClassification?: string | Array<string>
    timeCardOwnerId?: number
    workShiftId?: number
    timekeepingStatuses?: string | Array<string>
}

export type tFilterState = Readonly<iMutableFilterState>

export interface iMutableSavedFilterState {
    savedFilterSets?: Array<any>
    activeSavedFilter?: any
    error?: string
}

export type tSavedFilterSetState = Readonly<iMutableSavedFilterState>

export type tSavedFilter = {
    id?: number
    name: string
    page: string
    filter_selections: { [key: string]: any }
    employee: number
    // TODO: Maybe also add the invalidSelections property here
}

// TODO: This is what tSavedFilter["filter_selections"] looks like when viewing Saved Filter Sets
// in the Redux store. Unfortunately, tSavedFilter["filter_selections"] sometimes gets assigned
// data in a different format, so we can't use this type without reducing TS to errors.
export type SavedFilterSetSelections = {
    companyCrewTypesId: {
        [id: number]: iCompanyCrewType
    }
    employeeClassification: {
        [name: string]: { name: string }
    }
    employeeId: {
        [id: number]: iEmployee
    }
    employeeTrade: {
        [name: string]: { name: string }
    }
    groupId: {
        [id: number]: iCompanyGroup
    }
    projectId: {
        [id: number]: tProject
    }
    projectStatus: {
        [name: string]: { name: string }
    }
    timeCardOwnerId: {
        [id: number]: iEmployee
    }
}

// Internal API V4 format for common filter params that get packaged up as query params.
export type CommonFilterParamsV4 = {
    shift_start_time_0: string
    shift_start_time_1: string
    group_id?: number[]
    project_id?: number[]
    employee_id?: number[]
    cohorts?: number[]
    statuses?: string[]
}

export type tInvalidFilterSelections = {
    invalidFilterSelections: Array<any>
}

export type tFilterKey = keyof tFilterState

export type tFilterResourceName =
    | "cohorts"
    | "companyAbsenceTypes"
    | "companyGroups"
    | "companyStartStopTypes"
    | "costCodes"
    | "costItems"
    | "changeOrders"
    | "employees"
    | "employeeTrades"
    | "employeeClassifications"
    | "employeeCertifications"
    | "employeeLicenses"
    | "employeeUnions"
    | "equipment"
    | "projects"
    | "materials"
    | "projectMaterials"
    | "companies"
    | "schemaStatusNames"
    | "projectShares"
    | "analyticsDashboards"
    | "companyCrewTypes"
    | "companyTrades"
    | "companyClassifications"
    | "timekeepingStatuses"
    | "tkExportFormats"
    | "workShifts"
    | "companyFormStores"
    | "companyTextFieldOptions"
    | "picklistItems"

export type tFilterKeyToQueryParam = { [key in tFilterKey]?: string }

export type tRelatedFilter = {
    filterKey: tFilterKey
    queryParam: string
}

export type tExtraFilter = {
    field: string
    queryParam: string
    resourceName?: tFilterResourceName
}

export type tRelatedFilterInfo = {
    defaultFilterKey: tFilterKey
    filterKeys?: Array<tFilterKey>
    queryParam: string
    relatedFilters: Array<tRelatedFilter>
    extraFilters?: Array<tExtraFilter>
    defaultLabel: string
}

export type tResourceToRelatedFilter = { [key in tFilterResourceName]: tRelatedFilterInfo }

export type tValueFormatter = { (args: any): string }

export enum eDatePickerType {
    CUSTOM = "CUSTOM",
    DAILY = "DAILY",
    WEEKLY = "WEEKLY",
}

export type tFilterDef = {
    clearable?: boolean
    clearedBy?: tFilterKey[]
    component: ElementType
    datePickerType?: eDatePickerType
    key: tFilterKey
    isDesignSystem?: boolean
    isSelectorV3?: boolean
    label?: string
    multiselect?: boolean
    options?: Array<any>
    parameterName: string
    resourceName?: tFilterResourceName
    queryParam?: string // For things like trades/classifications where it's a referenceable but not an entity
    secondaryKey?: tFilterKey
    secondaryParameterName?: string
    type?: string
    field?: string
    required?: boolean
    filterStateStringGetter?: (params: tFilterToStringParams) => string
    valueKey?: string
    defaultGetter?: {
        (filterState: tFilterState, filterDef: tFilterDef, context: tFilterContext): tFilterState
    }
    ignoreRelatedFilters?: Array<string>
    extraSearchFilters?: {
        [key: string]: string
    }
}

export type tErrorFilterDef = {
    key: string
    component: ElementType
}

export type tUpdatedFilterValue = {
    filterKey: tFilterKey
    filterValue: any
    filterValueChanged: boolean
}

export type tReferenceableToRelatedFilters = Map<tFilterResourceName, tRelatedFilterInfo>

export interface iSetMultipleFilterValuesAction {
    type: "SET_MULTIPLE_FILTER_VALUES"
    payload: {
        error: null
        filtersToAdd: iMutableFilterState
    }
}

export interface iSetPendingFilterToStateAction {
    type: "SET_PENDING_FILTERS_TO_STATE"
    payload: {
        error: null
        filtersToAdd: iMutableFilterState
        currentUser: Record<string, any>
    }
}

export interface iSetStateToPendingFiltersAction {
    type: "SET_STATE_TO_PENDING_FILTERS"
    payload: {
        error: null
        filtersToAdd: iMutableFilterState
    }
}

export interface iCreateSavedFilterAction {
    type: "CREATE_SAVED_FILTER_ACTION"
    payload: {
        error: null
    }
}

export interface iFetchSavedFilterSetsSuccessful {
    type: "FETCH_SAVED_FILTER_SET_SUCCESSFUL"
    payload: Array<tSavedFilter>
}

export interface iFetchSavedFilterSetsFailed {
    type: "FETCH_SAVED_FILTER_SET_FAILED"
    payload?: {
        error: string | undefined
    }
}

export interface iUpdateSavedFilterSetAction {
    type: "UPDATE_SAVED_FILTER_SET"
    payload: {
        error: null
    }
}

export interface iUpdateSavedFilterSetSuccessful {
    type: "UPDATE_SAVED_FILTER_SET_SUCCESSFUL"
    payload?: {
        error?: undefined
    }
}

export interface iUpdateSavedFilterSetFailed {
    type: "UPDATE_SAVED_FILTER_SET_FAILED"
    payload?: {
        error: string | undefined
    }
}

export interface iDeleteSavedFilterSetAction {
    type: "DELETE_SAVED_FILTER_SET"
    payload: {
        error: null
    }
}

export interface iDeleteSavedFilterSetSuccessful {
    type: "DELETE_SAVED_FILTER_SET_SUCCESSFUL"
    payload?: {
        error?: undefined
    }
}

export interface iDeleteSavedFilterSetFailed {
    type: "DELETE_SAVED_FILTER_SET_FAILED"
    payload?: {
        error: string | undefined
    }
}

export interface iSetSavedFilterSetAction {
    type: "SET_SAVED_FILTER_SET"
    payload: tSavedFilter | undefined
}

export interface iApplySavedFilterSetSucceeded {
    type: "APPLY_SAVED_FILTER_SET_SUCCEEDED"
    payload?: {
        error?: undefined
    }
}

export interface iApplySavedFilterSetFailed {
    type: "APPLY_SAVED_FILTER_SET_FAILED"
    payload?: {
        error?: string
    }
}

export interface iSetInvalidSelectionsForSavedFilterSetAction {
    type: "SET_INVALID_SELECTIONS_FOR_SAVED_FILTER_SET"
    payload: {
        invalidSelections?: { [key: string]: tInvalidFilterSelections }
        invalidFilters?: tFilterKey[]
    }
}

export interface iClearInvalidSelectionsForSavedFilterSet {
    type: "CLEAR_INVALID_SELECTIONS_FOR_SAVED_FILTER_SET"
    payload: any
}

export interface iLogout {
    type: "LOGOUT"
}

export interface iUpdateCurrentUser {
    type: "UPDATE_CURRENT_USER"
    data: iCurrentUser
}

export interface iFilterDefParams {
    clearedBy?: tFilterKey[]
    datePickerType?: eDatePickerType
    defaultGetter?: any
    field?: string
    filterStateStringGetter?: (params: tFilterToStringParams) => string
    isDesignSystem?: boolean
    isSelectorV3?: boolean
    isStartDateOnly?: boolean
    key?: tFilterKey
    label?: string
    multiselect?: boolean
    options?: Array<any>
    parameterName?: string
    partialMatch?: boolean
    queryParam?: string
    required?: boolean
    resourceName?: tFilterResourceName
    type?: string
    value?: string
    ignoreRelatedFilters?: Array<string>
    extraSearchFilters?: {
        [key: string]: string
    }
    extraFilterQueryParams?: Array<string>
    valueKey?: string
}

export interface iFilterDefCreator {
    (params: iFilterDefParams): tFilterDef | tErrorFilterDef
}

export type tFilterDefType = "date" | "enum" | "referenceable-selector" | "string" | "boolean"

export type tStandardFilterDefCreator = { [key in tFilterDefType]: iFilterDefCreator }

export type tFilterAction =
    | iSetMultipleFilterValuesAction
    | iSetPendingFilterToStateAction
    | iSetStateToPendingFiltersAction

export type tSavedFilterAction =
    | iCreateSavedFilterAction
    | iFetchSavedFilterSetsSuccessful
    | iFetchSavedFilterSetsFailed
    | iUpdateSavedFilterSetAction
    | iUpdateSavedFilterSetSuccessful
    | iUpdateSavedFilterSetFailed
    | iDeleteSavedFilterSetAction
    | iDeleteSavedFilterSetSuccessful
    | iDeleteSavedFilterSetFailed
    | iApplySavedFilterSetSucceeded
    | iApplySavedFilterSetFailed
    | iSetSavedFilterSetAction
    | iSetInvalidSelectionsForSavedFilterSetAction
    | iClearInvalidSelectionsForSavedFilterSet

export type tFilterActionCallbacks = { (arg: tFilterState, storeFilters?: boolean): void }

export type tFilterValues = number | string | Array<number> | Array<string> | Date | undefined | null | boolean

export type tFilterToStringParams = {
    primaryValue: tFilterValues
    secondaryValue?: tFilterValues
    resourceName?: tCachedResourceName
    context?: tFilterContext
    enumLabel?: string
}

export interface iInitialFiltersAppliedAction {
    type: "SET_INITIAL_FILTERS_APPLIED"
    payload: {
        initialized: boolean
    }
}

export type tStartOfWeekIndex = 0 | 1 | 2 | 3 | 4 | 5 | 6

export type tFilterContext = {
    referenceableData: tEntitySelection
    startOfTheWeekIndex: tStartOfWeekIndex
}
