import { getSharePreviousCollaborators } from "../../api/get"
import { VIEW_ONLY, REQUEST_SIGNATURE } from "../../common/constants"

export const DAYS_UNTIL_GUEST_FORM_EXPIRES = 14
export const userRolesWithAdditionalSharePrivileges = ["ADMIN", "PAYROLL_ADMIN", "PM", "FOREMAN"]

/**
 * Helper function that converts the format of a guest permissions received from the server (e.g. "VIEW_ONLY")
 * to a cleanly formatted display string (e.g. "View Only")
 */
export const getDisplayValueForPermissions = (permissions: string) => {
    switch (permissions) {
        case VIEW_ONLY:
            return "View Only"
        case REQUEST_SIGNATURE:
            return "Request Signature"
        default:
            return ""
    }
}

/**
 * Generates the default e-mail body that is sent when a guest user is invited to share a form. Supports
 * language for sharing a single form or multiple forms
 */
export const getDefaultEmailMessage = (
    currentUserFullName: string,
    companyName: string,
    formType: string,
    selectedForms: any,
    projectName: string
) =>
    `
    <p>Hello,</p>
    <p>${currentUserFullName}${
        selectedForms.length === 1
            ? ` at ${companyName} has sent you ${formType} form #${selectedForms[0]} \
                for the project ${projectName}.`
            : " wants to collaborate with you on multiple forms."
    }</p>
    <p>${
        selectedForms.length === 1
            ? "Please use the link above to start collaborating. Please note, the link will expire " +
              `${DAYS_UNTIL_GUEST_FORM_EXPIRES} days after being sent and you will lose access when the ` +
              `link expires. To request a new link, contact ${currentUserFullName}.`
            : "Please use the links above to start collaborating. Please note, the links will expire " +
              `${DAYS_UNTIL_GUEST_FORM_EXPIRES} days after being sent and you will lose access when the ` +
              `links expire. To request new links, contact ${currentUserFullName}.`
    }</p>
    `

/**
 * Fetch the previous collaborators who have worked on the specified projects and schemas,
 * typically to populate the Share Modal with possible recipients
 */
export const fetchPreviousCollaborators = async (projects: number[], schemas: number[]) => {
    const formattedPreviousCollaborators: any[] = []

    const previousCollaborators = await getSharePreviousCollaborators({
        projects,
        schemas,
    })

    // For each unique email address, extract the data returned and format it to what is expected by the share modal
    // We may want to revisit this to be more selective of which record to keep in the case of
    // repeated e-mails - for example, maybe we want to keep the one with the most restrictive permissions
    previousCollaborators.results.forEach((ea: any) => {
        if (!formattedPreviousCollaborators.find(x => x.email_address === ea.guest.email) && !ea.guest.deleted_on) {
            formattedPreviousCollaborators.push({
                email_address: ea.guest.email,
                id: ea.guest.id,
                permissions: ea.permissions,
                variant: ea.variant,
            })
        }
    })

    return formattedPreviousCollaborators
}
