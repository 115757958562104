import { JsonPointer as jsonpointer } from "json-ptr"
import numeral from "numeral"
import { format } from "date-fns"
/** Utils */
import { calculateQuantitiesPerHour } from "./ag-grid-utils"
import { getAsDate } from "./ts-utils"
import { isNumber } from "./validators"
/** Const */
import { DATETIME_MM_DD_YYYY_12H, EMPLOYEE_PROJECT_SPECIFIC_ASSIGNMENTS } from "./constants"
import { getFlagEnabled } from "../getFlagValue"

export const totalHoursValueGetter = params => {
    if (!params.data) return ""

    let total = 0
    for (const key of ["adjusted_minutes_st", "adjusted_minutes_ot", "adjusted_minutes_dt"]) {
        const value = params.data[key]
        if (value) {
            total += value
        }
    }
    return !isNaN(total) ? toTwoDecimalFloatFromMinutes(total) : "-"
}

export const unitManHourValueGetter = params => params.data["units"] + " / mh"

export const budgetUnitRateValueGetter = params =>
    calculateQuantitiesPerHour(params.data["budget_quantities"], params.data["budget_hours"])

export const avgUnitRateValueGetter = params =>
    calculateQuantitiesPerHour(params.data["total_quantities"], params.data["total_hours"])

export const percentWeeklyUnitRateChangeValueGetter = params => {
    const thisWeek = calculateQuantitiesPerHour(params.data["week_quantities"][0], params.data["week_hours"][0])
    const lastWeek = calculateQuantitiesPerHour(params.data["week_quantities"][1], params.data["week_hours"][1])

    return isNaN(thisWeek) || isNaN(lastWeek) ? "N/A" : (thisWeek / lastWeek) * 100 - 100
}

export const weekUnitRateValueGetter = (params, week_index) =>
    calculateQuantitiesPerHour(params.data["week_quantities"][week_index], params.data["week_hours"][week_index])

export const entireRowValueGetter = params => params.data

export const toGoUnitRateValueGetter = params => {
    const hours = params.data["budget_hours"] - params.data["total_hours"]
    const quantities = params.data["budget_quantities"] - params.data["total_quantities"]

    return calculateQuantitiesPerHour(quantities, hours)
}

export const jsonPointerValueGetter = ({
    data,
    colDef: { field = undefined, referenceableMap = undefined },
    context: {
        settings: { otherSettings: { requestSpecificFields = undefined } = {} } = {},
        referenceableData = undefined,
    } = {},
}) => {
    if (requestSpecificFields && data && field in data) {
        return data[field]
    }

    if (referenceableMap && data) {
        let key = data[referenceableMap["rowKey"]]
        if (
            getFlagEnabled("WA-8672-group-details-ff-list-view") &&
            !key &&
            referenceableMap["rowKey"].startsWith("/")
        )
            key = jsonpointer.has(data, referenceableMap["rowKey"])
                ? jsonpointer.get(data, referenceableMap["rowKey"])
                : undefined
        if (typeof key === "object") {
            data = key
        } else {
            // In the event of a Guest Form Share, there may or may not be referenceable data
            if (key) {
                data = referenceableData[referenceableMap["referenceableKey"]][key]
            } else {
                return undefined
            }
        }
    }
    return field && jsonpointer.has(data, field) ? jsonpointer.get(data, field) : undefined
}

/**
 * This value getter function is a lot like its little brother up above, but does some weird other things
 * in order to work for mixed source data types which would feed into a pivot table.
 * @param data - The row data
 * @param field - The primary field for this column
 * @param referenceableMap - How to search the source data for its referenceable entity
 * @param altField - A secondary field in case the primary does not exist for this data entry
 * @param returnEntireRef - If there is a referenceable match for this data entry, return that entire referenceable.
 * In the equipment tracking use case, the shift extra form store has the entire serialized entities that are
 * referenced. However, a modifier to a timekeeping entry will only be an ID and the table is expecting the entire
 * object so that it can use value formatters and such on its various fields
 * @param fallbackToField - If the referenceable approach fails, don't return undefined, try to look up the field
 * and altFields. Again, combining mixed source data types makes this ugly
 * @param requestSpecificFields - Not quite sure, but it seems like a shortcut through the more complicated logic
 * @param referenceableData - Map of all the other entities that we have loaded up which are referenced in the
 * source data.
 * @returns {unknown|undefined}
 */
export const jsonPointerValueGetterEnhanced = ({
    data,
    colDef: {
        field = undefined,
        referenceableMap = undefined,
        altField = undefined,
        returnEntireRef = undefined,
        fallbackToField = undefined,
    },
    context: {
        settings: { otherSettings: { requestSpecificFields = undefined } = {} } = {},
        referenceableData = undefined,
    } = {},
}) => {
    if (requestSpecificFields && data && field in data) {
        return data[field]
    }

    if (referenceableMap && data) {
        let key = data[referenceableMap["rowKey"]]
        if (!key && referenceableMap["rowKey"].startsWith("/"))
            key = jsonpointer.has(data, referenceableMap["rowKey"])
                ? jsonpointer.get(data, referenceableMap["rowKey"])
                : undefined
        // In the event of a Guest Form Share, there may or may not be referenceable data
        if (key) {
            const refData = referenceableData[referenceableMap["referenceableKey"]][key]
            if (returnEntireRef) {
                return refData
            }
        } else if (!fallbackToField) {
            return undefined
        }
    }
    const value = field && jsonpointer.has(data, field) ? jsonpointer.get(data, field) : undefined
    if (value === undefined && altField)
        return altField && jsonpointer.has(data, altField) ? jsonpointer.get(data, altField) : undefined
    return value
}

export const referenceableValueGetter = params => {
    const id = jsonPointerValueGetter(params)
    if (isNumber(id)) {
        return params.context.referenceableData[params.colDef.resourceName][id]
    }
    return id
}

export const hoursFromMinutesValueGetter = params => {
    const fieldValue = jsonPointerValueGetter(params)
    if (isNumber(fieldValue)) {
        return toTwoDecimalFloatFromMinutes(fieldValue)
    }
    return fieldValue
}

export const durationSecondsValueGetter = params => {
    const fieldValue = jsonPointerValueGetter(params)
    if (isNumber(fieldValue)) {
        const durationSeconds = Number(fieldValue)

        // format as H:mm
        const hours = Math.floor(durationSeconds / 3600)
        const minutes = Math.floor((durationSeconds % 3600) / 60)

        return `${hours}:${String(minutes).padStart(2, "0")}`
    }
    return fieldValue
}

// TODO: find a more generic way to do this later, maybe label the resource of every object before it gets here?
export const pivotValueValueGetter = params => {
    if (!params.data) {
        return {}
    }
    if ("adjusted_minutes_dt" in params.data) {
        const data = {
            ST: params.data.adjusted_minutes_st / 60,
            OT: params.data.adjusted_minutes_ot / 60,
            DT: params.data.adjusted_minutes_dt / 60,
            signature: params.data.signature !== "",
            status: params.data.status,
            workshiftId: params.data.work_shift_id,
            modifierActive: getFlagEnabled("WA-7200-Custom-Modifiers")
                ? params.data?.modifier_active != null &&
                  Object.values(params.data.modifier_active).some(v => v != null)
                : !!(
                      params.data?.modifier_active?.trade ||
                      params.data?.modifier_active?.classification ||
                      params.data?.modifier_active?.equipment
                  ),
        }
        const project = params.context.referenceableData["projects"][params.data.project]
        data.cicoTime =
            project && project.cico_settings.enabled ? (data.ST || 0) + (data.OT || 0) + (data.DT || 0) : 0
        return data
    } else if ("schema" in params.data) {
        const { schema } = params.context.referenceableData.employeeSchemas[params.data.schema]
        return {
            shiftExtra: Object.assign({}, params.data.store, {
                title: schema ? schema.title : "Unknown",
                description: schema ? schema.description : "This schema has been deleted",
                project_job_number: params.data.project_job_number,
            }),
            status: params.data.status,
            workshiftId: params.data.work_shift_id,
        }
    } else if ("type" in params.data) {
        return {
            absenceReason: params.data.type,
            status: params.data.status,
            workshiftId: params.data.work_shift_id,
        }
    } else if ("units" in params.data) {
        return {
            quantityId: params.data.id,
            quantityGridId: params.data.gridId,
            quantityValue: params.data.value,
        }
    } else if ("start_stop_type" in params.data && !("entry_type" in params.data)) {
        return {
            startStopTypes: params.data.start_stop_type,
            status: params.data.status,
        }
    } else if ("entry_type" in params.data) {
        const d = {
            clocked_in_duration: params.data.clocked_in_duration || 0,
            meal_duration: params.data.meal_duration || 0,
            break_duration: params.data.break_duration || 0,
            workshiftId: params.data.work_shift_id.id,
            status: params.data.status,
            version_count: params.data.version_count,
            item_count: 1,
        }
        if (params.data.entry_type === "CLOCK_IN") d.shift_count = 1
        return d
    } else {
        return {}
    }
}

export const timestampValueGetter = params => {
    const date = jsonPointerValueGetter(params)
    if (date) {
        return format(getAsDate(date), DATETIME_MM_DD_YYYY_12H)
    }
    return ""
}

/**
 * Generic value getter for currencies that divide the value stored by 100. Values are stored as their smallest unit
 * So $1.00 would be stored as 100. So to get 1, you need to divide by 100.
 * @param {*} params
 * @returns the field value divided by 100
 */
export const currencyValueGetter = params => {
    let fieldValue = jsonPointerValueGetter(params)
    // list views pass value back as a string
    if (typeof fieldValue === "string" && !isNaN(fieldValue)) {
        fieldValue = Number(fieldValue)
    }
    if (isNumber(fieldValue)) {
        return fieldValue / 100
    }
    return fieldValue
}

export const JPYValueGetter = params => {
    // Yen are the smallest unit, so we don't divide by 100 like in the other currencies
    let fieldValue = jsonPointerValueGetter(params)
    // list views pass value back as a string
    if (typeof fieldValue === "string" && !isNaN(fieldValue)) {
        fieldValue = Number(fieldValue)
    }
    return fieldValue
}

export const USDSumValueGetter = params => {
    let value = jsonPointerValueGetter(params)

    // Sometimes the value that gets returned is a string, other times it's json. Attempt to convert
    if (typeof value == "string") {
        value = JSON.parse(value)
    }
    if (Array.isArray(value) && params.colDef.subfield) {
        const fieldValue = value.reduce((total, item) => total + item[params.colDef.subfield], 0)
        return isNumber(fieldValue) ? fieldValue / 100 : fieldValue
    }
    return jsonPointerValueGetter(params)
}

export const rowCountValueGetter = params => {
    let value = jsonPointerValueGetter(params)

    // Sometimes the value that gets returned is a string, other times it's json. Attempt to convert
    if (typeof value == "string") {
        value = JSON.parse(value)
    }
    return Array.isArray(value) ? value.length : value
}

const toTwoDecimalFloatFromMinutes = v => parseFloat(numeral(v / 60).format("0.[00]"))

// Given an array of items, grabs the contents of the specified field on each item and
// joins them into a string. For example, given an array of Cost Code Controls which each have a name field,
// the function returns a string containing the names of all of the Cost Code Controls, separated
// by the provided separator
export const arrayContentsValueGetter = (items, valueFieldName, separator) => {
    let description = ""
    if (items && items.length > 0) {
        const itemDescriptors = items.map(item => item[valueFieldName])
        description = itemDescriptors.join(separator)
    }
    return description
}

// A wrapper for arrayContentsValueGetter that accesses items in a column that's using a
// referenceableMap
export const arrayOfReferenceablesValueGetter = (params, valueFieldName, separator) => {
    const items = jsonPointerValueGetter(params)

    return arrayContentsValueGetter(items, valueFieldName, separator)
}

// Produces values for the Link Click column on guest form shares, including the count of
// link clicks and also an indicator if the link has expired
export const guestFormSharesLinkClickValueGetter = params => {
    let linkClickColValue = ""
    if (params.data) {
        if (params.data.view_count !== undefined) {
            linkClickColValue += params.data.view_count === 0 ? "None" : params.data.view_count
        }

        if (params.data.access_expires_on && new Date(params.data.access_expires_on) < new Date()) {
            linkClickColValue += " (expired)"
        }
    }

    return linkClickColValue
}

// creates a string formatted like so, api token and company key are displayed
// and the api token is masked
export const apiIntegrationTokenValueGetter = params => {
    const { company_integration_name, company_integration_token_value } = params.data || {}
    // mask the token value for display only
    const tokenValue = params.data ? company_integration_token_value.replace(/.(?=.{7,}$)/g, "*") : ""
    return params.data ? `company_key = ${company_integration_name}, api_key = ${tokenValue}` : ""
}

// For non-GLO customers. Take the values of the groups field and translates it to the enum
// options for the column ("Main" - which is the Main group, and which is formatted as "Main (all projects)"
// - or "Project Specific", which means there are no groups)
export const employeeMainGroupAvailableValueGetter = params => {
    const item = jsonPointerValueGetter(params)

    if (item && item.level === 0) return [item.name]
    return [EMPLOYEE_PROJECT_SPECIFIC_ASSIGNMENTS]
}
