import Rmbx from "../../util"
import { addSourceDataRow, bulkUpdateAndHandleResponse } from "../../dashboard-data/actions"
import {
    getGroupingChangeHandlers,
    getTimekeepingStatusChangeHandlers,
    processCellCallback,
    processHeaderCallback,
} from "./utils"
import { iRmbxColDef } from "../../components/custom-dashboards/settings-files/types"
import { tButtonClickHandler, tButtonClickHandlerFactory } from "../types"
import { performDataRefresh } from "../../actions/server-side-row-model"
import { IconAddCollaborator } from "@rhumbix/rmbx_design_system_web"
import { toggleFancySearchVisible } from "../../actions/search-bar"
import { Column, ColumnApi, GridApi } from "ag-grid-community"
import { getV4Resources } from "../../api"
import {
    openAddCohortEmployeesModal,
    openAddRowsToWeeklyTKModal,
    openAddEditWorkShiftModal,
} from "../../components/modals/actions"
import { bulkAddSourceData } from "../../dashboard-data/actions/write"
import {
    logUserAmplitudeEvent,
    WEEKLY_TIMEKEEPING_MODAL_ADD_COST_CODES_OPENED,
    WEEKLY_TIMEKEEPING_MODAL_ADD_EMPLOYEES_OPENED,
} from "../../common/amplitude-event-logging"
import { togglePlaceholdersVisible } from "../../actions"
import { toggleSearchBarState } from "../../actions"
import { getFlagEnabled } from "../../getFlagValue"
import { getWorkShifts } from "../../common/ag-grid-utils"
import { isNumber } from "../../common/validators"
import { tResourceObject } from "../../dashboard-data/types"
import { tResourceName } from "../../common/types"
import { updateSortOrder } from "../../api/patch"
import { getObjectId } from "../../common/ag-grid-ts-utils"

export const addNewRow: tButtonClickHandler = (e, { columnApi, context, gridApi }) => {
    const cApi = columnApi as ColumnApi
    if (context.updateSourceDataCb)
        bulkAddSourceData(
            context.settings.resources[0],
            cApi.getAllColumns()?.map(column => column.getColDef()) as iRmbxColDef[],
            [{}],
            context.settings.otherSettings.rowLevelValidators,
            context.settings.otherSettings.hiddenColumnDefaults,
            context.groupKeyInfo,
            context.filters,
            context.updateSourceDataCb,
            context
        )
    else {
        context.dispatch(
            addSourceDataRow(
                context.settings.resources[0],
                cApi.getAllColumns()?.map(column => column.getColDef()) as iRmbxColDef[],
                context.settings.otherSettings.rowLevelValidators,
                context.settings.otherSettings.hiddenColumnDefaults,
                context.groupKeyInfo,
                context.filters,
                context
            )
        )
    }
    // If this is a user-sorted table and we've added a row, let's update the sorting on everything else.
    if (context.settings.otherSettings?.rowDragField) {
        const nodeData: tResourceObject[] = []
        gridApi.forEachNode(n => {
            if (n.id)
                nodeData.push({
                    ...n.data,
                    [context.settings.otherSettings?.rowDragField]: n.rowIndex,
                    modified: true,
                })
        })
        context.dispatch(
            bulkUpdateAndHandleResponse(
                updateSortOrder,
                context.settings.resources[0],
                nodeData,
                undefined,
                undefined,
                e => e.id,
                context.settings.otherSettings?.rowDragField
            )
        )
    }
    if (context.settings.gridSettings?.rowModelType === "serverSide") context.dispatch(performDataRefresh(true))
}

// Opens a modal where the user selects a set of workers to add to a table, specifically
//  the Timekeeping Entries tab on the Weekly Timekeeping modal
export const addEmployeeRows: tButtonClickHandler = (e, { columnApi, context }) => {
    logUserAmplitudeEvent(WEEKLY_TIMEKEEPING_MODAL_ADD_EMPLOYEES_OPENED, {})
    const colDefs = (columnApi.getAllColumns() || []).map(column => column.getColDef())

    context.dispatch(openAddRowsToWeeklyTKModal("employees", "employee", context, colDefs))
}

// Opens a modal where the user selects a set of cost codes to add to a table, specifically
// the Timekeeping Entries tab on the Weekly Timekeeping modal
export const addCostCodeRows: tButtonClickHandler = (e, { columnApi, context }) => {
    logUserAmplitudeEvent(WEEKLY_TIMEKEEPING_MODAL_ADD_COST_CODES_OPENED, {})
    const colDefs = (columnApi.getAllColumns() || []).map((column: Column) => column.getColDef())

    context.dispatch(openAddRowsToWeeklyTKModal("costCodes", "cost code", context, colDefs))
}

// Opens a modal where the user selects a set of employees to add to a cohort
export const addCohortEmployeeRows: tButtonClickHandler = (e, { columnApi, context, args }) => {
    const colDefs = (columnApi.getAllColumns() || []).map(column => column.getColDef())
    context.dispatch(openAddCohortEmployeesModal(args?.value, context, colDefs))
}

export const openFieldFormCreate: tButtonClickHandler = (e, params) => {
    const { context } = params
    // Opens a New Field Form in the right rail -
    const projectIds = Array.isArray(context.filters.projectId)
        ? context.filters.projectId
        : [context.filters.projectId]

    // Due to some particularities in the ListView setup with regard to Bundling, we need to check for the
    // "schema_names" attribute and pick the first one
    const schemaName = context.settings.additionalQueryParams.schema_name
        ? context.settings.additionalQueryParams.schema_name
        : context.settings.additionalQueryParams.schema_names
        ? context.settings.additionalQueryParams.schema_names[0]
        : ""

    const folderPath = context.settings.useNavFolderPaths ? context.settings.listViewTitle : null
    if (projectIds.length === 1) {
        context.sideRailContext.enableSideRail({
            flow: "FIELD_FORMS",
            folderPath,
            isNew: true,
            schemaName,
            projectId: projectIds[0],
            mode: "Create",
            extraBtnParams: getFlagEnabled("WA-7649-web-transforms") ? params : undefined,
            listViewFields: context.settings.otherSettings.requestSpecificFields,
            listViewContext: context.settings,
            gridId: context.selectedRows[0]?.gridId,
            enableSideRail: context.sideRailContext.enableSideRail,
        })
    }
}

export const getGroupingChangeHandler: tButtonClickHandlerFactory = params => {
    const { currentGrouping } = params
    return [[`${currentGrouping} ▾`, getGroupingChangeHandlers(params), "group"]]
}

export const getExportHandlers: tButtonClickHandlerFactory = params => {
    /**
     * - Export every visible column that has a header name (which will exclude
     * things like checkbox and button columns).
     * - Any columns with the excludeFromExport set to "true" will be excluded from the export
     * - If exportAll is true, include all the columns (except the group column).
     * Note that these are only valid for Client Side Row Model
     */
    const exportAll = params.context.settings.otherSettings?.exportAll
    const gridApi = params.gridApi as GridApi
    const allColumns = params.columnApi.getAllGridColumns() as Column[]
    const columns =
        allColumns?.filter(column => {
            const { groupColumn, headerName, excludeFromExport } = column.getColDef() as iRmbxColDef
            if (!groupColumn) return exportAll || (!!headerName && !excludeFromExport)
        }) ?? []

    const resource = params.context.settings.resources[0]
    const rowModelType = params.context.settings.gridSettings?.rowModelType

    const exportParams = {
        columnKeys: columns,
        processCellCallback,
        processHeaderCallback,
        skipGroups: true, // Don't insert an empty row between groups
    }
    let exportToCsv
    if (rowModelType == "serverSide") {
        const headers = columns.map(column => {
            const { headerName } = column.getColDef() as iRmbxColDef
            return `"${headerName}"`
        })
        exportToCsv = async () => {
            let response = await getV4Resources(resource)
            const resources = response.results

            while (response.next) {
                const nextUrlParams = new URL(response.next).searchParams
                const params = Object.fromEntries(nextUrlParams)
                response = await getV4Resources(resource, params)
                resources.push(...response.results)
            }

            const resourceRows = resources.map((resource: { [x: string]: any }) => {
                return columns.map(column => {
                    const colDef = column.getColDef()
                    const valueFormatter = colDef.valueFormatter as (params: any) => any
                    try {
                        const field = colDef.field as string
                        // remove the / that precedes name
                        const formattedColumnName = field.substring(1)
                        // create into a dictionary in order to use the existing formatters
                        const property = { value: resource[formattedColumnName], data: resource }

                        const parts = formattedColumnName.split("/")
                        const isMultiValueField =
                            parts.length === 1 &&
                            property.value &&
                            ((Array.isArray(property.value) && property.value.length === 0) ||
                                (property.value[0]?.id !== undefined && property.value[0]?.name !== undefined))

                        if (parts.length === 2) {
                            // If the field is like custom_fields/fieldname, we need to access
                            // resource["custom_fields"]["fieldname"] to get the value of the field.
                            // Currently only strings from custom text fields are nested objects like this,
                            // so no need for stringifying or formatting at the moment.
                            return resource[parts[0]][parts[1]]
                        } else if (isMultiValueField) {
                            // Used to properly export array of choices fields without
                            // the extra whitespace added by multiChoiceValueFormatter
                            // and also support escaping values properly so that the exported
                            // CSV is not broken if one of the values contained a double quote
                            const names = property.value.map((item: { name: string }) =>
                                item.name.replace(/"/g, '""').replace(/;/g, "\\;")
                            )
                            return `"${names.join(";")}"`
                        } else if (valueFormatter && resource[formattedColumnName] != undefined) {
                            return `"${valueFormatter(property)}"`
                        } else if (resource[formattedColumnName] != undefined) {
                            return `"${resource[formattedColumnName]}"`
                        } else if (colDef.cellRendererParams.computedField && valueFormatter) {
                            return `"${valueFormatter(property)}"`
                        } else {
                            return '""'
                        }
                    } catch (error) {
                        /* empty */
                    }
                })
            })

            const headerRow = headers.join(",")
            const csvString = headerRow.concat(
                "\n",
                resourceRows.map((resourceRow: any[]) => resourceRow.join(",")).join("\n")
            )
            // create a Blob object from a from the array of resources
            const csvData = new Blob([csvString], { type: "text/csv" })
            // create a DOMString which contains a URL representing the csvData blob object
            const csvUrl = URL.createObjectURL(csvData)
            // create an anchor element which when clicked downloads the csvData as 'export.csv'
            const a = document.createElement("a")
            a.href = csvUrl
            a.target = "_blank"
            a.download = "export.csv"

            document.body.appendChild(a)
            a.click()
        }
        return [["CSV", exportToCsv, "export"]]
    } else {
        exportToCsv = () => gridApi.exportDataAsCsv(exportParams)
        const exportToXls = () => gridApi.exportDataAsExcel(exportParams)

        return [
            ["CSV", exportToCsv, "export"],
            ["Excel", exportToXls, "export"],
        ]
    }
}

export const getRowToggleHandler: tButtonClickHandlerFactory = params => {
    const { gridApi, rowsExpanded, toggleRowExpansion } = params
    return [
        [
            `${rowsExpanded ? "Collapse" : "Expand"} All`,
            () => {
                rowsExpanded ? gridApi.collapseAll() : gridApi.expandAll()
                toggleRowExpansion(!rowsExpanded)
            },
            rowsExpanded ? "collapse" : "expand",
        ],
    ]
}

export const getTableTimekeepingStatusChangeHandlers: tButtonClickHandlerFactory = params =>
    getTimekeepingStatusChangeHandlers(params, false)

export const openAddTimekeepingEntryModal: tButtonClickHandler = (e, { context }) => {
    context.openAddTimekeepingEntryModal()
}

export const openCreateTimeCardModal: tButtonClickHandler = (e, { context }) => {
    context.openCreateTimeCardModal()
}

export const navigateTo: tButtonClickHandler = (e, { args }) => {
    const isNotRelativeUrl = new RegExp("^(?:[a-z]+:)?//", "i")
    const url = args ? args.url || "" : ""

    if (!url.length || isNotRelativeUrl.test(url)) {
        return
    }
    Rmbx.util.history.push(url)
}

export const navigateToExternal: tButtonClickHandler = (e, { args }) => {
    const url = args ? args.url || "" : ""
    const isExternalURL = new URL(url).origin !== location.origin
    if (url.length && isExternalURL) {
        window.open(url, "_blank")
    }
    return
}

export const addProject: tButtonClickHandler = (_e, params) =>
    params.sideRailContext.enableSideRail({ flow: "PROJECT_CREATE" })

export const addEmployee: tButtonClickHandler = (_e, params) =>
    params.sideRailContext.enableSideRail({ flow: "EMPLOYEE_CREATE" })

export const addApiIntegrationToken: tButtonClickHandler = (_e, params) =>
    params.sideRailContext.enableSideRail({ flow: "API_INTEGRATION_TOKEN_CREATE" })
/**
 * Launch the Collaborators view in the Side Rail.
 * @param {tMouseEvent<HTMLButtonElement> | null} e Click event.
 * @param context View context.
 */
export const openSideRailCollaborators: tButtonClickHandler = async (e, { context }) => {
    if (context.sideRailContext.sideRailEnabled) return
    const resource = "guestFormShares"

    // Grab the schema names in order to filter the form permissions
    const schemaNames = context.settings.additionalQueryParams.schema_names

    // This comes from modify production value getters
    context.sideRailContext.enableSideRail({
        flow: "DATA_TABLE",
        config: {
            schemaNames: schemaNames,
            useBasicHeader: true,
            hideApplyButton: true,
            cancelButtonText: "Done",
            headerIcon: IconAddCollaborator(),
        },
        filters: [],
        resource,
        title: `${context.settings.tableName} Collaborators`,
    })
}

export const toggleFancySearchBar: tButtonClickHandler = async (e, { context }) => {
    context.dispatch(toggleFancySearchVisible())
}

export const togglePlaceholders: tButtonClickHandler = async (e, { context }) => {
    context.dispatch(togglePlaceholdersVisible())
}

export const toggleStatefulButton: tButtonClickHandler = async (e, { context, args }) => {
    context.dispatch(toggleSearchBarState(args!.extraArgs!.stateAttribute))
}

export const editTimeCardDetails: tButtonClickHandler = async (e, { context, sourceData }) => {
    const workShiftId = Array.from(getWorkShifts(context, sourceData))[0] as number
    const resourceName = context.settings.resources[0] as tResourceName
    const rowToEdit = Object.values(sourceData[resourceName] as tResourceObject[]).find(e => {
        if (isNumber(e.work_shift_id) && e.work_shift_id == workShiftId) return true
        if (typeof e.work_shift_id === "object" && e.work_shift_id)
            if (e.work_shift_id.id === workShiftId || e.work_shift_id.tempId === workShiftId) return true
        return false
    })
    if (rowToEdit) context.dispatch(openAddEditWorkShiftModal(rowToEdit.work_shift_id, context))
}

export const openCicoDetails: tButtonClickHandler = (e, { context, sourceData }) => {
    // props.data gets stale if we have just created the row, so we need to find
    // the current row data in the state.
    const sourceObject = Object.values(sourceData)[0][0]
    const lockedColumn = context.groupKeyInfo.find(gki => gki.colDef.field === "/employee")
    if (sourceObject?.employee) {
        context.sideRailContext.enableSideRail({
            flow: "VIEW_CICO_DATA",
            lockedColumns: context.groupKeyInfo,
            employee: context.referenceableData.employees[getObjectId(sourceObject?.employee)],
        })
    } else if (lockedColumn) {
        context.sideRailContext.enableSideRail({
            flow: "VIEW_CICO_DATA",
            lockedColumns: context.groupKeyInfo,
            employee: context.referenceableData.employees[getObjectId(lockedColumn.value)],
        })
    }
}

export const openCsvExportForm: tButtonClickHandler = async (e, { context }) => {
    context.sideRailContext.enableSideRail({
        flow: "CREATE_CSV_EXPORT",
    })
}
