import React, { Component } from "react"
import { tUserRole, tCompanyRole } from "../common/types"

export type tRoute = {
    authRequired?: boolean
    component?: React.ComponentType<any>
    navCategory?: string
    navClassName?: string
    navCondition?: (args: any) => boolean
    navIconSrc?: string
    navIconObj?: typeof Component
    navTitle?: string
    path?: string | Array<string>
    settings?: string
    wrapper?: React.ComponentType<any>
    listView?: any
    isCompanyLevel?: boolean
    isErrorPage?: boolean
    exact?: boolean
    url?: string
}

export type tDashboard = {
    id: number
    name: string
    category: string
    url_slug: string
    url?: string
    mobile_url: string
    tablet_url: string
}

export type tMenuItem = {
    settingsKey: tDemoDashboardKey
    path: string
    tableName: string
}

type tDemoDashboardKey =
    | "weeklyTimeCard"
    | "costCodeAccrual"
    | "employees"
    | "projectEmployees"
    | "costCodes"
    | "equipment"
    | "companyEquipment"
    | "companyGroups"
    | "unitRateTracker"
    | "equipmentTracking"
    | "dailyViewBeta"
    | "accountSettings"
    | "projectMaterials"
    | "gcReferenceNumbers"
    | "productionTracking"

export type tNavCategoriesKeys = keyof tMenuCategoriesRoutes

export type tHasEmailNotificationAccessProps = {
    user_role: tUserRole
    company_role: tCompanyRole
}

export type tMenuCategoriesRoutes = {
    emailAlerts: Array<tRoute>
    analytics: Array<tRoute>
    beta: Array<tRoute>
    companySettings: Array<tRoute>
    dailySummaryEmailSettings: Array<tRoute>
    demo: Array<tRoute>
    fieldForms: Array<tRoute>
    projectSettings: Array<tRoute>
    reports: Array<tRoute>
    timeCards: Array<tRoute>
}

export enum eAuthenticationErrorTypes {
    ACCOUNT_DISABLED = "ACCOUNT_DISABLED",
    TRIAL_DISABLED = "TRIAL_DISABLED",
}
