import { significantFigureMaker } from "./ag-grid-utils"
import {
    notificationPreferences,
    DATE_ONLY_FORMAT,
    EMPLOYEE_MAIN_GROUP_ASSIGNMENT,
    EMPLOYEE_PROJECT_SPECIFIC_ASSIGNMENTS,
    TIME_FORMAT,
    DATETIME_FORMAT,
    DECLINABLE_BOOLEAN_LABELS,
} from "./constants"
import { statusNameLabelMap } from "../components/custom-dashboards/settings-files/statuses-by-user-role"
import { isNumber } from "./validators"
import { getDateTimeFormats, getAsDate, getTimezoneAcronym, isValueEmpty } from "./ts-utils"
import { getFlagEnabled } from "../getFlagValue"
import { JsonPointer as jsonpointer } from "json-ptr"
import { format, isValid } from "date-fns"
import { isEqual } from "lodash"

/**
 * Mutates a values array to be used in formatting. This is a common helper function for the
 * following value formatters and came directly from getValueFormatter where
 *
 * @param {any} params Referenceable context to grab the values from
 * @param {string} field The field from which to grab the value
 * @param {string[]} values The mutable string array to add the values to
 * @param {string} dateFormat If this is a date, specify the dateFormat to apply to it
 * @returns {string[]} The values array in case one wasn't provided
 */
const pointerValues = (params, field, values = [], dateFormat = "") => {
    /**
     * skip job_number if are filtered by a project... maybe later this
     * should be more generic and remove any field that we are filtered by
     */
    if (
        (params.context?.current_project_id &&
            (!Array.isArray(params.context.current_project_id) || params.context.current_project_id.length === 1) &&
            jsonpointer.decode(field)[0] === "project") ||
        field === ""
    ) {
        return ""
    }
    if (Array.isArray(params.value)) {
        params.value.forEach(item => {
            const itemVal = jsonpointer.get(item, field)
            if (itemVal) values.push(itemVal)
        })
        return
    }
    let val = jsonpointer.get(params.value, field)

    // If the user passed dateFormat, the field contains a date that should be
    // formatted as specified
    if (dateFormat && val) {
        val = dateValueFormatter({ value: val }, dateFormat)
    }

    if (val) values.push(val)

    return values
}

/**
 * Returns a valueFormatter, a function that takes one or more fields on an object,
 * renders and if specified formats them, and returns them as a single string
 *
 * @param {any[]} fields The fields from which to grab values
 * @param {string} separator The separator string to insert between each value
 * @param {string} prefix A string added to the front of the fields
 * @param {string} dateFormat If this is a date, specify the dateFormat to apply to it
 * @param {function} indicateInactive A function which determines if an option is inactive
 * @returns {tValueFormatter} The value formatter that returns the formatted string
 */
export const getValueFormatter = (
    fields,
    separator = "",
    prefix = "",
    dateFormat = "",
    indicateInactive = null,
    noEntityString = ""
) => params => {
    if (!params.value) {
        return ""
    }

    const values = []
    fields.forEach(field => {
        pointerValues(params, field, values, dateFormat)
    })
    if (noEntityString && isEqual(values, [-1])) return noEntityString
    let value = `${prefix}${values.join(separator)}`
    if (indicateInactive && indicateInactive(params.value)) value += " (Inactive)"
    return value
}

/**
 * Returns a valueFormatter, a function that takes one or more fields on an object,
 * renders and if specified formats them, and returns them as a single string
 *
 * @param {string} formatString The format string with indexed replacement keys. e.g.
 *      "{0}, {1} - {2}" will be replaced by the fields ["lastName", "firstName", "employeeId"]
 * @param {any[]} fields The fields from which to grab values
 * @param {boolean} indicateInactive Whether to indicate the value is inactive or not
 * @returns {tValueFormatter} The value formatter that returns the formatted string
 */
export const getStringValueFormatter = (formatString, fields, indicateInactive = false) => params => {
    if (!params.value) {
        return ""
    }

    const format = (string, params) => {
        return string.replace(/{(\d+)}/g, (match, index) => {
            return typeof params[index] !== "undefined" ? params[index] : match
        })
    }

    const values = []
    fields.forEach(field => {
        let fieldValue = field
        let dateFormat = null

        // If the field is an object, that means additional formatting has been declared
        if (typeof field === "object") {
            fieldValue = field.value
            if ("dateFormat" in field) {
                dateFormat = field.dateFormat
            }
        }

        pointerValues(params, fieldValue, values, dateFormat)
    })

    if (!values.length) return ""

    let value = format(formatString, values)
    if (indicateInactive && jsonpointer.get(params.value, "/status") === "INACTIVE") value += " (Inactive)"

    return value
}

/**
 * Format date.
 * @param {Object} params Input data.
 * @prop {string|Date} [params.value] Date.
 * @param {string} [dateFormat] Output date format. Default to "MMMM dd, yyyy".
 * @return Formatted date.
 */
export const dateValueFormatter = (params, dateFormat) => {
    if (getFlagEnabled("WA-7753-improve-date-parsing-in-list-view")) {
        return params?.value && isValid(getAsDate(params.value))
            ? format(getAsDate(params.value), dateFormat || DATE_ONLY_FORMAT)
            : ""
    } else {
        return params.value ? format(getAsDate(params.value), dateFormat || DATE_ONLY_FORMAT) : ""
    }
}

/**
 * Format a Date object to Date/Time string
 * @param params Input date from AG Grid
 * @param {string} [dateFormat] Optional date format
 * @returns {string} Formatted date/time or empty string
 */
export const dateTimeValueFormatter = (params, dateFormat = DATETIME_FORMAT) => {
    return params?.value && isValid(getAsDate(params.value)) ? format(getAsDate(params.value), dateFormat) : ""
}

export const timeValueFormatter = params => {
    if (!params.value) {
        return ""
    }
    const cellEditorParams = params.colDef.cellEditorParams
    const dateFormat = getDateTimeFormats(cellEditorParams.mode, cellEditorParams.militaryTime).dateFormat
    // add the timezone acronym to the timestamp unless it is a date-only cell
    const tz = cellEditorParams.mode !== "date" ? getTimezoneAcronym() : ""

    if (getFlagEnabled("WA-7753-improve-date-parsing-in-list-view")) {
        // Sometimes the incoming value is not a date - if it's not valid, don't try to format
        // and display it
        const date = getAsDate(params.value)
        return isValid(date) ? `${format(date, dateFormat)} ${tz}` : ""
    } else {
        return `${format(getAsDate(params.value), dateFormat)} ${tz}`
    }
}

/*
 * Format a date value as: 2:45 pm
 */
export const simpleTimeValueFormatter = params => {
    if (!params.value) {
        return ""
    }
    return `${format(getAsDate(params.value), TIME_FORMAT)}`
}

export const phoneNumberFormatter = params => {
    const number = params.value
    if (number === null) {
        return ""
    }
    const s2 = ("" + number).replace(/^\+1?/, "").replace(/\D/g, "")
    const m = s2.match(/^(\d{3})(\d{3})(\d{4})$/)
    return !m ? number : `(${m[1]}) ${m[2]}-${m[3]}`
}

export const titleCaseFormatter = params => {
    if (!params.value) {
        return ""
    }
    return params.value
        .split(/[\s_]+/)
        .map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
        .join(" ")
}

export const upperCaseFormatter = params => (params.value ? params.value.toUpperCase() : "")

export const plusMinusPercentFormatter = params => {
    const performanceChange = params.value
    if (isNaN(performanceChange)) {
        return performanceChange
    } else {
        const plus = performanceChange >= 0 ? "+" : ""
        return plus + significantFigureMaker(performanceChange, 3) + " %"
    }
}

export const filterFormatter = params => {
    // Always returns the dummy row as a filter response
    if (params.data.dummy) {
        return params.node.beans.gridApi.filterManager.quickFilter
    }
    // Don't filter if group by is different then header name
    if (
        params.colDef &&
        params.column &&
        params.column.columnApi &&
        params.column.columnApi.getColumn("ag-Grid-AutoColumn")
    ) {
        const headerName = params.column.columnApi.getColumn("ag-Grid-AutoColumn").getColDef().headerName
        if (headerName !== params.colDef.headerName) {
            return null
        }
    }
    if (params.colDef && params.colDef.cellRendererParams) {
        const cell = params.colDef.cellRendererParams
        const context = params.node.context
        const value = params.value
        const title = cell.titleFormatter ? cell.titleFormatter({ value, context }) : ""
        const primary = cell.primarySubtitleFormatter ? cell.primarySubtitleFormatter({ value, context }) : ""
        const secondary = cell.secondarySubtitleFormatter ? cell.secondarySubtitleFormatter({ value, context }) : ""
        return `${title} ${primary} ${secondary}`
    }
    return params.value
}

const USDformatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
})

const EURformatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
})

const GPBformatter = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    minimumFractionDigits: 2,
})

const JPYformatter = new Intl.NumberFormat("ja-JP", {
    style: "currency",
    currency: "JPY",
})

export const currencyValueFormatter = params => {
    const value = params.value
    if (isNumber(value)) {
        let formatter = USDformatter
        switch (params.colDef.columnType) {
            case "currency-GBP":
                formatter = GPBformatter
                break
            case "currency-EUR":
                formatter = EURformatter
                break
            case "currency-JPY":
                formatter = JPYformatter
                break
        }
        return formatter.format(value)
    }
    return value
}

export const USDValueFormatter = params => {
    const value = params.value
    if (isNumber(value)) {
        return USDformatter.format(value)
    }
    return value
}

export const statusValueFormatter = params => {
    if (params.context) {
        const statusMap = statusNameLabelMap()
        if (params.value) {
            const valueCheck = params.value.name ? params.value.name : params.value
            const value = valueCheck in statusMap ? statusMap[valueCheck] : valueCheck
            return titleCaseFormatter({ value })
        }
    }

    return "-"
}

export const customStatusValueFormatter = params => {
    if (params.value) {
        return params.value.label
    }

    return "-"
}

export const tmEmailPreferenceValueFormatter = params => {
    if (params.value == null) {
        // Return OPT_OUT as the default value for grouped/single rows
        // in case they don't have the email type property returned from backend
        if (params.node && !params.node.group) {
            return notificationPreferences.OPT_OUT
        }
        return null
    }
    if (params.value == true || params.value === notificationPreferences.OPT_IN) {
        return notificationPreferences.OPT_IN
    }
    return notificationPreferences.OPT_OUT
}

export const isActiveValueFormatter = params => {
    return params.value ? "Active" : "Inactive"
}

export const isActiveEnumValueFormatter = params => {
    // This is for use with an enumerated string column type. The dropdown from agRichSelect
    // fires the value formatter as well, which was causing all the options to be "Active".
    return params.value === true ? "Active" : params.value === false ? "Inactive" : params.value
}

export const declinableBooleanValueFormatter = params => {
    return DECLINABLE_BOOLEAN_LABELS.get(params.value)
}

export const booleanValueFormatter = params => (isValueEmpty(params?.value) ? "True" : "False")

export const multiChoiceValueFormatter = (options, alphabetize = false) => {
    if (!options) return
    if (alphabetize && Array.isArray(options)) {
        options = options.sort((a, b) => (a.name < b.name ? -1 : a.name === b.name ? 0 : 1))
    }
    return Array.isArray(options) ? options.map(item => item.name).join(", ") : options.name
}

export const userRoleChoiceValueFormatter = params => {
    // this is for use with the MultiChoiceCellEditor - currently used to assign user roles to dashboards
    if (!params?.value?.length || !params?.colDef?.cellEditorParams) return
    const { value } = params
    const { fixedOptions } = params.colDef.cellEditorParams
    let selectedChoices = params.colDef.cellEditorParams.choices.filter(choice => value.includes(choice.value))
    selectedChoices = selectedChoices
        .filter(v => fixedOptions?.includes(v.value))
        .concat(selectedChoices.filter(v => !fixedOptions?.includes(v.value)))
    return selectedChoices.map(choice => choice.label).join(", ")
}

// For non-GLO customers. Takes the CompanyGroup assigned to an employee
// and translates it to a simple string. If the employee has no groups, a different string is returned
// to convey that fact
export const employeeMainGroupAvailableValueFormatter = params => {
    let option = EMPLOYEE_PROJECT_SPECIFIC_ASSIGNMENTS
    const items = params.value

    // The main group could be renamed, so look it up in the referencable
    // data, and if for some reason we can't, assume it's Main as a last
    // ditch effort, which will usually be true.
    const groups = params.context?.referenceableData?.companyGroups
    const mainGroupName = groups ? Object.values(groups).find(item => item.level === 0)?.name ?? "Main" : "Main"

    // Only the Main group is supported; if this employee happens to have any other groups
    // (say, because they used to be a GLO Customer), they are ignored
    if (items) {
        if (Array.isArray(items)) {
            const mainGroup = items.find(item => item.name === mainGroupName)
            if (mainGroup) option = EMPLOYEE_MAIN_GROUP_ASSIGNMENT
        } else {
            // Fall back to using items as-is, in case it was already a string
            option = items
        }
    }

    return option
}

export const quantityValueFormatter = (params, default_entry_type) => {
    return params.value === null || params.value === undefined
        ? null
        : default_entry_type === "PERCENT_COMPLETE"
        ? `${params.value} %`
        : params.value
}

export const emailFormatter = params => {
    const email = params.value
    if (!email) {
        return ""
    }
    return email.toLowerCase()
}
