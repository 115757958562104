import React from "react"
import { connect } from "react-redux"
import Breadcrumb from "./Breadcrumb"
import { tRoute } from "../../router/types"
import {
    tFilterDef,
    tErrorFilterDef,
    tFilterState,
    tFilterActionCallbacks,
    iMutableFilterState,
    iInitialFiltersAppliedAction,
    eDatePickerType,
    tFilterKey,
    tFilterContext,
} from "../types"
import {
    updateEmployeeStartOfWeek,
    setFilterState,
    setPendingFilters,
    setMultipleFilterValues,
    setInitialFiltersApplied,
    loadEntitiesForFilter,
    loadStringEntitiesForFilter,
} from "../actions"
import { FilterBanner } from "../FilterBanner"
import "./FilterController.less"
import { referenceableDataSelector, filtersSelector, isFeatureEnabledSelector } from "../../selectors"
import { requiredFilterValidator, removeFilterDefByKey, datePickerHasArrows, getApplicableFilters } from "../utils"
import { pendingFiltersSelector, initialFiltersAppliedSelector, startOfTheWeekSelector } from "../selectors"
import { WrapFilterComponents } from "./WrapFilterComponents"
import { DateQuickNav } from "../DateQuickNav"
import { SideRailContext, SideRailContextInstance } from "../../components/SideRail/SideRailContext"
import { projectDashboardAddNewRoutePath, projectDashboardRoutePath } from "../../router/constants"
import { getFlagEnabled } from "../../getFlagValue"
import { getCookie } from "../../common/ts-utils"
import { iCurrentUser } from "../../common/types"
import DayOfWeekSelector from "./DayOfWeekSelector"
import { calculateAndSetFilterDates } from "../DateFilter/calculateFilterDates"
import { spacingS } from "@rhumbix/rmbx_design_system_web"

type StateProps = {
    currentUser?: iCurrentUser
    filterState: tFilterState
    filterContext: tFilterContext
    pendingFilterState: tFilterState
    initialFiltersApplied: boolean
    isGroupsFeatureEnabled: boolean
}

type DispatchProps = {
    loadEntitiesForFilter: (filterKey: string, resource: string, ids: number[]) => void
    loadStringEntitiesForFilter: (filterKey: string, resource: string, values: string[], queryParam: string) => void
    setFilterState: tFilterActionCallbacks
    setPendingFilters: tFilterActionCallbacks
    setMultipleFilterValues: (arg: iMutableFilterState) => void
    setInitialFiltersApplied: () => iInitialFiltersAppliedAction
    updateEmployeeStartOfWeek: (empId: number, startOfWeek: string) => void
}

type OwnProps = {
    filters: Array<tFilterDef | tErrorFilterDef>
    route: tRoute
    requiredFiltersValidated?: (validated: boolean) => void
}

type Props = StateProps & DispatchProps & OwnProps

export class FilterController extends React.PureComponent<Props> {
    static contextType = SideRailContext
    declare context: SideRailContextInstance

    componentDidMount(): void {
        this.initializeFilterState()

        if (getFlagEnabled("WA-7095-keyboard-shortcuts-RR")) {
            // Add event listener while filter is open
            window.addEventListener("keydown", this.keyboardShortcuts)
        }
    }

    componentDidUpdate(prevProps: Props): void {
        this.updateCustomDashboardRequiredFilter()

        if (prevProps.currentUser?.employee_start_of_week !== this.props.currentUser?.employee_start_of_week) {
            const filterDefsNoErrors = this.getValidFilterDefs()
            calculateAndSetFilterDates({
                dates: {
                    startDate: this.props.filterState.startDate ?? null,
                    endDate: this.props.filterState.endDate ?? null,
                },
                startOfWeekIndex: this.props.filterContext.startOfTheWeekIndex,
                datePickerType: this.getDatePickerType(filterDefsNoErrors) as eDatePickerType,
                setState: true,
                setMultipleFilterValues: this.props.setMultipleFilterValues,
                setFilterState: this.props.setFilterState,
            })
        }
    }

    componentWillUnmount(): void {
        // remove the event handler for the form navigation upon closing side rail
        window.removeEventListener("keydown", this.keyboardShortcuts)
    }

    initializeFilterState = (): void => {
        const validFilterDefs = this.getValidFilterDefs()
        let filterStateUpdates = this.setDefaultFilterState(validFilterDefs)

        // Convert multi-filters into single filters
        const filtersToConvert: tFilterKey[] = ["projectId", "groupId"]
        filtersToConvert.forEach((entityType: tFilterKey) => {
            if (
                this.usingSingleEntityFilter(validFilterDefs, entityType) &&
                this.multipleEntitiesInFilterState(entityType)
            ) {
                filterStateUpdates = { ...filterStateUpdates, ...this.updateToSingleEntity(entityType) }
            }
        })

        const storedFilters = getFlagEnabled("WA-7926-rr-filter-save")
            ? getCookie(`filters_${this.props.currentUser?.id}`)
            : ""
        const storedFilterDict = storedFilters ? JSON.parse(storedFilters) : {}
        const applicablePendingFilters = getApplicableFilters(validFilterDefs, {
            ...storedFilterDict,
            ...this.props.filterState,
            ...filterStateUpdates,
        })
        this.props.setPendingFilters(applicablePendingFilters)
        this.props.setFilterState({ ...storedFilterDict, ...filterStateUpdates }, false)

        // Make sure that the entities for each filter selection are loaded into state
        Object.entries(applicablePendingFilters).forEach(([filterKey, selectionValues]: [string, any]) => {
            const selectionArray = getFlagEnabled("WA-7926-rr-filter-save")
                ? selectionValues && Array.isArray(selectionValues)
                    ? selectionValues
                    : selectionValues && !Array.isArray(selectionValues)
                    ? [selectionValues]
                    : []
                : selectionValues

            if (selectionArray?.length > 0) {
                const filterDef = validFilterDefs.find((filterDef: { key: string }) => filterDef.key === filterKey)
                const resource = filterDef?.resourceName
                // Optional query parameter for one-off cases where we don't actually load the entity (like Trades
                // and Classifications)
                const queryParam = filterDef?.queryParam
                if (resource) {
                    // get only numeric ids,
                    // and remove any string values that come from non-referenceable filter values
                    // (i.e trade and classification) that are just strings rather than ids
                    const ids = selectionArray.map(Number).filter((value: string | number) => value)
                    if (ids.length) this.props.loadEntitiesForFilter(filterKey, resource, ids)
                    // If the IDs weren't numeric and we were provided with a query parameter, let's try
                    // to fetch the resource entities using that.
                    else if (getFlagEnabled("WA-7926-rr-filter-save") && queryParam)
                        this.props.loadStringEntitiesForFilter(filterKey, resource, selectionValues, queryParam)
                }
            }
        })

        // TODO: ... and get rid of the ones that are no longer valid

        const hasRequiredFilters = this.checkForRequiredFilters(applicablePendingFilters, validFilterDefs)
        const filtersNotYetSelected = !this.props.initialFiltersApplied
        // Auto-set pending (empty) filters if we're skipping displaying the filter UI on page load.
        if (this.skipDisplayingInitialFilters()) this.applyPendingFilters()
        else if (this.canDisplayFilters() && (hasRequiredFilters || filtersNotYetSelected)) this.openPanel()
    }

    multipleEntitiesInFilterState = (entityType: tFilterKey): number | boolean | undefined =>
        !!this.props.filterState[entityType] &&
        Array.isArray(this.props.filterState[entityType]) &&
        (this.props.filterState[entityType] as Array<any>).length > 1

    usingSingleEntityFilter = (filterDefsNoErrors: Array<tFilterDef>, entityType: tFilterKey): boolean =>
        filterDefsNoErrors.some((item: tFilterDef) => item.key === entityType && !item.multiselect)

    updateToSingleEntity = (entityType: tFilterKey): iMutableFilterState => {
        const entityFilterState = this.props.filterState[entityType]
        if (entityFilterState && Array.isArray(entityFilterState)) {
            return { [entityType]: entityFilterState[0] }
        } else {
            return {}
        }
    }

    /**
     * Loop through the filter defs, if there exists a defaultGetter function,
     * call the defaultGetter function to get the default filter state and set
     * the filter values into filters and pendingFilters (redux states)
     */
    setDefaultFilterState = (filterDefsNoErrors: Array<tFilterDef>): iMutableFilterState =>
        filterDefsNoErrors.reduce((defaultState: iMutableFilterState, filterDef: tFilterDef) => {
            if (filterDef.defaultGetter) {
                return {
                    ...defaultState,
                    ...filterDef.defaultGetter(this.props.filterState, filterDef, this.props.filterContext),
                }
            }
            return defaultState
        }, {} as iMutableFilterState)

    closePanel = (): void => this.context.disableSideRail()

    openPanel = (): void => {
        this.context.enableSideRail({ flow: "FILTERS" })
    }

    checkForRequiredFilters = (filterState: tFilterState, filterDefs: Array<tFilterDef>): boolean =>
        /**
         * if there exists a filter that is required, and that filter is not set.
         */
        filterDefs.some((item: tFilterDef) => !requiredFilterValidator(filterState, item))

    skipDisplayingInitialFilters = (): boolean => {
        const { route } = this.props
        /**
         * Skip displaying initial filters popup if...
         *  - the user is on the Projects dashboard, since it supports filters but doesn't require them
         *  - we want to auto-open the Create Project form for the user on the projects dashboard
         */
        return [projectDashboardRoutePath, projectDashboardAddNewRoutePath].some(
            skippedPath => skippedPath === route.path
        )
    }

    canDisplayFilters = (): boolean => {
        const { route } = this.props
        const validFilterDefs = this.getValidFilterDefs()

        /**
         * Hide filters if there aren't any to show
         * or some special-case warrants hiding the filter-selector (and banner)
         */
        const hideFilters = validFilterDefs.length === 0 || route.path === "/rhumbix/projects"
        return !hideFilters
    }

    applyPendingFilters = (): void => {
        const {
            // eslint-disable-next-line no-shadow
            setFilterState,
            filterState,
            pendingFilterState,
            initialFiltersApplied,
            // eslint-disable-next-line no-shadow
            setInitialFiltersApplied,
        } = this.props
        if (filterState != pendingFilterState) {
            setFilterState(pendingFilterState)
        }
        if (!initialFiltersApplied) {
            setInitialFiltersApplied()
        }
    }

    applyPendingFiltersAndClose = (): void => {
        this.applyPendingFilters()
        this.closePanel()
    }

    cancelPendingFilters = (): void => {
        // eslint-disable-next-line no-shadow
        const { setPendingFilters, filterState, pendingFilterState } = this.props
        if (filterState != pendingFilterState) {
            setPendingFilters(filterState)
        }
        this.closePanel()
    }

    getDatePickerType = (filterDefs: Array<tFilterDef>): eDatePickerType | undefined | null => {
        const result = filterDefs.filter(item => item.key === ("startDate" as tFilterKey))
        if (result.length && datePickerHasArrows(result[0].datePickerType)) return result[0].datePickerType
    }

    /**
     * Checks if there are any required filters that are not set.
     * Callsback to custom-dashboard via the requiredFiltersValidated function to make it stop rendering
     * the table and show the required filters not set image if the table had not been loaded.
     */
    updateCustomDashboardRequiredFilter = (): void => {
        const { filterState, requiredFiltersValidated } = this.props

        if (!requiredFiltersValidated) return

        const filterDefsNoErrors = this.getValidFilterDefs()
        const requiredFilterNotSet = this.checkForRequiredFilters(filterState, filterDefsNoErrors)
        requiredFiltersValidated(!requiredFilterNotSet)
    }

    getValidFilterDefs = (): any => {
        const { filters, isGroupsFeatureEnabled } = this.props
        return isGroupsFeatureEnabled
            ? (removeFilterDefByKey(filters, "error") as Array<tFilterDef>)
            : (removeFilterDefByKey(filters, ["errors", "groupId"]) as Array<tFilterDef>)
    }

    keyboardShortcuts = (event: { key: string }): void => {
        if (this.context.sideRailConfig.flow === "FILTERS") {
            if (event.key === "Escape") this.cancelPendingFilters()
        }
    }

    render(): JSX.Element {
        const {
            route,
            filters,
            filterState,
            filterContext,
            pendingFilterState,
            isGroupsFeatureEnabled,
        } = this.props
        const { path: routePath } = route
        const { sideRailConfig, SideRailPortal } = this.context
        const filterDefsNoErrors = this.getValidFilterDefs()
        const canDisplayFilters = this.canDisplayFilters()
        let requiredFilterNotSet = false
        let datePicker
        let wrappedComponents

        if (canDisplayFilters) {
            requiredFilterNotSet = this.checkForRequiredFilters(pendingFilterState, filterDefsNoErrors)
            datePicker = this.getDatePickerType(filterDefsNoErrors)
            wrappedComponents = WrapFilterComponents({
                filterState: pendingFilterState,
                filterDefs: isGroupsFeatureEnabled ? filters : removeFilterDefByKey(filters, "groupId"),
                route,
            })
        }

        const dayOfWeekRoutes = [
            "/rhumbix/time-cards/weekly",
            "/rhumbix/time-cards/equipment-tracking",
            "/rhumbix/time-cards/production",
            "/rhumbix/reports/cc-accrual",
        ]
        // sometimes routePath is a list and sometimes a string - check if the path is in the day of week route list
        const isDayOfWeekRoute = Array.isArray(routePath)
            ? !!dayOfWeekRoutes.filter(route => dayOfWeekRoutes.includes(route)).length
            : routePath
            ? dayOfWeekRoutes.includes(routePath)
            : false

        return (
            <div className="filterBar" id="filter-bar">
                <Breadcrumb className="filterBar-breadcrumb" route={route} />
                {datePicker && filterState.startDate && filterState.endDate ? (
                    <DateQuickNav
                        datePickerType={datePicker}
                        startDate={filterState.startDate}
                        endDate={filterState.endDate}
                        context={filterContext}
                    />
                ) : null}
                {canDisplayFilters && (
                    <FilterBanner
                        openPanel={this.openPanel}
                        filterState={filterState}
                        filterDefs={filterDefsNoErrors}
                        context={filterContext}
                        requiredFilterNotSet={requiredFilterNotSet}
                    />
                )}
                {sideRailConfig.flow === "FILTERS" && (
                    <SideRailPortal
                        content={wrappedComponents}
                        headerText="Modify Filters"
                        headerIconClassName="icon-filter"
                        disableApplyButton={requiredFilterNotSet}
                        applyButtonOnClick={this.applyPendingFiltersAndClose}
                        cancelButtonOnClick={this.cancelPendingFilters}
                    />
                )}
                {isDayOfWeekRoute && (
                    <div style={{ right: spacingS, position: "absolute" }}>
                        <DayOfWeekSelector
                            companyStartOfWeek={this.props.currentUser?.employee.company.start_of_week}
                            employeeStartOfWeek={this.props.currentUser?.employee_start_of_week}
                            updateEmployeeStartOfWeek={this.props.updateEmployeeStartOfWeek}
                            employeeId={this.props.currentUser?.employee_id}
                        />
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state: any) => ({
    currentUser: state.current_user,
    filterState: filtersSelector(state),
    filterContext: {
        referenceableData: referenceableDataSelector(state),
        startOfTheWeekIndex: startOfTheWeekSelector(state.current_user),
    },
    pendingFilterState: pendingFiltersSelector(state),
    initialFiltersApplied: initialFiltersAppliedSelector(state),
    isGroupsFeatureEnabled: isFeatureEnabledSelector(state, "groups"),
})

const mapDispatchToProps = {
    loadEntitiesForFilter,
    loadStringEntitiesForFilter,
    setFilterState,
    setPendingFilters,
    setMultipleFilterValues,
    setInitialFiltersApplied,
    updateEmployeeStartOfWeek,
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterController)
