import { getFlagEnabled } from "../getFlagValue"

const canParseAsNumber = value => isFinite(value) && isFinite(parseFloat(value))

export const numberValueParser = params =>
    canParseAsNumber(params.newValue) ? Number(params.newValue) : params.newValue

export const numberOrNullValueParser = params => (params.newValue === "" ? null : numberValueParser(params))

export const hoursToMinutesValueParser = params =>
    canParseAsNumber(params.newValue) ? Math.round(params.newValue * 60) : params.newValue

/**
 * Parse entered time either in HH:MM format or in decimal hours (the classic way)
 * @param params
 * @returns {*}
 */
export const hoursMinutesToMinutesValueParser = params => {
    const parts = params.newValue.toString().split(":")
    const hours = parts.length === 1 ? parseFloat(parts[0]) : parseInt(parts[0])
    const minutes = parts.length === 1 ? 0 : parseInt(parts[1])
    return Math.round(hours * 60) + minutes
}

/**
 * We store values in their smallest unit, so for $1.00, we store it as 100.
 * So if 1 is entered, we want to convert that to 100 cents. Works for any currency that divides by 100
 * If value cannot be parsed, it will return 0.
 * @param {*} params
 * @returns entered value multiplied by 100
 */
export const currencyValueParser = params => {
    let { newValue } = params
    const { columnType } = params.colDef ?? {}
    if (getFlagEnabled("WA-8596-form-min-max") && typeof newValue === "string" && newValue.indexOf(",") !== -1) {
        // EURO currencies can use a , where USD and GBP use a decimal
        // so if using EUR, and the . is earlier in the the string than the comma
        if (columnType === "currency-EUR" && newValue.indexOf(",") > newValue.indexOf(".")) {
            newValue = newValue.replaceAll(".", "")
            newValue = newValue.replace(",", ".")
        } else {
            newValue = newValue.replaceAll(",", "")
        }
    }
    // currency fields should be integers, if the value cannot be parsed as a number, replace the value with zero
    return canParseAsNumber(newValue) ? Math.round(newValue * 100) : 0
}

/**
 * Yen is the smallest unit, so unlike other currencies we support, we do not multiple by 100.
 * Result will be a whole number.
 * If value cannot be parsed, it will return 0.
 * @param {*} params
 * @returns
 */
export const JPYValueParser = params =>
    // currency fields should be integers, if the value cannot be parsed as a number, replace the value with zero
    canParseAsNumber(params.newValue) ? Math.round(params.newValue) : 0
